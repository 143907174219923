import React from 'react'
import { KpiReportByLeader } from '../../../KpiReportByLeader'
import { hasRole } from '../../../../utils/auth'
import { getConsolidatedConfigSettings } from '../../../../utils/auth'

const MeetingHubKpiReport = () => {
  if (hasRole('team') && getConsolidatedConfigSettings('enable_l2_kpi') === true) {
    return <></>
  }
  return <KpiReportByLeader hideTitle={true} />
}

export default MeetingHubKpiReport

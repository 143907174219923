// StreamAudioRecorder.js
import React, { useEffect } from 'react'
import { useMeetingRecording } from '../../utils/meetingRecordingContext/meetingRecordingContext'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { hasRole } from '../../utils/auth'
import { routes, meetingMultiViewSubRoutes } from '../../utils/routes'

const StreamAudioRecorder = ({}) => {
  const { state, startRecording, stopRecording } = useMeetingRecording()

  const { t } = useTranslation(['Common'])
  const navigate = useNavigate()
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  const isRecordingStop = React.useRef(state.isRecordingStop)

  // this useEffect will be triggered everytime you go to different page
  // because it's part of the navigation, and it will be re-initialized,
  // since <Navigation /> is part of the ApplicationLayout/>...
  React.useEffect(() => {
    ;(async () => {
      if (state.isRecordingStop && !isRecordingStop.current) {
        const result = await Swal.fire({
          title: t('Common:transcription.doYouWantToReviewActionDecision'),
          showCancelButton: true,
          confirmButtonText: t('Common:transcription.yes'),
          cancelButtonText: t('Common:transcription.no'),
          reverseButtons: true,
        })
        // dispatch({ type: 'STOP_RECORDING' })
        if (result.isConfirmed) {
          let url = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.meetingSummaries}`
          navigate(url)
          navigate(0)
        }
      }
      isRecordingStop.current = state.isRecordingStop
    })()
  }, [state.isRecordingStop])

  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginRight: '1rem' }}>
      {state.hasSummarizationStarted ? (
        <div
          className="fitted-button blue"
          onClick={async () => {
            await stopRecording()
          }}
          style={{ pointerEvents: 'auto', cursor: 'pointer' }}
        >
          {state.isStoppingRecording ? (
            <span
              className="loading-spinner"
              style={{ width: '1rem', height: '1rem', marginRight: '0.5rem' }}
            ></span>
          ) : (
            <span className="material-symbols-outlined icon-size" style={{ marginRight: '0.1rem' }}>
              stop_circle
            </span>
          )}
          <span
            style={{
              display: 'flex',
              fontSize: '16px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {' '}
            {state.isStoppingRecording
              ? t('Common:transcription.processingEnd')
              : t('Common:transcription.summarizing')}
          </span>
        </div>
      ) : (
        <div
          className="fitted-button blue"
          onClick={startRecording}
          style={{ pointerEvents: 'auto', cursor: 'pointer' }}
        >
          <span className="material-symbols-outlined icon-size" style={{ marginRight: '0.1rem' }}>
            play_arrow
          </span>
          <span
            style={{
              display: 'flex',
              fontSize: '16px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {' '}
            {t('Common:transcription.startRecordingMeeting')}
          </span>
        </div>
      )}
    </div>
  )
}

export default StreamAudioRecorder

import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'
import { useTranslation } from 'react-i18next'

const TagInput = ({
  existingTags = [],
  setTagsExternally = function () {},
  objective,
  meetingTopic,
  isFromObjectiveMap = false,
  setSelected = function () {},
  objectiveId,
  addEditObjectiveModal = false,
}) => {
  const { t } = useTranslation(['Common'])
  const [tagsList, setTagsList] = React.useState([])
  const [passedTags, setPassedTags] = React.useState([])
  const [selectedOption, setSelectedOption] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [id, setId] = React.useState(0)

  const handleChangeTags = () => {
    //set deleted tags
    let delete_list = passedTags.filter(
      (item) => !selectedOption.some((option) => option.label === item.name),
    )
    delete_list.forEach((item) => {
      item['tagId'] = item.id
      item['isDeleted'] = true
    })
    let tagsToSetList = []
    tagsToSetList = delete_list
    //set added tags
    let notInPassedTags = selectedOption.filter(
      (item) => !passedTags.some((option) => option.name === item.label),
    )
    let add_list = notInPassedTags
      .filter((item) => !item.hasOwnProperty('isDeleted'))
      .map((item) => ({ tagName: item.label }))
    tagsToSetList = tagsToSetList.concat(add_list)
    return tagsToSetList
  }

  const handleCreate = (inputValue) => {
    setIsLoading(true)
    setTimeout(() => {
      const newOption = createOption(inputValue)
      setIsLoading(false)
      setTagsList((tagsList) =>
        [...tagsList, newOption].sort((a, b) => a.label.localeCompare(b.label)),
      )
      setSelectedOption((selectedOption) =>
        [...selectedOption, { value: newOption.id, label: newOption.label }].sort((a, b) =>
          a.label.localeCompare(b.label),
        ),
      )
    }, 1000)
  }

  const createOption = (label) => {
    setId(id - 1)
    return {
      id: id,
      label: label,
      objId: id,
    }
  }

  const handleSelectChange = (selected) => {
    setSelectedOption(selected)
  }

  const get_objective_tags = async () => {
    try {
      // Don't call on Add
      if (isFromObjectiveMap && objectiveId) {
        let result = await getHttpRequest(`/get_objective_tags?obj_id=${objectiveId}`)
        let existingtagsList = result.success.map((item) => ({
          id: item[0],
          name: item[1],
        }))
        setPassedTags(existingtagsList)
        setSelectedOption(result.success.map((item) => ({ value: item[0], label: item[1] })))
      } else {
        let existingtagsList = existingTags.map((item) => ({
          id: item.tagId,
          name: item.tagName,
        }))
        setPassedTags(existingtagsList)
        setSelectedOption(existingTags.map((item) => ({ value: item.tagId, label: item.tagName })))
      }
      let companyTags = await getHttpRequest(`/get_company_tags`)
      if (companyTags.success.length > 0) {
        const comtags = companyTags.success.map((item) => ({
          id: item[0],
          label: item[1],
        }))
        setTagsList(comtags)
      }
    } catch {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }
  const get_meeting_topic_tags = async () => {
    let existingtagsList = existingTags.map((item) => ({
      id: item.tagId,
      name: item.tagName,
    }))
    setPassedTags(existingtagsList)
    setSelectedOption(existingTags.map((item) => ({ value: item.tagId, label: item.tagName })))
    try {
      let companyTags = await getHttpRequest(`/get_company_tags`)
      if (companyTags.success.length > 0) {
        const comtags = companyTags.success.map((item) => ({
          id: item[0],
          label: item[1],
        }))
        setTagsList(comtags)
      }
    } catch {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }
  const getTags = () => {
    if (objective) {
      get_objective_tags()
    } else {
      get_meeting_topic_tags()
    }
  }

  React.useEffect(() => {
    getTags()
  }, [])

  React.useEffect(() => {
    setTagsExternally(handleChangeTags())
    setSelected(selectedOption)
  }, [selectedOption])

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="form-field action-field">
            <CreatableSelect
              id="selectOption"
              value={selectedOption}
              onChange={handleSelectChange}
              options={tagsList.filter(
                (item) => !selectedOption.some((option) => option.label === item.label),
              )}
              isLoading={isLoading}
              onCreateOption={handleCreate}
              isMulti
              isClearable
              menuPortalTarget={document.body}
              placeholder={t('Common:formPlaceHolder.select')}
              styles={{
                control: (base) => ({
                  ...base,
                  width: addEditObjectiveModal ? '16rem' : '300px',
                  borderRadius: addEditObjectiveModal ? '10px' : '0px',
                  backgroundColor: addEditObjectiveModal
                    ? 'rgb(242, 242, 242)'
                    : 'hsl(0, 0%, 100%)',
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}></div>
      </div>
    </>
  )
}

export default TagInput

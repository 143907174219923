import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const getAllActiveCompanyObj = async (
  isFromDashBoardAndIsRegularTl,
  owner,
  selectedLeaderToLoadMH,
  showL3ObjsForAlAndJuniorTls,
  isCapacityAddObjectiveModal,
) => {
  let url = isFromDashBoardAndIsRegularTl
    ? `${BASE_URL}/get_all_active_company_obj/${isFromDashBoardAndIsRegularTl}`
    : `${BASE_URL}/get_all_active_company_obj`

  if (owner !== undefined) {
    url = `${url}${url.includes('?') ? '&' : '?'}owner=${owner}`
  }

  if (selectedLeaderToLoadMH !== undefined && selectedLeaderToLoadMH !== null) {
    url = `${url}${url.includes('?') ? '&' : '?'}selectedLeaderToLoadMH=${selectedLeaderToLoadMH}`
  }

  if (showL3ObjsForAlAndJuniorTls !== undefined) {
    url = `${url}${
      url.includes('?') ? '&' : '?'
    }showL3ObjsForAlAndJuniorTls=${showL3ObjsForAlAndJuniorTls}`
  }
  if (isCapacityAddObjectiveModal !== undefined) {
    url = `${url}${
      url.includes('?') ? '&' : '?'
    }isCapacityAddObjectiveModal=${isCapacityAddObjectiveModal}`
  }

  const { data } = await axios.get(url)
  return data
}

export default function useGetAllActiveCompanyObj({
  isFromDashBoardAndIsRegularTl = undefined,
  owner = undefined,
  selectedLeaderToLoadMH = undefined,
  showL3ObjsForAlAndJuniorTls = undefined,
  isCapacityAddObjectiveModal = undefined,
}) {
  return useQuery(
    [
      'all-active-company-obj',
      { owner: owner, showL3ObjsForAlAndJuniorTls: showL3ObjsForAlAndJuniorTls },
    ],
    () =>
      getAllActiveCompanyObj(
        isFromDashBoardAndIsRegularTl,
        owner,
        selectedLeaderToLoadMH,
        showL3ObjsForAlAndJuniorTls,
        isCapacityAddObjectiveModal,
      ),
    {
      refetchOnWindowFocus: false,
    },
  )
}

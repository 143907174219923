// meetingRecordingReducer.js
export const defaultState = {
  meetingInstanceId: null,
  transcription: [],
  hasSummarizationStarted: false,
  isCardRecordingStarted: {},
  isGeneratingActionsCommentsFromRecording: {},
  topicFocusEventTracker: [],
  isRecordingStop: false,
  shouldRefresh: false,
  isAboutExpired: false,
  isExpired: false,
  // dataQueue: [],
  // isProcessingDataQueue: false,
  isStoppingRecording: false,
}

const meetingRecordingReducer = (state, action) => {
  switch (action.type) {
    case 'START_RECORDING':
      return {
        ...state,
        hasSummarizationStarted: true,
        meetingInstanceId: action.meetingInstanceId,
        isRecordingStop: false,
        shouldRefresh: false,
        isExpired: false,
        isAboutExpired: false,
        isStoppingRecording: false,
        // dataQueue: [],
      }
    case 'STOP_RECORDING':
      console.log('STOP_RECORDING')
      return {
        ...state,
        hasSummarizationStarted: false,
        isRecordingStop: true,
        meetingInstanceId: null,
        // isProcessingDataQueue: false,
      }
    case 'SET_TRANSCRIPTION':
      return { ...state, transcription: action.transcription }
    case 'SHOULD_REFRESH':
      return { ...state, shouldRefresh: action.shouldRefresh }
    case 'SET_CARD_RECORDING_STARTED':
      return {
        ...state,
        isCardRecordingStarted: {
          ...state.isCardRecordingStarted,
          [action.umtId]: action.newValue,
        },
      }
    case 'SET_IS_GENERATING_ACTIONS_COMMENTS_FROM_RECORDING':
      return {
        ...state,
        isGeneratingActionsCommentsFromRecording: {
          ...state.isGeneratingActionsCommentsFromRecording,
          [action.umtId]: action.newValue,
        },
      }
    case 'SET_TOPIC_FOCUS_EVENT_TRACKER':
      return {
        ...state,
        topicFocusEventTracker: action.topicFocusEventTracker,
      }
    case 'SET_IS_ABOUT_EXPIRED':
      console.log('action.isAboutExpired', action.isAboutExpired)
      return {
        ...state,
        isAboutExpired: action.isAboutExpired,
      }
    case 'SET_IS_EXPIRED':
      return {
        ...state,
        isExpired: action.isExpired,
      }
    case 'SET_IS_STOPPING_RECORDING':
      return {
        ...state,
        isStoppingRecording: action.isStoppingRecording,
      }
    case 'SET_TOPIC_ID_COMMENT_DECISION_ACTION_COUNT':
      return {
        ...state,
        topicIdCommentDecisionActionCount: action.topicIdCommentDecisionActionCount,
      }
    // case 'SET_DATA_QUEUE':
    //   return {
    //     ...state,
    //     dataQueue: action.dataQueue,
    //   }
    // case 'SET_IS_PROCESSING_DATA_QUEUE':
    //   return {
    //     ...state,
    //     isProcessingDataQueue: action.isProcessingDataQueue,
    //   }
    default:
      throw new Error(`Action: ${action.type} not implemented in meetingRecordingReducer.`)
  }
}

export default meetingRecordingReducer

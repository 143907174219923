import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { lastMeetingTopic } from '../../../../../api/services/dashboard.services'
import '../../UserDashboard/dashboard.scss'
import { useTranslation } from 'react-i18next'
import AddEditMeetingTopicModal from '../../../../../components/AddEditMeetingTopicModal'
import { getCompanyConfigSettings } from '../../../../../utils/auth'
import Swal from 'sweetalert2'
import { useUpdateMeetingLogs } from '../../../../../api/services/meetingTopic.services'
import Toast from '../../../../../common/toast'
import { routes } from '../../../../../utils/routes'
import { hasRole } from '../../../../../utils/auth'
import useAreaLeaderTeamLeaders from '../../../../../api/query/useAreaLeaderTeamLeaders'

const LastMeetingTopic = () => {
  const { t } = useTranslation(['Dashboard'])
  const [isLoading, setIsLoading] = useState(false)
  const [lastMeeting, setLastMeeting] = useState(null)

  const [showMore, setShowMore] = useState(false)
  const [isAddMeetingTopicOpen, setIsAddMeetingTopicOpen] = React.useState(false)
  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')
  const [reload, setReload] = React.useState(0)
  const navigate = useNavigate()
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  const isAreaLeader = hasRole('area') ? true : false
  const mutationUpdateMeetingLogs = useUpdateMeetingLogs()
  const { data: teamLeadersData, isLoading: isTeamLeadersLoading } = useAreaLeaderTeamLeaders()

  useEffect(() => {
    getMeetingTopic()
  }, [reload])

  async function getMeetingTopic() {
    setIsLoading(true)
    const result = await lastMeetingTopic()
    setIsLoading(false)
    if (result && result?.meetingTopics?.length > 0) {
      setLastMeeting(result.meetingTopics)
    } else {
    }
  }

  const loadAgain = () => {
    setReload((prev) => prev + 1)
  }

  const resolve = async (data) => {
    const result = await Swal.fire({
      title: t('Common:modalMessages.areYouSureResolveCompleteMeetingTopic'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (result.isConfirmed) {
      const formData = {}
      formData.status = !data?.status

      if (!data.umtId) {
        alert('Error 0xA001')
      }
      formData.umtId = data?.umtId

      await mutationUpdateMeetingLogs.mutateAsync(formData, {
        onSuccess: () => {
          loadAgain()
        },
        onError: () => {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        },
      })
    }
  }

  function returnDataList(meeting) {
    let finalArray = []
    if (meeting.length > 5) {
      if (showMore) {
        finalArray = meeting
      } else {
        finalArray = meeting.slice(0, 5)
      }
    } else {
      finalArray = meeting
    }

    return finalArray.map((data, index) => {
      return (
        <div className="heading_body" key={index}>
          <div className="card_body">
            <div className="btn_box">
              <h5
                className={`card_heading 
                        ${data?.topicType == 1 ? 'blocker_title' : ''}
                        ${data?.topicType == 2 ? 'decision_title' : ''}
                        ${data?.topicType == 3 ? 'update_title' : ''}
                        ${data?.topicType == 4 ? 'admin_topic_title' : ''}

            `}
              >
                <span className="action"> {data.actionItem} </span>
              </h5>
              {/* <button type="button" class="btn-common blue-btn"><span class="material-symbols-outlined icon-size">comment</span></button> */}
            </div>
            <div className="objectiveNamebox" style={{ paddingLeft: '0.5rem' }}>
              <span style={{ color: 'grey', width: '85%' }}>
                {t('lastMeetingTopic.objectiveName')} :{' '}
                <span className="topic_name"> {data.statement} </span>
                <p className="comment_box">
                  <span>
                    {t('lastMeetingTopic.action')} : <span>{data.totalActionCount}</span>{' '}
                  </span>
                  {/* <span>{t('lastMeetingTopic.comment')} : <span className='comments_count'>{data.totalCommentCount}</span></span> */}
                </p>
              </span>
              <div className="actions_box">
                {/* <button type="button" class="btn-common blue-btn">A Z</button> */}
                <button
                  type="button"
                  className="fitted-button blue spacing-bottom"
                  style={{ background: 'white', padding: '0.4rem' }}
                  onClick={(e) => resolve(data)}
                >
                  {t('lastMeetingTopic.resolve')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      {isAddMeetingTopicOpen && (
        <AddEditMeetingTopicModal
          isModalOpen={isAddMeetingTopicOpen}
          closeModal={() => {
            setIsAddMeetingTopicOpen(false)
          }}
          isProductSettingsEnabled={isProductSettingsEnabled}
          loadAgain={loadAgain}
          isFromDashBoardAndIsRegularTl={!(isDelegateTl || isAreaLeader)}
          teamLeadersList={teamLeadersData}
        />
      )}

      <div className="meeting_heading_box">
        <div className="heading_box">
          <h1 className="charts_heading">{t('lastMeetingTopic.title')}</h1>
          <div className="actions_box">
            <button
              type="button"
              className="fitted-button blue"
              style={{ background: 'white', padding: '0.4rem', fontWeight: 'bold' }}
              onClick={() => setIsAddMeetingTopicOpen(true)}
            >
              <span className="material-symbols-outlined icon-size">
                <span class="material-symbols-outlined" style={{ fontWeight: 'bold' }}>
                  add
                </span>
              </span>
            </button>
            <button
              type="button"
              className="fitted-button blue"
              style={{ background: 'white', padding: '0.4rem', height: '40.8px' }}
              onClick={() => {
                navigate(`/${routes.update}`, {
                  state: { showBGUonly: true, isFromDashBoard: true },
                })
              }}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: '14px',
                  paddingLeft: '0.2rem',
                  paddingTop: '0.3rem',
                  fontWeight: 'bold',
                }}
              >
                {' '}
                {t('lastMeetingTopic.multiple')}
              </span>
            </button>
          </div>
        </div>
        {lastMeeting && returnDataList(lastMeeting)}
        {lastMeeting && lastMeeting.length > 5 && (
          <span className="see_more" onClick={() => setShowMore(!showMore)}>
            {' '}
            {showMore ? t('lastMeetingTopic.showLess') : t('lastMeetingTopic.showMore')}{' '}
          </span>
        )}
        {!lastMeeting && (
          <div style={{ margin: 'auto' }}>{t('lastMeetingTopic.noMeetingTopicsMsg')}</div>
        )}
      </div>
    </>
  )
}

export default LastMeetingTopic

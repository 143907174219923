import React, { useState } from 'react'

import { Handle, Position } from 'reactflow'
import './customNode.scss'
import ActivityIcon from '../../../assets/activity.svg'
import MessageIcon from '../../../assets/message.svg'
import ZoomIcon from '../../../assets/zoom.svg'
import ZoomOutIcon from '../../../assets/zoomOut.svg'
import ObjMapDropdownMenu from './objMapDropdownMenu'
import { shortenName } from '../../../utils/general'
import magic_wand from '../../../assets/magic.svg'
import { getConsolidatedConfigSettings } from '../../../utils/auth'

const handleStyle = { left: 10 }

function CustomNode({ data, isConnectable }) {
  const enableL2Kpi = getConsolidatedConfigSettings('enable_l2_kpi') === true

  const SelectFirstCharacter = (name) => {
    return name
  }

  const renderListOfUserNames = (data) => {
    return (
      <>
        {data.slice(0, 4).map((item, key) => (
          <div>
            <span key={item.eId + key}>{SelectFirstCharacter(item.name)}</span>
            {key !== data.length - 1 && <span>,</span>}
          </div>
        ))}
        {data.length > 4 ? (
          <span className="d-flex align-items-center">{data.length - 4} more</span>
        ) : null}
      </>
    )
  }

  return (
    <div className="nodrag text-updater-node">
      {data.main === 1 || data.main === 2 || data.main === 3 ? (
        <>
          {' '}
          <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
        </>
      ) : (
        ''
      )}
      {/* ${data.lackingCapacity ? 'change-bg-color' : ''} */}
      <div
        className={`custom-box-node custom-box-node-assignee box-level-${data.main}  
        ${data.ownerId == data.value ? '' : 'blur-box'} 
        `}
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <ul className="node-text-list">
          <li>
            <div style={{ display: 'flex', gap: '1.5rem', marginBottom: '0.2rem' }}>
              {data.isShared ? (
                <div className="shared-box">Shared</div>
              ) : (
                <div className="shared-box-empty"></div>
              )}
              {data.main != 3 || (data.main == 3 && data.isShared) ? (
                <div className="owner">{shortenName(data.owner)}</div>
              ) : null}
            </div>
          </li>
          <li className="single-node">
            {/* <h2>Objective:</h2> */}
            <p className="goal-desc">{data.goal}</p>
          </li>
        </ul>

        <div className="icons-list-node-box">
          <div
            style={{
              width: '100%',
              alignSelf: 'flex-end',
              gap: '0.313rem',
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0 0.1rem .313rem 0.1rem',
              padding: '0',
            }}
          >
            <div style={{ width: '24px' }}>
              {data.cardPermissions.canSeeObjStatistics === true &&
                data.hasOverdueActivities === true && (
                  <div class="fitted-button yellow-notification">
                    <span
                      class="material-symbols-outlined icon-size-sm"
                      style={{ backgroundColor: '#fff' }}
                      onClick={() => {
                        data.ShowOverdueModal(data?.id)
                      }}
                    >
                      warning
                    </span>
                  </div>
                )}
            </div>
            {data.cardPermissions.canAdd && (
              <div class="fitted-button blue">
                <span
                  class="material-symbols-outlined icon-size-sm"
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => {
                    data.ShowAddModal(data, data.main)
                  }}
                >
                  add
                </span>
              </div>
            )}
            <div style={{ width: '24px' }}>
              {data.cardPermissions.canSeeObjStatistics === true &&
                data.hasAnyProgressLastTwoWeeks === true && (
                  <div class="fitted-button green-notification">
                    <span
                      class="material-symbols-outlined icon-size-sm"
                      style={{ backgroundColor: '#fff' }}
                      onClick={() => {
                        data.ShowNotificationsModal(data?.id)
                      }}
                    >
                      notifications
                    </span>
                  </div>
                )}
            </div>
            <div style={{ width: '24px' }}>
              {data.cardPermissions.canSeeObjStatistics === true &&
                data.hasAnyProgressLastTwoWeeks === false && (
                  <div class="fitted-button red-notification" style={{ cursor: 'grab' }}>
                    <span
                      class="material-symbols-outlined icon-size-sm"
                      style={{ backgroundColor: '#fff' }}
                    >
                      hourglass_disabled
                    </span>
                  </div>
                )}
            </div>
            {!data.isReviewMode && (
              <div style={{ width: '24px' }}>
                {data.rag && data.main == 3 && data.rag !== 'UNKNOWN_RAG' && (
                  <div
                    className={`rag-letter-box-sm ${
                      data.rag === 'RED'
                        ? 'red-rag-box'
                        : data.rag === 'YELLOW'
                          ? 'amber-rag-box'
                          : data.rag === 'GREEN'
                            ? 'green-rag-box'
                            : ''
                    }`}
                    style={{ cursor: 'grab' }}
                    onClick={() => {
                      data.ShowKPIModalSpecificKpi(data?.id)
                    }}
                  >
                    <span class="icon-size-sm">
                      {data.rag === 'RED'
                        ? 'R'
                        : data.rag === 'YELLOW'
                          ? 'A'
                          : data.rag === 'GREEN'
                            ? 'G'
                            : ''}
                    </span>
                  </div>
                )}
              </div>
            )}
            <div style={{ width: '24px' }}>
              {data.cardPermissions.canSeeActivities && (
                <li className="fitted-button blue" style={{ backgroundColor: '#fff' }}>
                  <span
                    className="material-symbols-outlined icon-size-sm"
                    style={{ transform: 'rotate(270deg) scaleX(-1)', marginLeft: '0.1rem' }}
                    onClick={() => data.ShowActivitesModal(data?.id)}
                  >
                    account_tree
                  </span>
                </li>
              )}
            </div>
            <div style={{ width: '24px' }}>
              {data.cardPermissions.canSeeHistory && (
                <div
                  class="fitted-button blue"
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => {
                    data.ShowHistoryModal(data?.id)
                  }}
                >
                  <span class="material-symbols-outlined icon-size-sm">history</span>
                </div>
              )}
            </div>
            {data.cardPermissions.canSeeRecommendations && (data.main === 1 || data.main === 2) && (
              <li
                className="fitted-button blue"
                style={{ backgroundColor: '#fff' }}
                onClick={() => data.ShowRecommendedObjStatementModalOpen(data)}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={magic_wand}
                    alt="magic wand"
                    style={{
                      width: '15px',
                      height: 'auto',
                    }}
                  />
                </div>
              </li>
            )}
            {data.cardPermissions.canZoom &&
              data.main !== 3 &&
              data?.zoomIndexLevel?.id != data.id && (
                <li
                  className="fitted-button blue"
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => data.setZoomIndex(data, data.main)}
                >
                  <span class="material-symbols-outlined icon-size-sm">zoom_in</span>
                </li>
              )}
            {data.cardPermissions.canZoom &&
              data.main !== 3 &&
              data?.zoomIndexLevel?.id == data.id && (
                <li
                  className="fitted-button blue"
                  style={{ backgroundColor: '#e4e7fd' }}
                  onClick={() => data.setZoomIndex(data, data.main)}
                >
                  <span class="material-symbols-outlined icon-size-sm">zoom_out</span>
                </li>
              )}
            {data.cardPermissions.canKpi &&
              ((data.main === 3 && !enableL2Kpi) || (data.main === 2 && enableL2Kpi)) && (
                <li className="fitted-button blue" onClick={() => data.ShowKPIModal(data?.id)}>
                  <span class="material-symbols-outlined icon-size-sm">query_stats</span>
                </li>
              )}
            {data.cardPermissions.canEdit && (
              <ObjMapDropdownMenu
                level={data.main}
                canEdit={data.cardPermissions.canEdit}
                canDeleteComplete={data.cardPermissions.canDeleteComplete}
                canSeeFiles={data.cardPermissions.canSeeFiles}
                isDeactivated={data.isDeactivated}
                canKpi={data.cardPermissions.canKpi}
                canAddTopic={data.cardPermissions.canAddTopic}
                onClickEdit={() => {
                  data.ShowEditModal(data?.id, data.main)
                }}
                onClickRequestUpdate={() => {
                  data.ShowRequestUpdateModal(data?.id)
                }}
                onClickViewFiles={() => {
                  data.ShowFilesModal(data?.id)
                }}
                onClickKPIs={() => {
                  data.ShowKPIModal(data?.id)
                }}
                onClickChangelog={() => {
                  data.ShowChangelogPage(data?.id)
                }}
                onClickCompleteObj={() => {
                  data.ShowCompleteObjectiveModal(data?.id, data.main, data?.numOfChildrenActive)
                }}
                onClickDeleteObj={() => {
                  data.ShowDeleteObjectiveModal(data?.id, data.main, data?.numOfChildrenNotDeleted)
                }}
                onClickShowGanttChart={() => {
                  data.ShowGanttChartModal(data?.id)
                }}
                isReviewMode={data.isReviewMode}
                onClickAddMeetingTopic={() => {
                  data.ShowAddEditMeetingTopicModal(data)
                }}
              />
            )}
          </div>
          {/* {data.main == 3 ? (
            <li className="single-icon-zoom">
              <img src={MessageIcon} alt="" onClick={() => data.ShowCommentModel(data?.id)} />
            </li>
          ) : (
            ''
          )} */}
        </div>
      </div>
      {data.main != 3 ? (
        <>
          <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} />
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default CustomNode

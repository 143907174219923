import React, { useState, useEffect } from 'react'
import Modal from '../../Modal'
import InPageLoader from '../../InPageLoader'
import {
  getKpiList,
  getKpiUpdates,
  deleteKpi,
  completeKpi,
  updateKpiStatus,
} from '../../../api/services/kpi.services'
import './styles.scss'
import AddEditKPIModal from '../AddEditKPIModal'
import { useTranslation } from 'react-i18next'
import { shortenName } from '../../../utils/general'
import { dateFilter } from '../../../utils/time'
import CardModal from '../../CardModal'
import { getCompanyConfigSettings } from '../../../utils/auth'
import Toast from '../../../common/toast'
import Swal from 'sweetalert2'
import KpiDropdownMenu from '../KpiDropdownMenu'
import KPIUpdatesModal from '../KPIUpdatesModal'
import UpdateKPIModal from '../UpdateKPIModal'

const KPIModal = ({
  handleCancel,
  objId,
  openOnSpecificKpi = false,
  reloadBackground = () => {},
  reloadObjectiveReportModal = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [kpiList, setKpiList] = useState([])
  const [updatesList, setUpdatesList] = useState([])
  const [isOpenAddEditKPIModal, setIsOpenAddEditKPIModal] = useState(false)
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false)
  const [addEditModalMode, setAddEditModalMode] = useState('')
  const [selectedKpi, setSelectedKpi] = useState(null)

  const [selectedKpiUpdate, setSelectedKpiUpdate] = useState(null)
  const [isOpenKPIUpdatesModal, setIsOpenKPIUpdatesModal] = useState(false)

  const { t } = useTranslation(['Common'])
  const showMeetingDeck = getCompanyConfigSettings('showMeetingDeck') === true
  const [kpiChecks, setKpiChecks] = useState({})
  const [shouldShowCompleted, setShouldShowCompleted] = React.useState(false)

  React.useEffect(() => {
    loadKpisList()
  }, [])

  const reload = () => {
    loadKpisList()
    reloadObjectiveReportModal()
  }

  React.useEffect(() => {
    if (selectedKpiUpdate?.kpiId) {
      loadUpdates(selectedKpiUpdate.kpiId)
    }
  }, [selectedKpiUpdate])

  async function loadKpisList() {
    setIsLoading(true)
    let result = await getKpiList(objId)
    setIsLoading(false)
    if (result && result.kpis) {
      setKpiList(result.kpis)
    }

    if (result && result.objDescription) {
      setTitle(result.objDescription)
    }
  }

  async function loadUpdates(kpiId) {
    setIsLoading(true)

    let result = await getKpiUpdates(kpiId)
    setIsLoading(false)
    if (result && result.kpiUpdates) {
      setUpdatesList(result.kpiUpdates)
    }
  }

  const deleteKpiFn = async (kpi) => {
    const confirmBoxResult = await Swal.fire({
      title: t('Common:kpi.sureDeleteKpi'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (!confirmBoxResult.isConfirmed) {
      return
    }

    let formData = new FormData()
    formData.append('kpiId', kpi.kpiId)

    setIsLoading(true)
    let result = await deleteKpi(formData)
    setIsLoading(false)
    reload()

    if (result) {
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const completeKpiFn = async (kpi) => {
    const confirmBoxResult = await Swal.fire({
      title: t('Common:kpi.sureCompleteKpi'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (!confirmBoxResult.isConfirmed) {
      return
    }

    let formData = new FormData()
    formData.append('kpiId', kpi.kpiId)

    setIsLoading(true)
    let result = await completeKpi(formData)
    setIsLoading(false)
    reload()

    if (result) {
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const convertFrequencyEnumToText = (enumValue) => {
    switch (enumValue) {
      case 1:
        return t('kpi.weekly')
      case 2:
        return t('kpi.biweekly')
      case 3:
        return t('kpi.monthly')
      default:
        return t('kpi.unknown')
    }
  }

  const convertMilestoneEnumToText = (enumValue) => {
    switch (enumValue) {
      case 1:
        return t('kpi.notStarted')
      case 2:
        return t('kpi.inProgress')
      case 3:
        return t('kpi.completed')
    }
  }

  const updateComplete = async (kpi) => {
    let formData = new FormData()
    formData.append('kpiId', kpi.kpiId)
    formData.append(
      'isCompleted',
      kpi.isCompleted === undefined || kpi.isCompleted === null ? true : !kpi.isCompleted,
    )
    try {
      setIsLoading(true)
      let result = await updateKpiStatus(formData)
      if (result.success) {
        reload()
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    setIsLoading(false)
  }

  return (
    <>
      {isOpenAddEditKPIModal && (
        <AddEditKPIModal
          closeModal={() => setIsOpenAddEditKPIModal(false)}
          mode={addEditModalMode}
          objId={objId}
          kpi={selectedKpi}
          reload={() => {
            reload()
            reloadBackground()
          }}
        />
      )}

      {isOpenKPIUpdatesModal && (
        <KPIUpdatesModal
          handleCancel={() => setIsOpenKPIUpdatesModal(false)}
          reloadBackground={() => {
            reload()
          }}
          selectedKpiUpdate={selectedKpiUpdate}
          objId={objId}
          canAddUpdate={selectedKpiUpdate.isCompleted === false}
          canEditUpdate={selectedKpiUpdate.isCompleted === false}
        />
      )}

      {isOpenUpdateModal && (
        <UpdateKPIModal
          closeModal={() => setIsOpenUpdateModal(false)}
          objId={objId}
          kpi={selectedKpiUpdate}
          loadUpdates={() => {
            reload()
            reloadBackground()
          }}
        />
      )}

      <Modal style={{ zIndex: 102 }} title={title} closeModal={handleCancel} width={'80rem'}>
        {isLoading && <InPageLoader isLoading={isLoading} />}
        {!isLoading && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignSelf: 'end',
                  marginRight: '0.5rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '0.5%',
                }}
              >
                <span>{t('kpi.showCompleted')}</span>
                <input
                  type="checkbox"
                  checked={shouldShowCompleted}
                  onChange={() => setShouldShowCompleted((prev) => !prev)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                {t('kpi.addKpi')}
                <div
                  className="fitted-button blue tooltip"
                  onClick={() => {
                    setAddEditModalMode('add')
                    setIsOpenAddEditKPIModal(true)
                  }}
                >
                  <span className="material-symbols-outlined">add</span>
                </div>
              </div>
            </div>

            {kpiList.length > 0 && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <span
                  style={{
                    alignSelf: 'flex-start',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1.5rem',
                  }}
                >
                  <b>{t('kpi.kpiStatus')}</b>
                </span>
                <div className="divider"></div>

                <div style={{ marginBottom: '3rem', paddingTop: '2rem' }}>
                  <div className="kpi-status-table">
                    <div className="kpi-status-table-header"></div>
                    <div className="kpi-status-table-header">{t('kpi.kpi')}</div>
                    <div className="kpi-status-table-header">{t('kpi.target')}</div>
                    <div className="kpi-status-table-header">{t('kpi.unit')}</div>
                    <div className="kpi-status-table-header">{t('kpi.reachBy')}</div>
                    <div className="kpi-status-table-header"> {t('kpi.frequency')}</div>
                    <div className="kpi-status-table-header" style={{ paddingLeft: '1rem' }}>
                      {t('kpi.latestStatus')}
                    </div>
                    <div className="kpi-status-table-header">{t('kpi.ownerName')}</div>
                    <div className="kpi-status-table-header">{t('kpi.latestUpdate')}</div>
                    <div className="kpi-status-table-header">{t('kpi.updatedOn')}</div>
                    <div className="kpi-status-table-header">{t('kpi.updatedBy')}</div>
                    <div className="kpi-status-table-header">{t('kpi.addViewUpdates')}</div>
                    <div className="kpi-status-table-header"></div>

                    {kpiList
                      ?.filter((kpi) => {
                        if (shouldShowCompleted) {
                          return true
                        }
                        return !kpi.isCompleted
                      })
                      ?.sort((a, b) => {
                        if (a.isCompleted && !b.isCompleted) {
                          return 1
                        }
                        if (!a.isCompleted && b.isCompleted) {
                          return -1
                        }
                        return 0
                      })
                      ?.map((kpi, kpiIndex) => {
                        return (
                          <>
                            <React.Fragment key={`objective-${kpi.kpiId}`}>
                              <>
                                <div
                                  className="kpi-status-table-cell"
                                  style={{ textAlign: 'left' }}
                                >
                                  {kpi.isCompleted ? (
                                    <span
                                      className="material-symbols-outlined"
                                      style={{ color: 'green' }}
                                    >
                                      check
                                    </span>
                                  ) : null}
                                </div>
                                <div
                                  className="kpi-status-table-cell"
                                  style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <span>{kpi.name}</span>
                                </div>
                                <div
                                  className="kpi-status-table-cell"
                                  style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <span>
                                    {kpi.kpiType === 3 ? '---' : kpi.target.toLocaleString()}
                                  </span>
                                </div>
                                <div
                                  className="kpi-status-table-cell"
                                  style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <span>{kpi.unit === '' ? '---' : kpi?.unit}</span>
                                </div>
                                <div
                                  className="kpi-status-table-cell"
                                  style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <span>{kpi.targetDate}</span>
                                </div>
                                <div
                                  className="kpi-status-table-cell"
                                  style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <span>{convertFrequencyEnumToText(kpi.frequency)}</span>
                                </div>
                                <div className="kpi-status-table-cell" style={{}}>
                                  {
                                    <div
                                      style={{
                                        backgroundColor:
                                          kpi.ragStatus === -1
                                            ? 'lightgray'
                                            : kpi.ragStatus === 0
                                              ? 'green'
                                              : kpi.ragStatus === 1
                                                ? 'gold'
                                                : 'red',
                                        width: '2rem',
                                        height: '2rem',
                                        marginLeft: '1rem',
                                      }}
                                    ></div>
                                  }
                                </div>
                                <div
                                  className="kpi-status-table-cell"
                                  style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <span>{shortenName(kpi.ownerName)}</span>
                                </div>
                                <div
                                  className="kpi-status-table-cell"
                                  style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <span>
                                    {kpi.ragStatus === -1
                                      ? '---'
                                      : kpi.kpiType === 3
                                        ? convertMilestoneEnumToText(kpi.lastUpdateAmount)
                                        : kpi.lastUpdateAmount.toLocaleString()}
                                  </span>
                                </div>
                                <div
                                  className="kpi-status-table-cell"
                                  style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    color:
                                      kpi.isKpiUpdateOverdue && kpi.ragStatus !== -1
                                        ? 'red'
                                        : 'black',
                                  }}
                                >
                                  <span>
                                    {kpi.ragStatus === -1 ? '---' : dateFilter(kpi.lastUpdateTs)}
                                  </span>
                                </div>
                                <div
                                  className="kpi-status-table-cell"
                                  style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <span>
                                    {kpi.ragStatus === -1
                                      ? '---'
                                      : shortenName(kpi.lastUpdateCreatedBy)}
                                  </span>
                                </div>

                                <div
                                  className="kpi-status-table-cell"
                                  style={{
                                    display: 'flex',

                                    gap: '0.5rem',
                                    textAlign: 'left',
                                    padding: '0',
                                  }}
                                >
                                  <div
                                    className="fitted-button blue tooltip"
                                    style={{ backgroundColor: 'white' }}
                                    onClick={() => {
                                      setSelectedKpiUpdate(kpi)
                                      setIsOpenKPIUpdatesModal(true)
                                    }}
                                  >
                                    <span className="material-symbols-outlined">visibility</span>
                                  </div>
                                  {kpi.isCompleted === false && (
                                    <div
                                      className="fitted-button blue tooltip"
                                      style={{ backgroundColor: 'white' }}
                                      onClick={() => {
                                        setSelectedKpiUpdate(kpi)
                                        setIsOpenUpdateModal(true)
                                      }}
                                    >
                                      <span className="material-symbols-outlined">add</span>
                                    </div>
                                  )}
                                </div>

                                <div
                                  className="kpi-status-table-cell"
                                  style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignContent: 'flex-start',
                                  }}
                                >
                                  <KpiDropdownMenu
                                    onClickEdit={() => {
                                      setSelectedKpi(kpi)
                                      setAddEditModalMode('edit')
                                      setIsOpenAddEditKPIModal(true)
                                    }}
                                    onClickDelete={() => {
                                      deleteKpiFn(kpi)
                                    }}
                                    onClickUpdateComplete={() => {
                                      updateComplete(kpi)
                                    }}
                                    canDelete={kpi.ragStatus === -1}
                                    canEdit={kpi.isCompleted === false}
                                    isCompleted={kpi.isCompleted}
                                  />
                                </div>
                              </>
                            </React.Fragment>
                          </>
                        )
                      })}
                  </div>
                </div>
              </div>
            )}

            {/* {selectedKpiUpdate && updatesList.length === 0 && (
              <div>{t('kpi.noKPIUpdatesMessage')}</div>
            )} */}

            {(kpiList.length <= 0 || !kpiList) && (
              <div style={{ paddingTop: '1rem' }}>{t('kpi.noKPIsMessage')}</div>
            )}
          </div>
        )}
      </Modal>
    </>
  )
}

export default KPIModal

import React from 'react'
import { useTranslation } from 'react-i18next'

const ModalSaveButton = ({ isLoading = false, tag = null, onClick = function () {} }) => {
  const { t } = useTranslation(['Common'])

  return (
    <button
      className={'dual-btn positive-btn ' + (isLoading === true ? 'disabled-button' : '')}
      type="submit"
      disabled={isLoading}
      onClick={() => {
        onClick()
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading === true ? (
          <div
            style={{ width: '0.5rem', height: '0.5rem', margin: '0' }}
            className="loading-spinner"
          ></div>
        ) : null}
        {tag === null ? <div>{t('commonButtons.save')}</div> : <div>{tag}</div>}
      </div>
    </button>
  )
}

const ModalSaveButtonFitted = ({ isLoading = false, tag = null, onClick = function () {}, id }) => {
  const { t } = useTranslation(['Common'])

  return (
    <button
      className={`fitted-button modal-save-button ${isLoading ? 'disabled-button' : 'blue'}`}
      type="submit"
      disabled={isLoading}
      onClick={() => {
        onClick()
      }}
      id={id}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading === true ? (
          <div
            style={{ width: '0.5rem', height: '0.5rem', margin: '0' }}
            className="loading-spinner"
          ></div>
        ) : null}
        {tag === null ? <span>{t('commonButtons.save')}</span> : <span>{tag}</span>}
      </div>
    </button>
  )
}

export { ModalSaveButton as default, ModalSaveButtonFitted }

import React from 'react'
import Modal from '../../../components/Modal'
import { useTranslation } from 'react-i18next'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import Toast from '../../../common/toast'
import InPageLoader from '../../../components/InPageLoader'
import useEmployeeData_2_3 from '../../../api/query/useEmployeeDataLevel2&3'
import './styles.scss'

const RecommendedObjStatementModal = ({
  // This modal displays Q1 obj statement recommendation + Q2-Q5 new child statements recommendations
  isModalOpen,
  closeModal,
  objId,
  ownerId,
  setEditedObj,
  loadAgain,
  level, //level clicked in can be 1 or 2
}) => {
  const { t } = useTranslation(['Common', 'ObjectiveMap'])
  const [isLoading, setIsLoading] = React.useState(false)
  const [existingObjStatement, setExistingObjStatement] = React.useState('')
  const [suggestedObjStatement, setSuggestedObjStatement] = React.useState('')
  const [selectedWinningAspiration, setSelectedWinningAspiration] = React.useState(null)
  const [winningAspirationErrorMessage, setWinningAspirationErrorMessage] = React.useState(null)

  const [suggestedChildrenObjectives, setSuggestedChildrenObjectives] = React.useState([])

  const [suggestedChildrenObjectivesWithErrors, setSuggestedChildrenObjectivesWithErrors] =
    React.useState([])

  React.useEffect(() => {
    if (
      suggestedChildrenObjectivesWithErrors &&
      suggestedChildrenObjectivesWithErrors?.length > 0
    ) {
      // Create a copy and set the 'error' property to false for all suggestedChildrenObjectives
      let tempSuggestedChildrenObjectives = suggestedChildrenObjectives.map((obj) => ({
        ...obj,
        error: false,
        errorFields: {},
      }))

      for (let i = 0; i < suggestedChildrenObjectivesWithErrors.length; i++) {
        for (let j = 0; j < tempSuggestedChildrenObjectives.length; j++) {
          if (
            suggestedChildrenObjectivesWithErrors[i].category ===
            tempSuggestedChildrenObjectives[j].category
          ) {
            tempSuggestedChildrenObjectives[j].error = true
            tempSuggestedChildrenObjectives[j].errorFields = {
              ...tempSuggestedChildrenObjectives[j].errorFields,
              ...suggestedChildrenObjectivesWithErrors[i].errorFields,
            }
          }
        }
      }
      setSuggestedChildrenObjectives(tempSuggestedChildrenObjectives)
    }
  }, [suggestedChildrenObjectivesWithErrors])

  const { data: dataEmpLevel23 } = useEmployeeData_2_3()

  React.useEffect(() => {
    if (objId) {
      getSuggestedObjective()
    }
  }, [])

  const textInputChangeHandler = (event) => {
    setSuggestedObjStatement(event.target.value)
  }

  const getSuggestedObjective = async () => {
    setIsLoading(true)
    try {
      let response = await postHttpRequest('/generate_objective_recommendations', {
        objId: objId,
      })

      if (response) {
        if (response.existingStatement) {
          setExistingObjStatement(response.existingStatement)
        }
        if (response?.winningRecommendation !== null && response?.winningRecommendation !== '') {
          setSuggestedObjStatement(response.winningRecommendation)
        } else {
          setSelectedWinningAspiration('unrequired')
        }
        if (response?.childrenRecommendation?.recommendedL3Objectives) {
          setSuggestedChildrenObjectives(response.childrenRecommendation.recommendedL3Objectives)
        } else {
          setSuggestedChildrenObjectives(null) //makes it unrequired
        }
      }
    } catch (error) {
      console.log(error)
      setSelectedWinningAspiration('unrequired')
      setSuggestedChildrenObjectives(null)

      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    setIsLoading(false)
  }

  const saveSuggestedResponse = async (action) => {
    setIsLoading(true)

    let obj = {
      objId: objId,
      ownerId: ownerId, //must send this with current save function called in /generate_accepted_recommendation to edit obj
      objectiveType: level === 1 ? 'cxo' : 'enterprise',

      suggestedChildrenObjectives: suggestedChildrenObjectives, //eg.[{category='winning_strategy', statement: "string", ownerId: int, include:boolean}...]
    }

    if (selectedWinningAspiration !== 'unrequired') {
      //TODO:change this to check if ot was send or not from db
      obj = {
        ...obj,
        winningAspiration: {
          current: existingObjStatement,
          suggested: suggestedObjStatement,
          status: selectedWinningAspiration,
        }, //{ current:suggestedObjStatement, suggested:suggestedObjStatement, status:bool} or null
      }
    }

    try {
      let response = await postHttpRequest('/handle_user_answer_to_recommendation', obj)
      if (response.ok === true && response.statement !== null) {
        //if null it means it never changed the statement
        setEditedObj({
          objId: parseInt(objId),
          statement: response.acceptedRecommendation,
        })
      }
      closeModal()
    } catch (error) {
      console.log(error)
      closeModal()
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    loadAgain()
    setIsLoading(false)
  }

  const handleCheckboxChange = (index) => {
    setSuggestedChildrenObjectives((prevObjectives) =>
      prevObjectives.map((obj, i) => (i === index ? { ...obj, include: !obj.include } : obj)),
    )
    if (suggestedChildrenObjectives[index].include === false) {
      setSuggestedChildrenObjectives((prevObjectives) =>
        prevObjectives.map((obj, i) => (i === index ? { ...obj, ownerId: '' } : obj)),
      )
    }
  }

  const handleStatementChange = (index, value) => {
    setSuggestedChildrenObjectives((prevObjectives) =>
      prevObjectives.map((obj, i) => (i === index ? { ...obj, statement: value } : obj)),
    )
  }

  const handleOwnerIdChange = (index, value) => {
    setSuggestedChildrenObjectives((prevObjectives) =>
      prevObjectives.map((obj, i) => (i === index ? { ...obj, ownerId: value } : obj)),
    )
  }

  const validateRecommendationsUserInput = () => {
    let tempSuggestedChildrenObjectives = [...suggestedChildrenObjectives]
    let atLeastOneError = false
    let tempSuggestedChildrenObjectivesWithErrors = []
    let isWinningAspirationError = false

    if (selectedWinningAspiration !== 'unrequired') {
      //it means if it is a required field
      if (suggestedObjStatement.trim().length > 0 && selectedWinningAspiration === null) {
        isWinningAspirationError = true
        setWinningAspirationErrorMessage(
          t('ObjectiveMap:recommendedObjStatementModal.acceptOrRejectErrorMessage'),
        )
      }

      if (suggestedObjStatement.trim().length === 0 && selectedWinningAspiration === true) {
        isWinningAspirationError = true
        setWinningAspirationErrorMessage(
          t('ObjectiveMap:recommendedObjStatementModal.suggestedObjStatementErrorMessage'),
        )
      }
    }

    for (let obj of tempSuggestedChildrenObjectives) {
      let objWithErrors = { ...obj, errorFields: {} } // Object to store fields with errors

      if (obj?.include === true) {
        if (obj?.statement && obj?.statement.trim().length > 0) {
          objWithErrors.errorFields.statement = false
        } else {
          objWithErrors.errorFields.statement = true
          atLeastOneError = true
        }

        if (obj?.ownerId != null) {
          objWithErrors.errorFields.ownerId = false
        } else {
          objWithErrors.errorFields.ownerId = true
          atLeastOneError = true
        }

        if (atLeastOneError) {
          tempSuggestedChildrenObjectivesWithErrors.push(objWithErrors)
        }
      }
    }

    if (atLeastOneError === true || isWinningAspirationError === true) {
      setSuggestedChildrenObjectivesWithErrors(tempSuggestedChildrenObjectivesWithErrors)
      return false
    }
    setWinningAspirationErrorMessage(null)
    return true
  }
  console.log('suggestedChildrenObjectives', suggestedChildrenObjectives)
  console.log('selectedWinningAspiration', selectedWinningAspiration)
  return (
    <>
      <Modal closeModal={closeModal}>
        <div className="modal-gray-box">
          <div className="modal-inner-content">
            <div className="modal-inner-title">
              {t('ObjectiveMap:recommendedObjStatementModal.title')}
            </div>
            {isLoading && <InPageLoader inComponent={true} />}
            {!isLoading && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {selectedWinningAspiration === 'unrequired' &&
                  suggestedChildrenObjectives === null && (
                    <span style={{ textAlign: 'left' }}>
                      {t('ObjectiveMap:recommendedObjStatementModal.thereAreNoRecommendations')}
                    </span>
                  )}

                {selectedWinningAspiration !== 'unrequired' && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '2rem',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ display: 'flex', alignSelf: 'left' }}>
                          <b>
                            {t(
                              'ObjectiveMap:recommendedObjStatementModal.currentObjectiveStatement',
                            )}
                            :
                          </b>
                        </span>

                        <textarea
                          className="rc-textarea"
                          rows="6"
                          id="multiSubActivityText"
                          name="multiSubActivityText"
                          value={existingObjStatement}
                          disabled={true}
                          style={{
                            resize: 'none',
                            marginTop: '1rem',
                            border: 'none',
                            borderRadius: '10px',
                            padding: '0.5rem',
                            backgroundColor: 'rgb(242, 242, 242)',
                            fontSize: '14px',
                            width: '26rem',
                          }}
                        />
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ display: 'flex', alignSelf: 'left' }}>
                          <b>
                            {t(
                              'ObjectiveMap:recommendedObjStatementModal.suggestedObjectiveStatement',
                            )}
                            :
                          </b>
                        </span>

                        <textarea
                          rows="6"
                          id="multiSubActivityText"
                          name="multiSubActivityText"
                          value={suggestedObjStatement}
                          onChange={(e) => {
                            textInputChangeHandler(e)
                          }}
                          style={{
                            resize: 'none',
                            marginTop: '1rem',
                            border: 'none',
                            borderRadius: '10px',
                            padding: '0.5rem',
                            backgroundColor: 'rgb(242, 242, 242)',
                            fontSize: '14px',
                            width: '26rem',
                          }}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '2rem',
                        width: '100%',
                        justifyContent: 'center',
                        marginTop: '2rem',
                      }}
                    >
                      <div
                        className={`option-button recommendations-button`}
                        style={{
                          backgroundColor:
                            selectedWinningAspiration === false ? '#4472c4' : '#a6a6a6',
                        }}
                        onClick={() => {
                          setSelectedWinningAspiration(false)
                        }}
                      >
                        {t('commonButtons.reject')}
                      </div>
                      <div
                        className={`option-button recommendations-button`}
                        style={{
                          backgroundColor:
                            selectedWinningAspiration === true ? '#4472c4' : '#a6a6a6',
                        }}
                        onClick={() => {
                          setSelectedWinningAspiration(true)
                        }}
                      >
                        {t('commonButtons.accept')}
                      </div>
                    </div>
                    {winningAspirationErrorMessage && (
                      <div
                        className="validation-error"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          justifyContent: 'center',
                          paddingTop: '1rem',
                        }}
                      >
                        {winningAspirationErrorMessage}
                      </div>
                    )}
                  </div>
                )}
                {suggestedChildrenObjectives?.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      marginTop: '2rem',
                    }}
                  >
                    {level === 1 ? (
                      <b>
                        {t('ObjectiveMap:recommendedObjStatementModal.l2ObjectivesRecommendations')}
                        :
                      </b>
                    ) : (
                      <b>
                        {t('ObjectiveMap:recommendedObjStatementModal.l3ObjectivesRecommendations')}
                        :
                      </b>
                    )}

                    <div style={{ marginBottom: '3rem', paddingTop: '2rem', alignSelf: 'center' }}>
                      <div className="objectives-recommendations-table">
                        <div className="objectives-recommendations-table-header">
                          {' '}
                          {t('ObjectiveMap:recommendedObjStatementModal.addObjective')}?
                        </div>
                        <div className="objectives-recommendations-table-header">
                          {t('ObjectiveMap:recommendedObjStatementModal.statement')}
                        </div>
                        <div className="objectives-recommendations-table-header">
                          {t('ObjectiveMap:recommendedObjStatementModal.owner')}
                        </div>
                      </div>

                      {suggestedChildrenObjectives.map((objective, index) => (
                        <div className="objectives-recommendations-table" key={index}>
                          <div
                            className={`objectives-recommendations-table-cell ${
                              objective.errorFields && objective.errorFields.include
                                ? 'input-error'
                                : ''
                            }`}
                            style={{ justifyContent: 'center', alignItems: 'center' }}
                          >
                            <input
                              type="checkbox"
                              checked={objective.include}
                              onChange={() => handleCheckboxChange(index)}
                            />
                          </div>

                          <div
                            className={`objectives-recommendations-table-cell ${
                              objective.errorFields && objective.errorFields.statement
                                ? 'input-error'
                                : ''
                            }`}
                          >
                            <textarea
                              rows="4"
                              value={objective.statement}
                              onChange={(e) => handleStatementChange(index, e.target.value)}
                              style={{
                                marginRight: '1rem',
                                marginleft: '0.5rem',
                                width: '100%',
                                resize: 'none',
                                padding: '0.5rem',
                                fontSize: '14px',
                              }}
                            />
                          </div>

                          <div
                            className={`objectives-recommendations-table-cell ${
                              objective.errorFields && objective.errorFields.ownerId
                                ? 'input-error'
                                : ''
                            }`}
                          >
                            <select
                              value={objective.ownerId}
                              onChange={(e) => handleOwnerIdChange(index, e.target.value)}
                              disabled={!objective.include}
                              style={{
                                marginRight: '1rem',
                                marginleft: '0.5rem',
                                height: 'fit-content',
                              }}
                            >
                              <option value="">{t('Common:formPlaceHolder.select')}</option>
                              {dataEmpLevel23?.employees
                                .filter(
                                  (emp) =>
                                    (level === 1 ? emp.level === 2 : emp.level === 3) &&
                                    !emp.isDeactivated,
                                )
                                .map((employee) => (
                                  <option key={employee.eId} value={employee.eId}>
                                    {employee.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '2rem',
                    width: '100%',
                    justifyContent: 'center',
                    marginTop: '2rem',
                  }}
                >
                  <div
                    className={`sleek-button sleek-blue`}
                    onClick={() => {
                      closeModal()
                    }}
                  >
                    {t('commonButtons.close')}
                  </div>
                  {suggestedChildrenObjectives != null &&
                    selectedWinningAspiration != 'unrequired' && (
                      <div
                        className={`sleek-button sleek-full-blue`}
                        onClick={() => {
                          if (validateRecommendationsUserInput() === true) {
                            saveSuggestedResponse()
                            closeModal()
                          }
                        }}
                      >
                        {t('commonButtons.save')}
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default RecommendedObjStatementModal

import React from 'react'
import Modal from '../../../../../../components/Modal'
import { getHttpRequest, postHttpRequest } from '../../../../../../api/query/dynamicAPI'
import TextArea from 'rc-textarea'
import Toast from '../../../../../../common/toast'
import InPageLoader from '../../../../../../components/InPageLoader'
import AudioToTextModal from '../../../../../../components/AudioToTextModal/AudioToTextModal'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { removeMentionMarkupFromText } from '../../../../../../utils/parseMarkup'
// import SimpleMdeReact from 'react-simplemde-editor'
// import "easymde/dist/easymde.min.css";
import './style.css'
import { MDXEditor, diffSourcePlugin, MDXEditorMethods } from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { ALL_PLUGINS } from './utils'
// import LexicalMarkdownEditor from './LexicalMarkdownEditor'
import { getCompanyConfigSettings } from '../../../../../../utils/auth'

const AiMemoModal = ({
  isModalOpen,
  closeModal,
  memoMaterial,
  memoAction,
  isFromAiMemoQuestionsModal = false,
  setIsAiMemoQuestionsModalOpen = () => {},
  setShowEng = () => {},
  showEng,
}) => {
  const { t } = useTranslation(['Common'])

  const [memo, setMemo] = React.useState('')
  const [documentId, setDocumentId] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [suggestions, setSuggestions] = React.useState('')
  const [isAudioToTextModalOpen, setIsAudioToTextModalOpen] = React.useState(false)
  const [audioText, setAudioText] = React.useState('')
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)
  const [isSaved, setIsSaved] = React.useState(true)

  const [isTranslating, setIsTranslating] = React.useState(false)
  const [isEnglish, setIsEnglish] = React.useState(showEng)
  // const [isEnglish, setIsEnglish] = React.useState(getCompanyConfigSettings('languageSettings') !== 'es')

  const productSettings = getCompanyConfigSettings('productSettings')

  const ref = React.useRef(null)

  const [toggleLanguage, setToggleLanguage] = React.useState(false)

  const suggestedEditsEng =
    'If you would like Odin to edit the memo, tell it what you would like changed'
  const suggestedEditsEsp = 'Si desea que Odin edite el memo creado, dígale que le gustaría cambiar'

  const draftMemoEng = 'Here is a draft of the memo, feel free to directly edit here'
  const draftMemoEsp =
    'Aquí hay un borrador del memorando, siéntase libre de editarlo directamente aquí'

  React.useEffect(() => {
    // we should only call updateLanguge explicitly when toggleLanguage is true, which means user has click on the toggle
    console.log(isEnglish)
    if (documentId !== '' && toggleLanguage) {
      console.log(2)
      updateDocumentLanguageSetting(isEnglish)
    }
  }, [isEnglish, toggleLanguage])

  const updateDocumentLanguageSetting = async (isEnglish) => {
    setIsTranslating(true)
    try {
      let response = await postHttpRequest('/update_document_language_setting', {
        documentId: documentId,
        language: isEnglish ? 'ENGLISH' : 'SPANISH',
        memo: memo,
      })
      setMemo(response?.memo ?? '')
      ref?.current?.setMarkdown(response?.memo ?? '')
      setIsSaved(false)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
    setIsTranslating(false)
  }

  React.useEffect(() => {
    // if memoMaterial is not null/undefined (then it's generated from QA modal), generate new memo
    // otherwise get existing memo by action id
    if (memoMaterial && isFromAiMemoQuestionsModal) {
      setIsProcessing(true)
      generateNewMemo()
    } else if (!isFromAiMemoQuestionsModal) {
      setIsLoading(true)
      getMemoByActionId()
    }
  }, [memoMaterial, isFromAiMemoQuestionsModal])

  React.useEffect(() => {
    setSuggestions(audioText)
  }, [audioText])

  const handleSuggestionChange = (e) => {
    setSuggestions(e.target.value)
  }

  const onChange = (value) => {
    setIsSaved(false)
    setMemo(value)
  }

  const getMemoByActionId = async () => {
    try {
      let response = await getHttpRequest(`/get_ai_memo_by_action/${memoAction.milestoneId}`)
      setMemo(response?.memo ?? '')
      setDocumentId(response?.documentId ?? '')
      setIsEnglish(response?.language === 'ENGLISH' ? true : false)
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: error.response.data,
      })
    }
    setIsLoading(false)
  }

  const generateNewMemo = async () => {
    try {
      memoMaterial.language = productSettings ? 'ENGLISH' : isEnglish ? 'ENGLISH' : 'SPANISH'
      let response = await postHttpRequest('/generate_ai_memo', memoMaterial)
      setMemo(response?.memo ?? '')
      setDocumentId(response?.documentId ?? '')
      setIsAiMemoQuestionsModalOpen(false)
    } catch (error) {
      if (error?.response?.status === 400) {
        Toast.fire({
          icon: 'error',
          title: error.response.data,
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      }
      closeModal(true)
    }
    setIsProcessing(false)
  }

  const updateMemo = async () => {
    try {
      let response = await postHttpRequest(`/edit_ai_memo/${documentId}`, {
        memo: memo,
        suggestions: suggestions,
        language: isEnglish ? 'ENGLISH' : 'SPANISH',
      })
      setMemo(response?.memo ?? '')
      ref?.current?.setMarkdown(response?.memo ?? '')
      setIsSaved(false)
      setDocumentId(response?.documentId ?? '')
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
    setIsProcessing(false)
  }

  const saveMemo = async () => {
    try {
      let response = await postHttpRequest(`/save_ai_memo/${documentId}`, {
        // we can also use ref.current?.getMarkdown() to get the latest markdown content
        memo: memo,
      })
      if (response.success) {
        setIsSaved(true)
        Toast.fire({
          icon: 'success',
          title: 'Memo saved successfully',
        })
      }
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
    setIsSaving(false)
  }

  const deleteMemo = async () => {
    try {
      const result = await Swal.fire({
        title: t('Common:modalMessages.areYouSureDeleteThisMemo'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('Common:commonButtons.yes'),
        cancelButtonText: t('Common:commonButtons.no'),
      })
      if (result.isConfirmed) {
        let response = await postHttpRequest(`/delete_ai_memo/${documentId}`)
        if (response.success) {
          Toast.fire({
            icon: 'success',
            title: 'Memo deleted successfully',
          })
          closeModal()
        }
      }
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const downloadMemo = async () => {
    if (!isSaved) {
      await Swal.fire({
        title: t('aiMemo.saveWarning'),
        icon: 'info',
        confirmButtonText: t('commonButtons.close'),
      })
      return
    }
    // let response = await getHttpRequest(`/download_ai_memo_md_as_docx/${documentId}`, {
    //     responseType: 'blob'
    // })
    const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
    const url =
      BASE_URL +
      `/download_ai_memo_md_as_docx/${documentId}?action=${encodeURIComponent(
        memoAction.description,
      )}`
    window.open(url, '_blank')
    // console.log('BASE_URL: ', BASE_URL)
    // const url = new URL(`${BASE_URL}/download_ai_memo_md_as_docx/${documentId}`)
    // console.log('url: ', url)
    // url.searchParams.append('action', memoAction.description)
    // console.log('url 2: ', url)
    // window.open(url.toString(), '_blank');
    // // response is file from send_file in flask
    // const url = window.URL.createObjectURL(new Blob([response.data],
    //     { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', 'memo.docx');
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // URL.revokeObjectURL(url);
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width="95%"
      modalContainerStyle={{ width: '93%' }}
      showCard={true}
      title={removeMentionMarkupFromText(memoAction.description)}
      titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
    >
      {isAudioToTextModalOpen && (
        <AudioToTextModal
          startRecording={true}
          isModalOpen={isAudioToTextModalOpen}
          type="meetingHubCommentsActions"
          closeModal={() => {
            setIsAudioToTextModalOpen(false)
          }}
          setAudioText={setAudioText}
        />
      )}
      {isLoading && <InPageLoader inComponent={true} />}
      {isProcessing && (
        <>
          <div style={{ fontSize: '25px', marginBottom: '2rem' }}>
            <b>{t('aiMemo.writeMemo')}</b>
          </div>
          <InPageLoader inComponent={true} />
        </>
      )}
      {isTranslating && (
        <>
          <div style={{ fontSize: '25px', marginBottom: '2rem' }}>
            <b>{t('aiMemo.translateMemo')}</b>
          </div>
          <InPageLoader inComponent={true} />
        </>
      )}
      {!isLoading && !isProcessing && !isTranslating && (
        // <UpdateUsingButtonWithAutofocus/>

        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}
        >
          {!productSettings && (
            <div
              style={{
                marginLeft: 'auto',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '0.5rem',
                width: '9rem',
              }}
            >
              <div class="tooltip">
                <span class="material-symbols-outlined">info</span>
                <span class="tooltiptext" style={{ fontSize: '15px', width: '20rem' }}>
                  {t('aiMemo.info')}{' '}
                </span>
              </div>
              <span>{isEnglish ? t('aiMemo.eng') : t('aiMemo.esp')}</span>
              <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                onClick={() => {
                  setToggleLanguage(true)
                  setIsEnglish((prev) => !prev)
                }}
              >
                {!isEnglish && (
                  <span
                    style={{ fontSize: '35px', color: 'rgb(176, 173, 173)' }}
                    class="material-symbols-outlined"
                  >
                    toggle_off
                  </span>
                )}

                {isEnglish && (
                  <span
                    style={{ fontSize: '35px', color: 'rgb(176, 173, 173)' }}
                    class="material-symbols-outlined"
                  >
                    toggle_on
                  </span>
                )}
              </div>
            </div>
          )}
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '90%', marginTop: '1rem' }}
          >
            <div style={{ textAlign: 'left', marginBottom: '1rem' }}>
              <b>{isEnglish ? draftMemoEng : draftMemoEsp}</b>
            </div>
            {/* <div className="review-generated-topics-list-item"> */}
            {/* <TextArea
                            rows={20}
                            type="text"
                            onChange={(e) => {
                                handleMemoTextChange(e)
                            }}
                            value={memo}
                            style={{height:'70%', backgroundColor: '#f1f0f0', width:'100%'}}
                        /> */}
            {/* </div> */}
            {/* <div className='editor-wrapper'> */}
            {/* <SimpleMdeReact 
                        value={memo} 
                        onChange={onChange}
                        options={editorOptions}
                        style={{textAlign:'left',  backgroundColor: '#f1f0f0'}}
                        getMdeInstance={getMdeInstanceCallback}
                    /> */}
            <MDXEditor
              markdown={memo}
              ref={ref}
              plugins={ALL_PLUGINS}
              onChange={onChange}
              contentEditableClassName="unique-editor-style"
              onError={(error) => console.log(error)}
            />
            {/* <LexicalMarkdownEditor 
                            value={memo}
                        /> */}

            {/* </div> */}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '1rem' }}
            >
              <div style={{ textAlign: 'left' }}>
                <b>{isEnglish ? suggestedEditsEng : suggestedEditsEsp}</b>
              </div>
              <div className="review-generated-topics-list-item">
                <TextArea
                  rows={3}
                  type="text"
                  onChange={(e) => {
                    handleSuggestionChange(e)
                  }}
                  value={suggestions}
                  style={{ height: '70%', backgroundColor: '#f1f0f0', width: '100%' }}
                  spellCheck={false}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '0.3rem',
                marginLeft: 'auto',
              }}
            >
              {/* <div style={{marginLeft:'auto'}}> */}
              <button
                type="button"
                className="btn-card tonedown-blue-btn"
                style={{ width: '2.5rem', marginRight: '0.75rem' }}
                onClick={(e) => {
                  setIsAudioToTextModalOpen(true)
                }}
              >
                <span class="material-symbols-outlined icon-size">mic</span>
              </button>
              {/* </div> */}
              {/* <div style={{marginLeft:'auto'}}> */}
              <div
                className="sleek-button sleek-full-blue"
                style={{ padding: '0rem 0.6rem 0rem 0.6rem', fontSize: '18px' }}
                onClick={(e) => {
                  setIsProcessing(true)
                  updateMemo()
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <span>{t('commonButtons.applyEdits')}</span>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          <div
            className="modal-dual-btn"
            style={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginTop: '3rem' }}
          >
            <div
              className="sleek-button sleek-full-blue"
              style={{ padding: '0rem 0.6rem 0rem 0.6rem', fontSize: '18px' }}
              onClick={(e) => {
                setIsSaving(true)
                saveMemo()
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {isSaving === true ? (
                  <div
                    style={{
                      width: '0.5rem',
                      height: '0.5rem',
                      margin: '0',
                      borderTopColor: 'white',
                    }}
                    className="loading-spinner icon-size-l"
                  ></div>
                ) : null}
                <span>{t('commonButtons.saveSmall')}</span>
              </div>
            </div>
            <div
              className="sleek-button sleek-full-blue"
              style={{ padding: '0rem 0.6rem 0rem 0.6rem', fontSize: '18px' }}
              onClick={(e) => {
                deleteMemo()
              }}
            >
              <span>{t('commonButtons.deleteSmall')}</span>
            </div>
            <div
              className="sleek-button sleek-full-blue"
              style={{ padding: '0rem 0.6rem 0rem 0.6rem', fontSize: '18px' }}
              onClick={(e) => {
                downloadMemo()
              }}
            >
              <span>{t('filingCabinet.download')}</span>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default AiMemoModal

import React, { useState, useEffect } from 'react'
import Modal from '../../Modal'
import InPageLoader from '../../InPageLoader'
import { getKpiList, getKpiUpdates, deleteKpi } from '../../../api/services/kpi.services'
import AddEditKPIModal from '../AddEditKPIModal'
import UpdateKPIModal from '../UpdateKPIModal'
import { useTranslation } from 'react-i18next'
import { shortenName } from '../../../utils/general'
import { dateFilter } from '../../../utils/time'
import CardModal from '../../CardModal'
import { getCompanyConfigSettings } from '../../../utils/auth'
import Toast from '../../../common/toast'
import Swal from 'sweetalert2'
import './styles.scss'

const KPIUpdatesModal = ({
  selectedKpiUpdate,
  reloadBackground,
  handleCancel,
  objId,
  inWizard = false,
  canAddUpdate = true,
  canEditUpdate = true,
}) => {
  const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState('')

  const [updatesList, setUpdatesList] = useState([])
  const [selectedUpdate, setSelectedUpdate] = useState(null)

  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false)
  const [isOpenCardModal, setIsOpenCardModal] = useState(false)
  const [cardModalUmtId, setCardModalUmtId] = useState(0)
  const [cardModalTopicType, setCardModalTopicType] = useState(null)
  const { t } = useTranslation(['Common'])
  const showMeetingDeck = getCompanyConfigSettings('showMeetingDeck') === true

  const [updateModalMode, setUpdateModalMode] = useState('add')

  React.useEffect(() => {
    reload()
  }, [selectedKpiUpdate])

  async function loadUpdates(kpiId) {
    setIsLoading(true)

    let result = await getKpiUpdates(kpiId)
    setIsLoading(false)
    if (result && result.kpiUpdates) {
      setUpdatesList(result.kpiUpdates)
    }
  }

  const reload = () => {
    if (selectedKpiUpdate?.kpiId) {
      loadUpdates(selectedKpiUpdate.kpiId)
    }
  }

  const convertEnumToTextUpdateValue = (enumValue) => {
    switch (enumValue) {
      case 1:
        return t('kpi.notStarted')
      case 2:
        return t('kpi.inProgress')
      case 3:
        return t('kpi.completed')
      default:
        return t('kpi.unknown')
    }
  }

  return (
    <>
      {isOpenUpdateModal && (
        <UpdateKPIModal
          closeModal={() => setIsOpenUpdateModal(false)}
          mode={updateModalMode}
          objId={objId}
          kpi={selectedKpiUpdate}
          kpiUpdateId={selectedUpdate?.kpiUpdateId}
          loadUpdates={() => {
            reload()
            reloadBackground()
          }}
        />
      )}

      {isOpenCardModal && (
        <CardModal
          isModalOpen={isOpenCardModal}
          closeModal={() => setIsOpenCardModal(false)}
          umtId={cardModalUmtId}
          topicType={cardModalTopicType}
        />
      )}

      <Modal
        style={{ zIndex: 103 }}
        title={selectedKpiUpdate.name}
        closeModal={handleCancel}
        width={'70rem'}
      >
        {isLoading && <InPageLoader isLoading={isLoading} />}
        {!isLoading && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            ></div>

            {selectedKpiUpdate && canAddUpdate && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                {t('kpi.addUpdate')}
                <div
                  className="fitted-button blue tooltip"
                  onClick={() => {
                    setUpdateModalMode('add')
                    setIsOpenUpdateModal(true)
                  }}
                >
                  <span className="material-symbols-outlined">add</span>
                </div>
              </div>
            )}

            {selectedKpiUpdate && updatesList.length === 0 && (
              <div>{t('kpi.noKPIUpdatesMessage')}</div>
            )}

            {selectedKpiUpdate && updatesList.length > 0 && (
              <>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                  <span style={{ alignSelf: 'flex-start' }}>
                    <b>{t('kpi.kpiUpdates')}</b>
                  </span>
                  <div className="divider"></div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      className={showMeetingDeck ? 'kpi-updates-table' : 'kpi-updates-table-no-doc'}
                    >
                      <div className="kpi-updates-table-header">{t('kpi.update')}</div>
                      <div className="kpi-updates-table-header">{t('kpi.date')}</div>
                      <div className="kpi-updates-table-header">{t('kpi.madeBy')}</div>
                      <div className="kpi-updates-table-header">{t('kpi.notes')}</div>
                      <div className="kpi-updates-table-header">{t('kpi.status')}</div>
                      <div className="kpi-updates-table-header">{t('kpi.topic')}</div>
                      {showMeetingDeck && (
                        <div className="kpi-updates-table-header">{t('kpi.document')}</div>
                      )}

                      <div className="kpi-updates-table-header"></div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          width: '100%',
                        }}
                      ></div>

                      {updatesList?.map((upd, updateIndex) => {
                        return (
                          <>
                            <React.Fragment key={`objective-${upd.kpiUpdateId}`}>
                              <div className="chart-log-meeting-description"></div>
                              <>
                                <div style={{ textAlign: 'left' }}>
                                  {selectedKpiUpdate.kpiType === 3
                                    ? convertEnumToTextUpdateValue(upd.amount)
                                    : upd.amount.toLocaleString()}
                                </div>
                                <div style={{ textAlign: 'left' }}>{dateFilter(upd.tsCreated)}</div>
                                <div style={{ textAlign: 'left' }}>{shortenName(upd.madeBy)}</div>
                                <div style={{ textAlign: 'left' }}>{upd?.comment}</div>
                                <div style={{ textAlign: 'left' }}>
                                  {
                                    <div
                                      style={{
                                        backgroundColor:
                                          upd.rag === 0 ? 'green' : upd.rag === 1 ? 'gold' : 'red',
                                        width: '2rem',
                                        height: '2rem',
                                        marginLeft: '1rem',
                                      }}
                                    ></div>
                                  }
                                </div>

                                <div style={{ textAlign: 'left' }}>
                                  {upd.rag === 0 && <span>{'---'}</span>}
                                  {upd.umtId > 0 && (upd.rag === 1 || upd.rag === 2) && (
                                    <span
                                      style={{
                                        color: 'blue',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        setCardModalUmtId(upd.umtId)

                                        if (upd.rag === 1) {
                                          setCardModalTopicType(2)
                                        } else if (upd.rag === 2) {
                                          setCardModalTopicType(1)
                                        }

                                        setIsOpenCardModal(true)
                                      }}
                                    >
                                      {upd.rag === 1
                                        ? t('kpi.seeGuidance')
                                        : upd.rag === 2
                                          ? t('kpi.seeBlocker')
                                          : '---'}
                                    </span>
                                  )}
                                </div>
                                {showMeetingDeck && (
                                  <div style={{ textAlign: 'left' }}>
                                    {upd?.fileName !== '' ? (
                                      <a
                                        style={{ color: 'blue', textDecoration: 'underline' }}
                                        href={`${BASE_URL}/export_filing_cabinet_file/${upd.fileId}`}
                                        target="_blank"
                                      >
                                        {upd?.fileName}
                                      </a>
                                    ) : (
                                      '---'
                                    )}
                                  </div>
                                )}
                                {canEditUpdate && (
                                  <div style={{ textAlign: 'left' }}>
                                    <div
                                      class="fitted-button blue"
                                      onClick={() => {
                                        setSelectedUpdate(upd)
                                        setUpdateModalMode('edit')
                                        setIsOpenUpdateModal(true)
                                      }}
                                    >
                                      <span class="material-symbols-outlined">edit</span>
                                    </div>
                                  </div>
                                )}
                              </>
                            </React.Fragment>
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </Modal>
    </>
  )
}

export default KPIUpdatesModal

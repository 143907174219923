import React, { useState, useEffect, useRef } from 'react'
import Modal from '../../Modal'
import InPageLoader from '../../InPageLoader'
import { useTranslation } from 'react-i18next'
import { Formik, Field, Form } from 'formik'
import { updateKpi, getKpiUpdateById } from '../../../api/services/kpi.services'
import Toast from '../../../common/toast'
import * as Yup from 'yup'
import { getCompanyConfigSettings } from '../../../utils/auth'
import { useUpdateContext } from '../../../pages/Update/context/updateContext'
import { ModalSaveButtonFitted } from '../../ModalSubmitButton'

const UpdateKPIModal = ({
  closeModal,
  mode = 'add',
  kpi,
  objId,
  loadUpdates = () => {},
  kpiUpdateId,
  setKpiUpdateId = () => {},
  setLatestRagStatus = () => {},
  isInTutorial = false,
}) => {
  //mode = "add" or "edit"
  const [isLoading, setIsLoading] = useState(true)
  const { state, dispatch } = useUpdateContext()

  React.useEffect(() => {
    loadDataIfEdit()
    setIsLoading(false)
  }, [])

  React.useEffect(() => {
    if (!isLoading && isInTutorial) {
      dispatch({
        type: 'SET_IS_TOUR_ACTIVE',
        isTourActive: true,
        tourIndex: 1,
      })
    }
  }, [isLoading])

  const loadDataIfEdit = async () => {
    if (mode === 'edit' && kpiUpdateId) {
      const data = await getKpiUpdateById(kpiUpdateId)
      if (data) {
        setUpdate(data)
      }
    }
  }

  const [update, setUpdate] = useState(null)
  const [file, setFile] = React.useState(null)
  const { t } = useTranslation(['Common'])
  const showMeetingDeck = getCompanyConfigSettings('showMeetingDeck') === true

  const buttonStatusStyle = {
    boxShadow: 'inset orange 0 0 0 5px',
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#009688',
  }

  const kpiMilestoneTypeValueList = [
    { label: t('kpi.notStarted'), value: 1 },
    { label: t('kpi.inProgress'), value: 2 },
    { label: t('kpi.completed'), value: 3 },
  ]

  const fileUploadRef = React.useRef(null)

  const handleUploadFileClick = () => {
    fileUploadRef.current.click()
  }

  const updateKpiSchema = (requiredMessage, requireNumber) => {
    return Yup.object().shape({
      updateValue: Yup.number().required(requiredMessage).typeError(requireNumber),
      rag: Yup.number().required(requiredMessage),
      // comment: Yup.string().when('rag', {
      //   is: 0,//green
      //   then: Yup.string().required(requiredMessage),
      //   otherwise: Yup.string(),
      // }),
      blocker: Yup.string().when('rag', {
        is: 2,
        then: Yup.string().required(requiredMessage),
        otherwise: Yup.string(),
      }),
      guidance: Yup.string().when('rag', {
        is: 1,
        then: Yup.string().required(requiredMessage),
        otherwise: Yup.string(),
      }),
    })
  }
  const validationSchema = updateKpiSchema(t('Common:required'), t('Common:mustBeNumber'))

  const handleSubmit = async (values) => {
    let formData = new FormData()

    formData.append('amount', values.updateValue)
    formData.append('rag', parseInt(values.rag))
    formData.append('kpiId', kpi.kpiId)
    formData.append('hasAttachment', false)
    formData.append('comment', values.comment)
    formData.append('objId', objId)
    formData.append('kpiType', kpi.kpiType)

    if (values.rag === 2) {
      formData.append('umt', values.blocker)
    }
    if (values.rag === 1) {
      formData.append('umt', values.guidance)
    }

    if (file) {
      formData.append('file', file, file.name)
      formData.append('hasAttachment', true)
    }

    if (mode === 'edit') {
      formData.append('updateId', update.kpiUpdateId)

      formData.append('originalRag', update.rag)

      if (update.umtId > 0) {
        formData.append('umtId', update.umtId)
      }
    }

    let response = await updateKpi(formData)
    setIsLoading(false)
    if ((response.upload_status === 'success' || response.success) && response.kpi_update_id) {
      Toast.fire({
        icon: 'success',
        title: mode === 'add' ? t('kpi.updateAddSuccess') : 'editing kpi update was successful',
      })
      setKpiUpdateId(response.kpi_update_id)
      setLatestRagStatus(parseInt(values.rag))
      loadUpdates(kpi.kpiId)
    } else {
      let errorResult = response.data.upload_status
      if (errorResult === 'timeout') {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.fileUploadRequestTimeout'),
        })
      } else if (errorResult === 'malicious') {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.fileScanningFailed'),
        })
      } else {
        // Default error handling for other cases
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    }
  }

  async function saveFileLocally(e) {
    const files = e.target.files

    setFile(files[0])
  }

  return (
    <>
      <Modal
        style={{ zIndex: 104, position: `${isInTutorial ? 'absolute' : 'fixed'}` }}
        width={'40rem'}
        title={kpi.name}
        closeModal={closeModal}
      >
        {isLoading && <InPageLoader isLoading={isLoading} />}
        {!isLoading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
              marginBottom: '1rem',
              gap: '1rem',
              marginLeft: '0.4rem',
            }}
          >
            {(kpi.kpiType === 1 || kpi.kpiType === 2) && (
              <span>
                {' '}
                <b>{t('kpi.target')}:</b> {kpi.target}
                {kpi.kpiType === 1 ? '%' : ' ' + kpi?.unit}
              </span>
            )}
            <span>
              <b>{t('kpi.reachBy')}:</b> {kpi.targetDate}
            </span>
          </div>
        )}
        {!isLoading && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="divider" style={{ width: '96%' }}></div>
          </div>
        )}
        {!isLoading && (
          <Formik
            enableReinitialize
            initialValues={{
              updateValue:
                mode === 'edit' ? (kpi.kpiType === 3 ? update?.amount : update?.amount) : '',
              rag: mode === 'edit' ? update?.rag : isInTutorial ? 0 : '',
              comment: mode === 'edit' ? update?.comment : '',
              blocker: mode === 'edit' ? update?.umtDescription : '',
              guidance: mode === 'edit' ? update?.umtDescription : '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values)
              closeModal(true)
              resetForm()
            }}
          >
            {({ errors, touched, resetForm, setFieldValue, values }) => (
              <Form className="objective-form">
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div className="form-field">
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <label>{t('kpi.update')}</label>
                        {(kpi.kpiType === 1 || kpi.kpiType === 2) && (
                          <span> &nbsp; &#40;{kpi.kpiType === 1 ? '%' : kpi?.unit}&#41;</span>
                        )}
                        <span style={{ color: 'red', marginLeft: '0.2rem' }}>*</span>
                      </div>

                      {kpi.kpiType === 3 ? (
                        <Field
                          as="select"
                          id="updateValue"
                          name="updateValue"
                          style={{ width: '21rem', height: '2.5rem' }}
                        >
                          <option value=""></option>

                          {kpiMilestoneTypeValueList?.map((item, itemIndex) => {
                            return (
                              <option
                                key={`level1-${itemIndex}`}
                                id={item.value}
                                value={item.value}
                              >
                                {item.label}
                              </option>
                            )
                          })}
                        </Field>
                      ) : (
                        <Field
                          type="text"
                          maxLength="200"
                          id="updateValue"
                          name="updateValue"
                          autoComplete="off"
                          style={{ width: '20rem' }}
                        />
                      )}
                      {errors.updateValue && touched.updateValue && (
                        <div className="validation-error">{errors.updateValue}</div>
                      )}
                    </div>

                    <div className="form-field">
                      <label>
                        {t('kpi.status')}{' '}
                        <span style={{ color: 'red', marginLeft: '0.2rem' }}>*</span>{' '}
                      </label>
                      <div
                        id="uw-kpi-update-modal-status"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          columnGap: '2rem',
                        }}
                      >
                        <div
                          style={
                            values.rag === 0
                              ? {
                                  ...buttonStatusStyle,
                                  backgroundColor: 'green',
                                  width: '2rem',
                                  height: '2rem',
                                }
                              : { backgroundColor: 'green', width: '2rem', height: '2rem' }
                          }
                          onClick={() => setFieldValue('rag', 0)}
                        ></div>
                        <div
                          style={
                            values.rag === 1
                              ? {
                                  ...buttonStatusStyle,
                                  backgroundColor: 'gold',
                                  width: '2rem',
                                  height: '2rem',
                                }
                              : { backgroundColor: 'gold', width: '2rem', height: '2rem' }
                          }
                          onClick={() => setFieldValue('rag', 1)}
                        ></div>
                        <div
                          style={
                            values.rag === 2
                              ? {
                                  ...buttonStatusStyle,
                                  backgroundColor: 'red',
                                  width: '2rem',
                                  height: '2rem',
                                }
                              : { backgroundColor: 'red', width: '2rem', height: '2rem' }
                          }
                          onClick={() => setFieldValue('rag', 2)}
                        ></div>
                      </div>
                      {errors.rag && touched.rag && (
                        <div className="validation-error">{errors.rag}</div>
                      )}
                    </div>

                    {showMeetingDeck && (
                      <>
                        <div className="form-field">
                          <label>{t('kpi.uploadSupportingDocument')}</label>
                          <div
                            id="uw-kpi-update-modal-doc"
                            style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}
                          >
                            <div
                              class="fitted-button blue tooltip"
                              onClick={handleUploadFileClick}
                              onKeyDown={handleUploadFileClick}
                            >
                              <input
                                type="file"
                                id="myFile"
                                name="filename"
                                ref={fileUploadRef}
                                accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.png,.jpg,.jpeg,.svg,.txt,.csv"
                                onChange={(e) => {
                                  saveFileLocally(e)
                                }}
                                style={{ display: 'none' }}
                              ></input>

                              <span class="material-symbols-outlined">upload</span>
                            </div>
                            <span>{file?.name}</span>
                          </div>
                        </div>
                      </>
                    )}

                    {values.rag === 0 && (
                      <div className="form-field" id="uw-kpi-update-modal-note">
                        <label>{t('kpi.notes')}</label>
                        <Field
                          component="textarea"
                          maxLength="200"
                          id="comment"
                          name="comment"
                          autoComplete="off"
                          style={{ width: '20rem', resize: 'none' }}
                          rows="4"
                        />
                        {errors.comment && touched.comment && (
                          <div className="validation-error">{errors.comment}</div>
                        )}
                      </div>
                    )}

                    {values.rag === 2 && (
                      <div className="form-field" id="uw-kpi-update-modal-meeting-topic">
                        <label style={{ whiteSpace: 'nowrap' }}>
                          {t('kpi.blockerFormLabel')}{' '}
                          <span style={{ color: 'red', marginLeft: '0.2rem' }}>*</span>{' '}
                        </label>
                        <Field
                          component="textarea"
                          maxLength="200"
                          id="blocker"
                          name="blocker"
                          autoComplete="off"
                          style={{ width: '20rem', resize: 'none' }}
                          rows="4"
                        />
                        {errors.blocker && touched.blocker && (
                          <div className="validation-error">{errors.blocker}</div>
                        )}
                      </div>
                    )}
                    {values.rag === 1 && (
                      <div className="form-field" id="uw-kpi-update-modal-meeting-topic">
                        <label style={{ whiteSpace: 'nowrap' }}>
                          {t('kpi.guidanceFormLabel')}{' '}
                          <span style={{ color: 'red', marginLeft: '0.2rem' }}>*</span>{' '}
                        </label>
                        <Field
                          component="textarea"
                          maxLength="200"
                          id="guidance"
                          name="guidance"
                          autoComplete="off"
                          style={{ width: '20rem', resize: 'none' }}
                          rows="4"
                        />
                        {errors.guidance && touched.guidance && (
                          <div className="validation-error">{errors.guidance}</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="modal-dual-btn"
                  style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
                >
                  <ModalSaveButtonFitted
                    isLoading={isLoading}
                    tag={t('Common:commonButtons.cancel')}
                    onClick={() => {
                      resetForm()
                      closeModal()
                    }}
                  />
                  <ModalSaveButtonFitted
                    isLoading={isLoading}
                    id="uw-kpi-update-modal-update-btn"
                    tag={t('Common:commonButtons.save')}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  )
}

export default UpdateKPIModal

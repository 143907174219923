import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/routes'
import { getCompanyConfigSettings, hasRole } from '../../utils/auth'
import ApplicationLayout from '../../components/ApplicationLayout'
import KanbanMain from './kanbanMain'

import useObjectiveDataList from '../../api/query/getListObjectiveData'

import { ContainerObjective } from './style'
import plus from '../../assets/plus.svg'

import Select from 'react-select'
import useGetAreaEmployees from '../../api/query/useGetAreaEmployees'
import { useFeatureFlagsContext } from '../../utils/featureFlagsContext'
import { useTranslation } from 'react-i18next'
import { getConsolidatedConfigSettings } from '../../utils/auth'
import { generateCommentBubblesColor } from '../../utils/commentBubbles'
import GanttChartModal from '../../components/GanttChartModal/ganttChartModal'
import './style.scss'
import { getMeetingTopic } from '../../api/services//meetingTopic.services'
import SelectSavedFilterViewModal from '../../components/FilterView/SelectSavedFilterViewModal'
import AddEditFilterViewModal from '../../components/FilterView/AddEditFilterViewModal'
import { getFilterViews } from '../../api/services/filterView.services'
import {
  getAllObjectivesInCompany,
  getKanbanObjectivesList,
  getKanbanObjectivesListCustomMode,
} from '../../api/services/objectives.services'
import SearchForObjectivesModal from '../../components/SearchForObjectivesModal'
import PersonalizedKanbanModal from './common/personalizedKanbanModal'
import IsolatedColumnHeader from './isolatedColumnHeader'
import { useLocation } from 'react-router-dom'
import { getObjectivUserList } from '../../api/services/employee.services'

function KanbanView() {
  const { t } = useTranslation(['Common', 'Kanban'])
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [objectives, setObjectives] = useState([])
  const [objIdsToShowTitle, setObjIdsToShowTitle] = React.useState({})
  const { flags } = useFeatureFlagsContext()
  const hideGanttChartButton =
    getConsolidatedConfigSettings('allow_adding_l2_activities') ||
    getCompanyConfigSettings('productSettings')
  const [isGanttChartModalOpen, setIsGanttChartModalOpen] = React.useState(false)

  const {
    data: areaEmployeesData,
    isLoading: isTeamLeaderLoading,
    error: teamLeaderError,
  } = useGetAreaEmployees()

  const [milestonesPeopleList, setMilestonesPeopleList] = React.useState([])
  const [employeesWithColors, setEmployeesWithColors] = React.useState([])
  const [timeFilterOptions, setTimeFilterOptions] = React.useState([
    { value: 'all', label: 'All' },
    { value: '7days', label: 'Next Week' },
    { value: '14days', label: 'Next 2 weeks' },
    { value: '28days', label: 'Next 4 weeks' },
    { value: 'overdue', label: 'Overdue' },
  ])
  const [tagsList, setTagsList] = React.useState([])
  const [objectiveTagsFilterValue, setObjectiveTagsFilterValue] = React.useState([])
  const [tagsPresetValue, setTagsPresetValue] = React.useState([])

  const canAddEditObjs = getConsolidatedConfigSettings('can_add_edit_objs')
  const [getTasgListUpdated, setGetTagsListUpdated] = React.useState(true)
  const showKanbanLabelMode = getConsolidatedConfigSettings('show_kanban_label_mode') === true

  const [milestoneLabelName, setMilestoneLabelName] = React.useState(null)
  const [userList, setUserList] = React.useState([])

  React.useEffect(() => {
    getAllEmployees()
  }, [])

  const getAllEmployees = async () => {
    const response = await getObjectivUserList(0)
    if (response?.employees)
      setUserList(response.employees.map((emp) => ({ display: emp.name, id: emp.eId })))
  }

  async function getKanbanBoardObjectives(teamLeaderId = null, objIds = null, tagsId = null) {
    setIsObjectiveDataLoading(true) // Set isObjectiveDataLoading to true before fetching data
    try {
      let result
      let localMilestoneLabelId = queryParams.get('milestoneLabelId')
      let localMilestoneLabelName = queryParams.get('milestoneLabelName')
      if (localMilestoneLabelName) {
        setMilestoneLabelName(localMilestoneLabelName)
      } else {
        setMilestoneLabelName(null)
      }

      if (localMilestoneLabelId) {
        result = await getKanbanObjectivesListCustomMode(localMilestoneLabelId, objIds, tagsId)
      } else {
        result = await getKanbanObjectivesList(teamLeaderId, objIds, tagsId)
      }
      if (result) {
        setData(result)
      }
    } catch (error) {
      // Handle errors if needed
      console.error('Error fetching objectives:', error)
    } finally {
      setIsObjectiveDataLoading(false) // Set isObjectiveDataLoading to false after fetching data
      setRefetchActions(!refetchActions) // used to make sure useEffect in KanbanMain is called
    }
  }

  async function getAllObjectivesList() {
    const result = await getAllObjectivesInCompany(3)
    if (result) setObjectivesListFilter(result.objectives)
  }

  useEffect(() => {
    if (areaEmployeesData?.employees) {
      let tempAreaEmployeeData = [...(areaEmployeesData?.employees ?? [])]
      tempAreaEmployeeData.unshift({ name: 'Unassigned', eId: null })
      setMilestonesPeopleList(tempAreaEmployeeData)

      let tempEmployeesWithColors = areaEmployeesData?.employees.map((employee, index) => {
        return { ...employee, color: generateCommentBubblesColor(employee.name) }
      })
      setEmployeesWithColors([...tempEmployeesWithColors])
    }
  }, [areaEmployeesData])

  const [timeframeFilterValue, setTimeframeFilterValue] = React.useState('all')
  const [peopleFilterValue, setPeopleFilterValue] = React.useState([])
  const [objectivePeopleFilterValue, setObjectivePeopleFilterValue] = React.useState([])
  const [objectiveFilterValue, setObjectiveFilterValue] = React.useState([])
  const [selectedFilterTopic, setSelectedFilterTopic] = React.useState([])
  const [filterObj, setFilterObj] = React.useState({
    peopleFilter: [],
    timeframeFilter: 'all',
    meetingTopicFilter: [],
    objectiveFilter: [],
  })
  const [objectivesListFilter, setObjectivesListFilter] = React.useState([])
  const initiativesData = [...new Set(data?.objectives?.map((obj) => obj))]
  const [topicsList, setTopicsList] = useState([])
  const [buttonTypes, setButtonTypes] = useState([])
  const [flag, setFlag] = useState(false)
  const [isAddEditFilterViewModalOpen, setIsAddEditFilterViewModalOpen] = useState(false)
  const [isSelectSavedFilterViewModalOpen, setIsSelectSavedFilterViewModalOpen] = useState(false)
  const [filterViewsList, setFilterViewsList] = useState([])
  const [objectiveActionDict, setObjectiveActionDict] = useState({})
  const [refetchActions, setRefetchActions] = useState(false)
  const [selectPresetValue, setSelectPresetValue] = useState(null)
  const navigate = useNavigate()
  const [isSearchForObjectivesModalOpen, setIsSearchForObjectivesModalOpen] = useState(false)
  const [isObjectiveDataLoading, setIsObjectiveDataLoading] = React.useState(false)
  const [isObjListToLoadChanged, setIsObjListToLoadChanged] = React.useState(false)
  const [isCustomModeEnabled, setIsCustomModeEnabled] = React.useState(false)
  const [isPersonalizedKanbanModalOpen, setIsPersonalizedKanbanModalOpen] = React.useState(false)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [milestoneLabelId, setMilestoneLabelId] = React.useState(null)

  React.useEffect(() => {
    let mli = queryParams.get('milestoneLabelId')
    console.log('mli: ', mli)
    if (mli) {
      setMilestoneLabelId(mli)
    } else {
      setMilestoneLabelId(null)
    }
  }, [])

  // Future: maintain a mapping from obj ID to {columns, column order, num of actions per column }
  // We can use this to sum up the number of actions to show in the unified header.
  const [p13nModeColumnData, setP13nModeColumnData] = React.useState(null)

  useEffect(() => {
    setIsLoading(true)
    getKanbanBoardObjectives()
    setIsLoading(false)
    getMapList(false)
    getAllObjectivesList()
    loadSavedFiltersList()
  }, [])

  useEffect(() => {
    let result = []
    objectiveTagsFilterValue.forEach((v) => {
      tagsList.forEach((tag) => {
        if (tag.name === v.name) {
          result.push(tag)
        }
      })
    })
    setIsObjListToLoadChanged(true)
    setTagsPresetValue(result)
  }, [objectiveTagsFilterValue])

  useEffect(() => {
    if (data?.objectives) {
      let generalObj = []
      let listObjCopy = JSON.parse(JSON.stringify(data?.objectives))

      let result = listObjCopy.concat(generalObj)

      let tempObjectiveActionDict = {}
      for (let i = 0; i < result.length; i++) {
        tempObjectiveActionDict[result[i].id] = 0
      }
      setObjectiveActionDict(tempObjectiveActionDict)

      // setObjectivesListFilter(result)

      //If condition to apply people assigned to objective
      if (filterObj?.objectivePeopleFilter?.length) {
        result = peopleObjectiveFilter(data?.objectives)
        setObjectives(result ? result : [])
      }
      //If condition to apply tags filter
      if (filterObj?.objectiveTagsFilter?.length) {
        result = tagsObjectiveFilter(data?.objectives)
        setObjectives(result ? result : [])
      }

      //If condition to apply objective filter
      let objIds = []
      if (filterObj?.objectiveFilter?.length) {
        for (let r of filterObj?.objectiveFilter) {
          objIds.push(parseInt(r.id))
        }
        result = result.filter((item) => objIds.includes(parseInt(item.id)))
        setObjectives(result ? result : [])
      }

      //If both filters above are unset revert objective back to normal
      // Other filters are processed in the backend
      if (!(filterObj?.objectivePeopleFilter?.length || filterObj?.objectiveFilter?.length)) {
        setObjectives(result ? result : [])
      }

      if (isObjListToLoadChanged) {
        setIsObjListToLoadChanged(false)
      }

      //Used to set collapsing feature so all hidden by default on load
      // if (data?.objectives?.length > 0) {
      //   setIsHiddenObj(
      //     new Array(result.filter((obj) => obj.isViewerOnly === false).length).fill(false)
      //   )
      //   setIsHiddenCoOwnedObj(
      //     new Array(result.filter((obj) => obj.isViewerOnly === true).length).fill(false)
      //   )

      // }
    }
  }, [data?.objectives, filterObj])

  useEffect(() => {
    const objIds = {}
    if (flags.enable_combined_objs === 'true') {
      objectives.map((data, index) => {
        const id = parseInt(data?.id)
        const originalId = data?.originalId
        if (data?.isViewerOnly && originalId === 0) {
          objIds[id] = id
        } else if (originalId != undefined) {
          if (!objIds.hasOwnProperty(originalId)) {
            objIds[originalId] = id
          }
        } else {
          if (!objIds.hasOwnProperty(id)) {
            objIds[id] = id
          }
        }
      })
    }
    getTagsList()
    setObjIdsToShowTitle(objIds)
  }, [objectives])

  useEffect(() => {
    applyFilters()
  }, [selectPresetValue])

  async function loadSavedFiltersList() {
    let result = await getFilterViews()

    if (result && result.filterValues) {
      setFilterViewsList(result.filterValues)
    }
  }

  const peopleObjectiveFilter = (objectives) => {
    if (objectivePeopleFilterValue.length === 0) {
      return objectives
    }

    const filteredObjectives = objectives?.filter((obj) =>
      obj?.objIcMappings?.some((assignee) =>
        objectivePeopleFilterValue?.some((person) => person.eId === assignee.eId),
      ),
    )

    return filteredObjectives
  }

  const tagsObjectiveFilter = (objectives) => {
    if (objectiveTagsFilterValue.length === 0) {
      return objectives
    }

    const filteredObjectives = objectives?.filter((obj) =>
      obj?.tags?.some((tag) =>
        objectiveTagsFilterValue?.some((tagFilter) => tagFilter.id === tag.tagId),
      ),
    )

    return filteredObjectives
  }

  const handleChangeTimeframeFilter = (value) => {
    setTimeframeFilterValue(value)
  }

  const handleChangePeopleFilter = (value) => {
    setPeopleFilterValue(value)
  }

  const handleChangeObjectivePeopleFilter = (value) => {
    setObjectivePeopleFilterValue(value)
  }

  const handleChangeObjectiveFilter = (value) => {
    setObjectiveFilterValue(value)
    setIsObjListToLoadChanged(true)
  }

  const inputChangeMeetingTopicHandler = (value) => {
    setSelectedFilterTopic(value)
  }
  const inputChangeTagsHandler = (value) => {
    setObjectiveTagsFilterValue(value)
    setIsObjListToLoadChanged(true)
  }

  const inputChangePresetDropdownHandler = (value) => {
    setIsCustomModeEnabled(true)
    if (value) {
      //set assigned to actions filter
      let tempPeopleFilterValue = []
      for (let i = 0; i < value.assignedToActions.length; i++) {
        for (let j = 0; j < milestonesPeopleList.length; j++) {
          if (value.assignedToActions[i] === milestonesPeopleList[j].eId)
            tempPeopleFilterValue.push({
              eId: value.assignedToActions[i],
              name: milestonesPeopleList[j].name,
            })
        }
      }
      setPeopleFilterValue(tempPeopleFilterValue)

      //set timeframe filter
      let timeframe = timeFilterOptions.find((item) => item.value === value.timeframe)
      if (timeframe === undefined || timeframe === null) {
        setTimeframeFilterValue('all')
      } else {
        setTimeframeFilterValue(timeframe)
      }

      // set objectives filter
      let tempObjectiveFilterValue = []
      for (let i = 0; i < value.objectives.length; i++) {
        for (let j = 0; j < objectivesListFilter.length; j++) {
          if (value.objectives[i] === parseInt(objectivesListFilter[j].id))
            tempObjectiveFilterValue.push({
              id: value.objectives[i].toString(),
              statement: objectivesListFilter[j].statement,
            })
        }
      }
      setObjectiveFilterValue(tempObjectiveFilterValue)

      //set meeting topics filter
      let tempSelectedFilterTopic = []
      for (let i = 0; i < value.meetingTopics.length; i++) {
        for (let j = 0; j < topicsList.length; j++) {
          if (value.meetingTopics[i] === topicsList[j].id)
            tempSelectedFilterTopic.push({ id: value.meetingTopics[i], name: topicsList[j].name })
        }
      }
      setSelectedFilterTopic(tempSelectedFilterTopic)

      //set tags filter
      let tempObjectiveTagsFilterValue = []
      for (let i = 0; i < value.tags.length; i++) {
        for (let j = 0; j < tagsList.length; j++) {
          if (value.tags[i] === tagsList[j].id) {
            const tagName = tagsList[j].name
            const alreadyExists = tempObjectiveTagsFilterValue.some((tag) => tag.name === tagName)
            if (!alreadyExists) {
              tempObjectiveTagsFilterValue.push({ id: value.tags[i], name: tagName })
            }
          }
        }
      }
      setObjectiveTagsFilterValue(tempObjectiveTagsFilterValue)
      setGetTagsListUpdated(false)
      setSelectPresetValue(value)
    }
  }

  const [isHiddenObj, setIsHiddenObj] = React.useState(
    new Array(objectives.filter((obj) => obj.isViewerOnly === false).length).fill(false),
  )
  const [isHiddenCoOwnedObj, setIsHiddenCoOwnedObj] = React.useState(
    new Array(objectives.filter((obj) => obj.isViewerOnly === true).length).fill(false),
  )

  const [isFiltersSectionHidden, setIsFiltersSectionHidden] = React.useState(true)

  const collapseAll = () => {
    setIsHiddenObj(
      new Array(objectives.filter((obj) => obj.isViewerOnly === false).length).fill(true),
    )
    setIsHiddenCoOwnedObj(
      new Array(objectives.filter((obj) => obj.isViewerOnly === true).length).fill(true),
    )
  }

  const uncollapseAll = () => {
    setIsHiddenObj(
      new Array(objectives.filter((obj) => obj.isViewerOnly === false).length).fill(false),
    )
    setIsHiddenCoOwnedObj(
      new Array(objectives.filter((obj) => obj.isViewerOnly === true).length).fill(false),
    )
  }

  const shouldCollapse =
    isHiddenObj == undefined ||
    isHiddenCoOwnedObj == undefined ||
    isHiddenObj.length === 0 ||
    isHiddenObj.some((item) => item === false) ||
    isHiddenCoOwnedObj.some((item) => item === false)

  async function applyFilters() {
    let tempFilterObj = {
      ...filterObj,
      peopleFilter: peopleFilterValue, // use to filter milestones only
      timeframeFilter: timeframeFilterValue?.value
        ? timeframeFilterValue?.value
        : timeframeFilterValue
          ? timeframeFilterValue
          : 'all',
      objectivePeopleFilter: objectivePeopleFilterValue,
      objectiveFilter:
        objectiveFilterValue.length > 0
          ? objectiveFilterValue.map((v) => ({
              id: parseInt(v.id),
              statement: v.statement,
            }))
          : [],
      meetingTopicFilter: selectedFilterTopic,
      objectiveTagsFilter: objectiveTagsFilterValue,
    }
    setSelectPresetValue(null)
    if (
      objectiveFilterValue.length === 0 &&
      objectiveTagsFilterValue.length === 0 &&
      selectedFilterTopic.length === 0 &&
      peopleFilterValue.length === 0 &&
      timeframeFilterValue === 'all'
    ) {
      setIsCustomModeEnabled(false)
      getKanbanBoardObjectives()
    }
    if (
      (objectiveFilterValue.length > 0 || objectiveTagsFilterValue.length > 0) &&
      isObjListToLoadChanged
    ) {
      let objIds = objectiveFilterValue.map((v) => parseInt(v.id))
      let tagsId = new Set()
      objectiveTagsFilterValue.forEach((v) => {
        tagsId.add(parseInt(v.id))
        tagsList.forEach((tag) => {
          if (tag.name === v.name) {
            tagsId.add(tag.id)
          }
        })
      })
      tagsId = Array.from(tagsId)
      getKanbanBoardObjectives(null, objIds, tagsId)
      setFilterObj(tempFilterObj)
      setIsCustomModeEnabled(true)
    } else {
      setFilterObj(tempFilterObj)
      setRefetchActions(!refetchActions) // used to make sure useEffect in KanbanMain is called
    }
  }

  const clearFilters = () => {
    setTimeframeFilterValue('all')
    setPeopleFilterValue([])
    setObjectivePeopleFilterValue([])
    setObjectiveFilterValue([])
    setSelectedFilterTopic([])

    setFilterObj({
      ...filterObj,
      peopleFilter: [],
      timeframeFilter: 'all',
      meetingTopicFilter: [],
      objectiveFilter: [],
    })

    // setRefetchActions(!refetchActions) // used to make sure useEffect in KanbanMain is called
    setSelectPresetValue(null)
    setIsCustomModeEnabled(false)
    getKanbanBoardObjectives()
    // setRefetchActions(!refetchActions)
  }

  async function filterFlag() {
    // if user clicks on same button twice, remove it from the array (so not underline)
    if (buttonTypes.includes('Flagged')) {
      setButtonTypes((prev) => prev.filter((item) => item !== 'Flagged'))
    } else {
      setButtonTypes((prev) => [...prev, 'Flagged'])
    }
    setFlag(!flag)
  }

  const getTagsList = () => {
    //set tags list
    const tagListExtracted = (data?.objectives || []).map((item) => item.tags).flat()
    const processedTags = []
    const seenNames = new Set()
    ;(tagListExtracted || []).forEach((item) => {
      const name = item.tagName
      const id = item.tagId

      if (!seenNames.has(name)) {
        seenNames.add(name)
        processedTags.push({ name, id })
      }
    })
    processedTags.sort((a, b) => a.name.localeCompare(b.name))
    if (getTasgListUpdated && processedTags.length > 0) {
      setTagsList(processedTags)
    }
  }

  async function getMapList(resolved) {
    const result = await getMeetingTopic(resolved, false)
    if (result) {
      let finalArray = []

      finalArray = finalArray.concat(result.blockedObjectives)
      finalArray = finalArray.concat(result.decisionObjectives)
      finalArray = finalArray.concat(result.updateObjectives)
      finalArray = finalArray.concat(result.adminTopicObjectives)

      const topicsList = (finalArray || []).map((item) => ({
        name: item.actionNeeded,
        id: item.umtId,
      }))
      topicsList.sort((a, b) => a.name.localeCompare(b.name))
      setTopicsList(topicsList)
    }
  }
  /** Function is used to hide or display kanbanMain which renders objective by objective from objectives
   * Logic of true and false if used to bridge for the fact that the filtering of the objectives is done in teh front end,
   * while the rest of the filters are done in the backend
   * First, we check what filters are set and which ones are not
   * Then depending on the case we further check on the objective actions count vs the activities count returned from the backend
   * with the filtering applied to resolve wether we should show the objective or not
   * @param {object} objective - objective object
   */
  const shouldShowObjective = (objective) => {
    if (
      //objective filter is set and at least one other filter is set
      filterObj?.objectiveFilter?.length > 0 &&
      (filterObj?.timeframeFilter !== 'all' ||
        filterObj?.peopleFilter?.length > 0 ||
        filterObj?.meetingTopicFilter?.length > 0)
    ) {
      if (objectiveActionDict[objective.id] === 0 && objective.activitiesCount > 0) {
        return false
      } else if (objectiveActionDict[objective.id] === 0 && objective.activitiesCount === 0) {
        return false
      } else {
        return true
      }
    } else if (
      //only objective filter is set
      filterObj?.objectiveFilter?.length > 0 &&
      filterObj?.timeframeFilter === 'all' &&
      filterObj?.peopleFilter?.length === 0 &&
      filterObj?.meetingTopicFilter?.length === 0
    ) {
      return true
    } else if (
      //objective filter is not set, but at least one other filter is set
      filterObj?.objectiveFilter?.length === 0 &&
      (filterObj?.timeframeFilter !== 'all' ||
        filterObj?.peopleFilter?.length > 0 ||
        filterObj?.meetingTopicFilter?.length > 0)
    ) {
      if (objectiveActionDict[objective.id] > 0 && objective.activitiesCount > 0) {
        return true
      }
      return false
    } else {
      return true
    }
  }

  return (
    <ApplicationLayout>
      {isGanttChartModalOpen === true && (
        <GanttChartModal
          isModalOpen={isGanttChartModalOpen}
          closeModal={() => {
            setIsGanttChartModalOpen(false)
          }}
        />
      )}

      {isAddEditFilterViewModalOpen === true && (
        <AddEditFilterViewModal
          isModalOpen={isAddEditFilterViewModalOpen}
          handleClose={() => {
            setIsAddEditFilterViewModalOpen(false)
          }}
          timeframeFilterValue={timeframeFilterValue}
          peopleFilterValue={peopleFilterValue}
          objectiveFilterValue={objectiveFilterValue}
          selectedFilterTopic={selectedFilterTopic}
          tagsFilterValue={tagsPresetValue}
          reloadBackground={() => {
            loadSavedFiltersList()
          }}
        />
      )}

      {isSelectSavedFilterViewModalOpen === true && (
        <SelectSavedFilterViewModal
          isModalOpen={isSelectSavedFilterViewModalOpen}
          handleClose={() => {
            setIsSelectSavedFilterViewModalOpen(false)
          }}
          reloadBackground={() => {
            loadSavedFiltersList()
          }}
        />
      )}

      {isSearchForObjectivesModalOpen === true && (
        <SearchForObjectivesModal
          isModalOpen={isSearchForObjectivesModalOpen}
          closeModal={() => {
            setIsSearchForObjectivesModalOpen(false)
            setIsObjListToLoadChanged(true)
          }}
          setObjectiveFilterValue={setObjectiveFilterValue}
          objectivesListFilter={objectivesListFilter}
        />
      )}

      {isPersonalizedKanbanModalOpen === true && (
        <PersonalizedKanbanModal
          closeModal={() => {
            setIsPersonalizedKanbanModalOpen(false)
          }}
          milestoneLabelId={milestoneLabelId}
        />
      )}

      <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '3rem' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0.5rem 1.5rem 0.5rem 1rem',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <b style={{ justifySelf: 'flex-start', alignSelf: 'flex-start', fontSize: '20px' }}>
              Kanban
            </b>
            {showKanbanLabelMode && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1rem',
                  paddingBottom: '0.5rem',
                }}
              >
                <div
                  className="option-button"
                  style={{ backgroundColor: milestoneLabelId != null ? '#a6a6a6' : '#4472c4' }}
                  onClick={() => {
                    window.location.href = `/Kanban`
                  }}
                >
                  {t('Kanban:myKanban')}
                </div>
                <div
                  className="option-button"
                  style={{ backgroundColor: milestoneLabelId === null ? '#a6a6a6' : '#4472c4' }}
                  onClick={() => {
                    setIsPersonalizedKanbanModalOpen(true)
                  }}
                >
                  {t('Kanban:personalizedKanban')}
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
              justifyContent: 'flex-end',
            }}
          >
            {hasRole('team') && (
              <div
                className="sleek-button sleek-blue"
                onClick={async () => {
                  setIsGanttChartModalOpen(true)
                }}
                title="Gantt Chart View"
              >
                <span class="material-symbols-outlined icon-size-l">view_timeline</span>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',

                columnGap: '2rem',

                alignSelf: 'center',
              }}
            >
              {!shouldCollapse && (
                <div
                  className="sleek-button sleek-blue"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    uncollapseAll()
                  }}
                >
                  {t('Common:commonButtons.expandAll')}
                </div>
              )}

              {shouldCollapse && (
                <div
                  className="sleek-button sleek-blue"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    collapseAll()
                  }}
                >
                  {t('Common:commonButtons.collapseAll')}
                </div>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',

                columnGap: '2rem',

                alignSelf: 'center',
              }}
            >
              {buttonTypes.includes('Flagged') && (
                <div
                  className="sleek-button sleek-blue"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    filterFlag()
                  }}
                >
                  {t('Common:filter.showAll')}
                </div>
              )}

              {!buttonTypes.includes('Flagged') && (
                <div
                  className="sleek-button sleek-blue"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    filterFlag()
                  }}
                >
                  {t('Common:filter.showFlagged')}
                </div>
              )}
            </div>

            <Select
              placeholder={t('Common:filter.presets')}
              name="selectTopic"
              isMulti={false}
              value={selectPresetValue?.ruleName}
              onChange={inputChangePresetDropdownHandler}
              getOptionLabel={(option) => option.ruleName}
              getOptionValue={(option) => option.ruleId}
              options={filterViewsList}
              menuPosition="fixed"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: '14px',
                  width: '9rem',
                  borderRadius: '10px',
                  color: '#4472c4',
                  border: '1px solid #4472c4',
                  height: '2.9rem',
                  boxShadow: state.isFocused ? '0 0 0 1px #00f' : null,
                  '&:hover': {
                    borderColor: '#4472c4',
                  },
                }),

                placeholder: (provided, state) => ({
                  ...provided,
                  fontSize: '14px',
                  color: '#4472c4',
                  textAlign: 'center',
                }),

                option: (provided, state) => ({
                  ...provided,
                  fontSize: '14px',
                  color: state.isSelected ? 'white' : '#4472c4',
                }),

                singleValue: (provided) => ({
                  ...provided,
                  fontSize: '14px',
                  color: '#4472c4',
                }),
              }}
            />

            <div
              className="sleek-button sleek-blue"
              onClick={() => {
                setIsFiltersSectionHidden(!isFiltersSectionHidden)
              }}
            >
              <span class="material-symbols-outlined icon-size-l">filter_alt</span>
              <span>{t('Common:filter.filters')}</span>
            </div>

            <div
              className="sleek-button sleek-blue"
              onClick={() => {
                clearFilters()
              }}
            >
              <span>{t('Common:commonButtons.clearFilters')}</span>
            </div>

            {((hasRole('team') && canAddEditObjs) || hasRole('area')) && (
              <div
                className="sleek-button sleek-full-blue"
                onClick={() => {
                  navigate(`/${routes.demandintake}`, {
                    state: { activityBoardCreateNewObj: true },
                  })
                }}
              >
                <span class="material-symbols-outlined icon-size-l">add_circle</span>
                <span> {t('Common:commonButtons.objective')}</span>
              </div>
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div
          className={`transition-div ${isFiltersSectionHidden === false ? 'visible' : ''}`}
          style={{
            top: '0',
            backgroundColor: 'white',
            paddingBottom: '1rem',
            marginTop: '1rem',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <span style={{ paddingLeft: '1rem' }}>{t('Common:filter.setFilters')}</span>

            <div
              className="sleek-button sleek-blue"
              onClick={() => {
                clearFilters()
              }}
              style={{ border: 'none', paddingRight: '2rem', height: '1rem' }}
            >
              <span>{t('Common:commonButtons.clearAll')} </span>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '2rem',
              marginLeft: '1rem',
            }}
          >
            <div
              className="option-button"
              style={{ backgroundColor: !isCustomModeEnabled ? '#4472c4' : '#a6a6a6' }}
              onClick={() => {
                setIsCustomModeEnabled(false)
                getKanbanBoardObjectives()
                clearFilters()
              }}
            >
              <span>{t('Kanban:showMyObjectives')}</span>
            </div>
            <div
              className="option-button"
              style={{ backgroundColor: isCustomModeEnabled ? '#4472c4' : '#a6a6a6' }}
              onClick={() => {
                setIsCustomModeEnabled(true)
              }}
            >
              <span>{t('Kanban:custom')}</span>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              maxWidth: '100rem',
              paddingLeft: '1rem',
              gap: '2rem',
              marginTop: '0.5rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '0.5rem',
              }}
            >
              <label for="teamLeadersFilter">
                <span style={{ fontSize: '14px' }}>{t('Common:filter.objectiveFilter')}:</span>
              </label>
              <div style={{ width: '38rem' }}>
                <Select
                  placeholder={t('Common:filter.select') + '...'}
                  value={objectiveFilterValue}
                  isMulti
                  onChange={handleChangeObjectiveFilter}
                  getOptionLabel={(option) =>
                    //might have to bring this in *
                    // companyConfig
                    //   ? `${(option?.initiativeId).toString().padStart(5, '0')} - ${option.statement}`
                    //   :
                    `${option.statement}`
                  }
                  getOptionValue={(option) => parseInt(option?.id)}
                  options={objectivesListFilter}
                  menuPosition="fixed"
                  styles={{
                    valueContainer: (provided, state) => ({
                      ...provided,
                      height: '4rem',
                      overflow: 'auto',
                    }),
                  }}
                />
              </div>
            </div>
            <div
              style={{
                color: '#0926D5',
                textDecoration: 'underline',
                marginTop: '3rem',
              }}
            >
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsSearchForObjectivesModalOpen(true)
                }}
              >
                {t('Kanban:searchForObjectivesToDisplay')}
              </span>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '2rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                maxWidth: '100rem',
                paddingLeft: '1rem',
                marginTop: '0.5rem',
                gap: '2rem',
              }}
            >
              {/* Commented out for now as not that many users eevr use this filter */}
              {/* <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '2rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              maxWidth: '100rem',
              paddingLeft: '1rem',
            }}
          >
            {/* Commented out for now as not that many users eevr use this filter */}
              {/* <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
              <label for="teamLeadersFilter">
                <b>{t('Common:filter.assignedToObjectives')}:</b>
              </label>
              <div data-testid="options-assigned_to_objs" style={{ maxWidth: '16vw' }}>
                <Select
                  value={objectivePeopleFilterValue}
                  isMulti
                  onChange={handleChangeObjectivePeopleFilter}
                  maxMenuHeight={100}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.eId}
                  options={areaEmployeesData?.employees}
                />
              </div>
            </div> */}
              <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                <label for="teamLeadersFilter">
                  <span style={{ fontSize: '14px' }}>
                    {t('Common:filter.assignedToActivities')}:
                  </span>
                </label>
                <div data-testid="multi-options-1" style={{ width: '18rem' }}>
                  <Select
                    placeholder={t('Common:filter.select') + '...'}
                    value={peopleFilterValue}
                    isMulti
                    onChange={handleChangePeopleFilter}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.eId}
                    options={milestonesPeopleList}
                    menuPosition="fixed"
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '0.5rem',
                  width: '18rem',
                }}
              >
                <label for="timeframeFilter">
                  <span style={{ fontSize: '14px' }}>{t('Common:filter.actionDueDate')}:</span>
                </label>
                <Select
                  placeholder={t('Common:filter.select') + '...'}
                  className="basic-single"
                  value={timeframeFilterValue}
                  onChange={handleChangeTimeframeFilter}
                  getOptionLabel={(option) => option?.label}
                  getOptionValue={(option) => option?.value}
                  options={timeFilterOptions}
                  menuPosition="fixed"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '0.5rem',
                  width: '18rem',
                }}
              >
                <label for="teamLeadersFilter">
                  <span style={{ fontSize: '14px' }}>{t('Common:filter.meetingTopics')}:</span>
                </label>
                <div>
                  <Select
                    placeholder={t('Common:filter.select') + '...'}
                    name="selectTopic"
                    value={selectedFilterTopic}
                    isMulti
                    onChange={inputChangeMeetingTopicHandler}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={topicsList}
                    menuPosition="fixed"
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '0.5rem',
                  width: '18rem',
                }}
              >
                <label for="teamLeadersFilter">
                  <span style={{ fontSize: '14px' }}>{t('Common:filter.objectiveTags')}:</span>
                </label>
                <div>
                  <Select
                    placeholder={t('Common:filter.select') + '...'}
                    name="selectTags"
                    value={objectiveTagsFilterValue}
                    isMulti
                    onChange={inputChangeTagsHandler}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={tagsList}
                    menuPosition="fixed"
                  />
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}></div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: '1rem',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
              <div
                className="sleek-button sleek-blue"
                onClick={() => {
                  setIsAddEditFilterViewModalOpen(true)
                }}
                style={{ border: 'none', paddingRight: '2rem' }}
              >
                <span>{t('Common:commonButtons.saveAsPreset')}</span>
              </div>

              <div
                className="sleek-button sleek-blue"
                onClick={() => {
                  setIsSelectSavedFilterViewModalOpen(true)
                }}
                style={{ border: 'none', paddingRight: '2rem' }}
              >
                <span>{t('Common:commonButtons.editPresets')} </span>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
                paddingRight: '1.5rem',
              }}
            >
              <div
                className="sleek-button sleek-blue"
                onClick={() => {
                  setIsFiltersSectionHidden(true)
                  clearFilters()
                }}
              >
                <span>{t('Common:commonButtons.cancelLowercase')}</span>
              </div>

              <div
                className="sleek-button sleek-full-blue"
                onClick={() => {
                  applyFilters()
                  setGetTagsListUpdated(false)
                }}
              >
                <span>{t('Common:commonButtons.apply')}</span>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',

              columnGap: '2rem',

              marginTop: '1rem',
              marginLeft: '32px',
            }}
          ></div>
          <div className="divider" style={{ marginTop: '1rem' }}></div>
        </div>
        <ContainerObjective>
          {/* <IsolatedColumnHeader p13nModeColumnData={p13nModeColumnData} /> */}

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* owned by user obj */}
            {objectives
              .filter((obj) => obj.isViewerOnly === false)
              .map((obj, index) => {
                return (
                  <div style={{ display: shouldShowObjective(obj) ? 'block' : 'none' }}>
                    {
                      <KanbanMain
                        key={obj.id}
                        objective={obj}
                        filterObj={filterObj}
                        employeesWithColors={employeesWithColors}
                        objIdsToShowTitle={objIdsToShowTitle}
                        isHidden={isHiddenObj}
                        setIsHidden={setIsHiddenObj}
                        index={index}
                        buttonTypes={buttonTypes}
                        objectiveActionDict={objectiveActionDict}
                        setObjectiveActionDict={setObjectiveActionDict}
                        refetchActions={refetchActions}
                        isShared={false}
                        data={data}
                        setData={setData}
                        isObjectiveDataLoading={isObjectiveDataLoading}
                        setGetTagsListUpdated={setGetTagsListUpdated}
                        hideGanttChartButton={hideGanttChartButton}
                        setP13nModeColumnData={index === 0 ? setP13nModeColumnData : () => {}}
                        milestoneLabelId={milestoneLabelId}
                        milestoneLabelName={milestoneLabelName}
                        userList={userList}
                      />
                    }
                  </div>
                )
              })}
            {/* Hide in LABEL MODE */}
            {/* co-owned by user obj */}
            {objectives.filter((obj) => obj.isViewerOnly === true).length > 0 && (
              <>
                {objectives
                  .filter((obj) => obj.isViewerOnly === true)
                  .map((obj, index) => {
                    return (
                      <div style={{ display: shouldShowObjective(obj) ? 'block' : 'none' }}>
                        <KanbanMain
                          key={obj.id}
                          objective={obj}
                          filterObj={filterObj}
                          employeesWithColors={employeesWithColors}
                          objIdsToShowTitle={objIdsToShowTitle}
                          isHidden={isHiddenCoOwnedObj}
                          setIsHidden={setIsHiddenCoOwnedObj}
                          index={index}
                          buttonTypes={buttonTypes}
                          objectiveActionDict={objectiveActionDict}
                          setObjectiveActionDict={setObjectiveActionDict}
                          refetchActions={refetchActions}
                          isShared={true}
                          data={data}
                          setData={setData}
                          isObjectiveDataLoading={isObjectiveDataLoading}
                          hideGanttChartButton={hideGanttChartButton}
                          milestoneLabelId={milestoneLabelId}
                          milestoneLabelName={milestoneLabelName}
                          userList={userList}
                        />
                      </div>
                    )
                  })}
              </>
            )}
            {!isLoading && objectives.length === 0 && data?.objectives?.length === 0 ? (
              <p>
                <i>{t('Kanban:noObjectives')}</i>
              </p>
            ) : null}
          </div>
        </ContainerObjective>
      </div>
    </ApplicationLayout>
  )
}

export default KanbanView

import React, { useState, useEffect } from 'react'
import InPageLoader from '../../../../components/InPageLoader'
import styled from 'styled-components'
import './styles.css'
import useUpdateShowInLog from '../../../../api/query/useUpdateShowInLog'
import Select from 'react-select'
import { dateToString } from '../../../../utils/time'
import { getHttpRequest, postHttpRequest } from '../../../../api/query/dynamicAPI'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import { getCompanyConfigSettings, hasRole } from '../../../../utils/auth'
import EditCommentActivityModal from '../../../../components/EditCommentActivityModal'
import Toast from '../../../../common/toast'
import { useTranslation } from 'react-i18next'
import { linkifyString, shortenName } from '../../../../utils/general'
import SelectSingleField from '../../../../components/CustomMultipleSelect/singleOption.jsx'
import { getAllEmployeeList, getObjectivUserList } from '../../../../api/services/employee.services'
import { Link } from 'react-router-dom'
import { removeMentionMarkupFromText } from '../../../../utils/parseMarkup'
import AssigneeActivity from '../MeetingTopics/common/assigneeActivity'
import { MentionsInput, Mention } from 'react-mentions'
import useUpdateDecisionLogOutcome from '../../../../api/mutation/useUpdateDecisionLogOutcome'

const ObjectiveStatement = styled.div`
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 1.5rem;
`
const Wrapper = styled.div`
  div > textarea {
    border: 1px solid lightgrey;
    rows: 3;
  }
  width: 100%;
  font-family: 'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif;

  .body_bottom_text__control {
    height: 3rem !important;
    min-height: 3rem !important;
    max-height: 3rem !important;
    overflow-y: auto;
  }
`
const DecisionLog = (props) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])

  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')

  const mutationUpdateShowInLog = useUpdateShowInLog()
  const mutationUpdateItemText = useUpdateDecisionLogOutcome()

  const [decisionLogList, setDecisionLogList] = React.useState(null)
  const [isLoadingDecisionLogList, setIsLoadingDecisionLogList] = useState(false)
  const [selectedLog, setSelectedLog] = React.useState(null)
  const [decisionLogData, setDecisionLogData] = React.useState([])
  const [isLoadingDecisionLogData, setIsloadingDecisionLogData] = useState(false)
  const [isLogEditable, setIsLogEditable] = useState(false)
  const [isEditCommentActivityModalOpen, setIsEditCommentActivityModalOpen] = useState(false)
  const [textInput, setTextInput] = useState('')
  const [commentIdToEdit, setCommentIdToEdit] = useState(0)
  const [activityIdToEdit, setActivityIdToEdit] = useState(0)
  const [checkboxComments, setCheckboxComments] = useState({})
  const [checkboxMiles, setCheckboxMiles] = useState({})
  const [showLoader, setShowLoader] = useState(false)
  const [teamLeadersData, setTeamLeadersData] = React.useState(null)
  const [additionalLogViewersList, setAdditionalLogViewersList] = React.useState(null)
  const [tlId, setTlId] = React.useState(null)
  const [logViewersList, setLogViewersList] = React.useState([])
  const [peopleList, setPeopleList] = React.useState([])
  const [userList, setUserList] = React.useState([])
  const [logName, setLogName] = React.useState('')
  const [logNameErrorMessage, setLogNameErrorMessage] = React.useState(null)
  const loggedInName = localStorage.getItem('tfmnm')
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false)
  const [mentionState, setMentionState] = React.useState([])

  // these are for assignee activity modal
  const [selectedMilestoneId, setSelectedMilestoneId] = useState(null)
  const [selectedAssigneeList, setSelectedAssigneeList] = useState([])

  const isTeamMeetingView = hasRole('ic') || (hasRole('team') && !isDelegateTl)

  React.useEffect(() => {
    getAdditionalLogViewersList()
    if (hasRole('cxo')) {
      getHttpRequest(`/get_decision_log_emp_list`, {}).then((response) => {
        setTeamLeadersData(response)
        let leader = response.employees.find((emp) => emp.name === localStorage.getItem('tfmnm'))
        if (!leader && response.employees.length > 0) {
          leader = response.employees[0]
        }
        setTlId(parseInt(leader.eId))
        getDecisionLogListData(leader.eId)
      })
    }
    getAllEmployees()
  }, [])

  const updateItemText = React.useCallback((id, text) => {
    setDecisionLogData((prevData) => {
      const updateComments = (comments) => {
        return comments.map((comment) => {
          if (comment.commentId === id) {
            return { ...comment, commentText: text }
          }
          return comment
        })
      }

      const updateMilestones = (milestones) => {
        return milestones.map((milestone) => {
          if (milestone.milestoneId === id) {
            return { ...milestone, milestoneDescription: text }
          }
          return milestone
        })
      }

      const updateTopics = (topics) => {
        return topics.map((topic) => ({
          ...topic,
          comments: topic.comments ? updateComments(topic.comments) : undefined,
          milestones: topic.milestones ? updateMilestones(topic.milestones) : undefined,
        }))
      }

      return {
        ...prevData,
        objectives: prevData.objectives.map((objective) => ({
          ...objective,
          blockers: objective.blockers ? updateTopics(objective.blockers) : undefined,
          decisions: objective.decisions ? updateTopics(objective.decisions) : undefined,
          updates: objective.updates ? updateTopics(objective.updates) : undefined,
          topics: objective.topics ? updateTopics(objective.topics) : undefined,
        })),
      }
    })
  }, [])

  const getAllEmployees = async () => {
    const result = await getObjectivUserList(0)
    if (result?.employees)
      setUserList(result.employees.map((emp) => ({ display: emp.name, id: emp.eId })))
  }

  React.useState(() => {
    if (hasRole('team') || hasRole('area') || hasRole('ceo') || hasRole('cos')) {
      getDecisionLogListData()
    } else if (tlId != undefined && tlId != null) {
      getDecisionLogListData(tlId)
    }
  }, tlId)

  React.useEffect(() => {
    if (decisionLogList?.decisionLogs && decisionLogList?.decisionLogs.length > 0) {
      setSelectedLog(decisionLogList?.decisionLogs[0])
      getDecisionLogData(
        decisionLogList?.decisionLogs[0]?.logId,
        decisionLogList.decisionLogs[0].isFinalized,
      )
      setIsLogEditable(!decisionLogList.decisionLogs[0].isFinalized)
    } else {
      setSelectedLog(null)
      setDecisionLogData(null)
    }
  }, [decisionLogList])

  useEffect(() => {
    let objs = decisionLogData?.objectives
    if (objs !== undefined) {
      for (let obj of objs) {
        const { topics } = obj
        topics?.map((topic) => updateCheckBoxes(topic))
      }
    }
  }, [decisionLogData])

  // useEffect(() => {
  //   setShowLoader(true)
  // }, [selectedLog])

  const updateCheckBoxes = (logType) => {
    if (logType.comments && logType.comments.length > 0) {
      for (let comment of logType.comments) {
        setCheckboxComments((prev) => ({
          ...prev,
          [comment.commentId]: comment.showInLog,
        }))
      }
    }
    if (logType.milestones && logType.milestones.length > 0) {
      for (let milestone of logType.milestones) {
        setCheckboxMiles((prev) => ({
          ...prev,
          [milestone.milestoneId]: milestone.showInLog,
        }))
      }
    }
  }

  const handleSendDecisionLog = async () => {
    let check = await checkIfLogNameExists()
    let result = null
    if (check === false) {
      result = await Swal.fire({
        title: t('Common:modalMessages.areYouSureYouWantToSendThisLog'),
        text: t('Common:modalMessages.areYouSureYouWantToSendThisLogMessage'),
        showCancelButton: true,
        confirmButtonText: t('Common:commonButtons.yes'),
        cancelButtonText: t('Common:commonButtons.no'),
      })
    } else if (check === true) {
      return
    }

    if (result?.isConfirmed) {
      sendLog()
    }
  }

  async function checkIfLogNameExists() {
    if (logName === '' || logName === null || logName === undefined) {
      setLogNameErrorMessage(t('decisionLog.logNameIsRequired'))
      return true
    }

    let currentDate = new Date()
    // Format current date to MM/DD/YYYY
    let formattedCurrentDate = `${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${currentDate
      .getDate()
      .toString()
      .padStart(2, '0')}/${currentDate.getFullYear()}`

    const postObj = {
      logName: `${formattedCurrentDate} - ${logName}`,
    }

    const headers = {
      'Content-Type': 'application/json', // Set Content-Type header to application/json
    }

    try {
      const response = await postHttpRequest('/check_decision_log_name_exists', postObj, headers)
      if (response && response.success) {
        if (response.queryResult === true) {
          setLogNameErrorMessage(t('decisionLog.logNameExists'))
          return true
        } else {
          setLogNameErrorMessage(null)
          return false
        }
      } else {
        // Handle case where log name doesn't exist
      }
    } catch (error) {
      setShowLoader(false) // Handle error appropriately
    }
  }

  async function sendLog() {
    setShowLoader(true)
    const postObj = {}

    let currentDate = new Date()
    // Format current date to MM/DD/YYYY
    let formattedCurrentDate = `${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${currentDate
      .getDate()
      .toString()
      .padStart(2, '0')}/${currentDate.getFullYear()}`

    postObj.logName = `${formattedCurrentDate} - ${logName}`
    postObj.logId = selectedLog.logId
    postObj.additionalLogViewers = logViewersList

    postHttpRequest('/send_decision_log', postObj)
      .then((response) => {
        if (response) {
          getDecisionLogListData()
          setLogName('')
          handleClearUnfinalizedLog(true)

          return
        } else {
        }
      })
      .catch((error) => {
        setShowLoader(false)
      })
  }

  const handleClearUnfinalizedLog = async (afterSendLog = false) => {
    const result = await Swal.fire({
      title: afterSendLog
        ? t('Common:modalMessages.doYouWantToSendAnotherLogFromThisList')
        : t('Common:modalMessages.areYouSureYouWantToClearThisLog'),
      text: afterSendLog
        ? t('Common:modalMessages.doYouWantToSendAnotherLogFromThisListMessage')
        : t('Common:modalMessages.areYouSureYouWantToClearThisLogMessage'),
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if ((result.isConfirmed && !afterSendLog) || (!result.isConfirmed && afterSendLog)) {
      clearUnfinalizedLog(afterSendLog)
    }

    if (result.isConfirmed && afterSendLog) {
      //Not clear after send log, keep on main
      handleChangeSelectedLog(selectedLog)
    }
  }

  async function clearUnfinalizedLog(afterSendLog) {
    const postObj = {}
    postObj.logId = selectedLog.logId
    postHttpRequest('/finalize_decision_log', postObj)
      .then((response) => {
        if (response) {
          getDecisionLogListData(tlId)
          setTimeout(() => {
            if (afterSendLog) {
              //if clear done after send log, load most recent child
              for (let log of decisionLogList?.decisionLogs) {
                if (!log.isMain) {
                  handleChangeSelectedLog(log)
                  return
                }
              }
            } else if (!afterSendLog && loggedInName === selectedLog.areaLeaderName) {
              // if clear done independently through its own button, load most recent main
              for (let log of decisionLogList?.decisionLogs) {
                if (log.isMain) {
                  handleChangeSelectedLog(log)
                  return
                }
              }
            }
          }, 3000)

          return
        }
      })
      .catch((error) => {})
  }

  const handleChangeSelectedLog = (value) => {
    setSelectedLog(value)
    getDecisionLogData(value.logId, value.isFinalized)
    setIsLogEditable(!value.isFinalized)
  }

  function getDecisionLogData(logId, isFinalized) {
    // setIsloadingDecisionLogData(true)
    setShowLoader(true)
    if (logId != undefined) {
      getHttpRequest(`/get_decision_log/${logId}?finalized=${isFinalized}`)
        .then((response) => {
          if (response) {
            setDecisionLogData(response)
          }
          if (!response) {
            return
          }
          // setIsloadingDecisionLogData(false)
          setShowLoader(false)
        })
        .catch((error) => {
          Toast.fire({
            icon: 'error',
            title: t('modalMessages.somethingWentWrongTryAgainLater'),
          })
          // setIsloadingDecisionLogData(false)
          setShowLoader(false)
        })
    }
  }

  function getDecisionLogListData(teamLeaderId = null) {
    // setIsLoadingDecisionLogList(true)
    setShowLoader(true)
    let url =
      teamLeaderId != null ? `/get_decision_logs_list/${teamLeaderId}` : `/get_decision_logs_list`
    getHttpRequest(url)
      .then((response) => {
        setDecisionLogList(response)

        // setIsLoadingDecisionLogList(false)
        setShowLoader(false)
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
        // setIsLoadingDecisionLogList(false)
        setShowLoader(false)
      })
  }

  const handleChangeLogName = (e) => {
    setLogName(e.target.value)
  }

  const handleEdit = (id, text, type) => {
    type === 'comment' ? setCommentIdToEdit(id) : setActivityIdToEdit(id)
    setTextInput(text)
    setIsEditCommentActivityModalOpen(true)
  }

  async function getAdditionalLogViewersList() {
    // List includes IC's
    const result = await getAllEmployeeList()
    getReportingLeaders()
    if (result?.employees) setAdditionalLogViewersList(result.employees)
  }

  async function getReportingLeaders() {
    const result = await getHttpRequest('/team_leaders_for_area_leader', {
      params: {
        notExcludeCos: true,
      },
    })
    let employees =
      result?.employees?.filter((emp) => emp.isDeactivated === false && emp.isDeleted === false) ??
      []
    setPeopleList(employees)
    setLogViewersList(employees.map((emp) => emp.eId))
  }

  const handleCloseEditCommentActivityModal = () => {
    setIsEditCommentActivityModalOpen(false)
    setCommentIdToEdit(0)
    setActivityIdToEdit(0)
    setTextInput('')
    getDecisionLogData(
      decisionLogList?.decisionLogs[0].logId,
      decisionLogList.decisionLogs[0].isFinalized,
    )
    setShowLoader(false)
  }

  if (showLoader) {
    return <InPageLoader />
  }

  const handleCheck = async (e, isComment) => {
    setShowLoader(false)
    if (isComment) {
      await mutationUpdateShowInLog.mutateAsync({
        included: !checkboxComments[e.target.id],
        commentId: e.target.id,
      })
      setCheckboxComments((prev) => ({
        ...prev,
        [e.target.id]: !prev[e.target.id],
      }))
    } else {
      await mutationUpdateShowInLog.mutateAsync({
        included: !checkboxMiles[e.target.id],
        milestoneId: e.target.id,
      })
      setCheckboxMiles((prev) => ({
        ...prev,
        [e.target.id]: !prev[e.target.id],
      }))
    }
  }

  const renderComments = (comment, objective, topic) => {
    return (
      <div key={comment.commentId} className="decision-log-inner-grid-area-view">
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 0.03fr', gap: '1rem' }}>
          <div
            className="decision-log-comment"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '1rem',
              alignItems: 'center',
            }}
          >
            {!isTeamMeetingView && isLogEditable && (
              <div style={{}}>
                <input
                  type="checkbox"
                  id={comment.commentId}
                  name={comment.commentId}
                  checked={checkboxComments[comment.commentId]}
                  onChange={(e) => handleCheck(e, true)}
                />
              </div>
            )}

            <div
              style={{
                backgroundColor: 'white',
                color: '#A9A9A9',
                border: '1px solid #A9A9A9',
                padding: '0.1rem 0.2rem',
                borderRadius: '0.5rem',
                width: '4.5rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '13.33px',
                }}
              >
                {comment.isDecision ? t('decisionLog.decision') : t('decisionLog.comment')}
              </div>
            </div>
            {!isTeamMeetingView && isLogEditable ? (
              <Wrapper>
                <MentionsInput
                  className="body_bottom_text"
                  value={comment.commentText}
                  onChange={(e, v, nv, mentions) => {
                    let id = 0
                    let mention = mentionState.find((mention) => mention.id === id)
                    if (mention) {
                      setMentionState((prev) =>
                        prev.map((item) =>
                          item.id === id ? { ...item, mentions: mentions } : item,
                        ),
                      )
                    } else {
                      setMentionState([...mentionState, { id: id, mentions: mentions }])
                    }

                    updateItemText(comment.commentId, e.target.value)
                  }}
                  onBlur={(e) => {
                    if (/\S/.test(comment.commentText)) {
                      updateOutcome(comment.commentId, 0, comment.commentText)
                    }
                  }}
                  allowSuggestionsAboveCursor={true}
                  style={{
                    height: '4.3rem',
                    padding: '0',
                    input: {
                      margin: 0,
                      overflow: 'auto',
                    },
                    suggestions: {
                      list: {
                        maxHeight: 80,
                        overflowY: 'auto',
                      },
                      item: {
                        '&focused': {
                          backgroundColor: '#d3d3d3',
                        },
                      },
                    },
                    highlighter: {
                      border: '4px solid transparent',
                    },
                  }}
                  spellCheck={false}
                >
                  <Mention
                    trigger="@"
                    data={userList}
                    // renderSuggestion={this.renderUserSuggestion}
                    markup="@[__display__](user:__id__)"
                    displayTransform={(id, display) => `@${display}`}
                    renderSuggestion={(suggestion, search, highlightedDisplay) => (
                      <div className="user">{highlightedDisplay}</div>
                    )}
                    appendSpaceOnAdd={true}
                    // style={{backgroundColor:'rgb(206, 228, 229)'}}
                  />
                </MentionsInput>
              </Wrapper>
            ) : (
              <div style={{ width: '40rem' }}>
                {linkifyString(removeMentionMarkupFromText(comment.commentText))}
              </div>
            )}
          </div>
          {/* {!isTeamMeetingView && isLogEditable && (
            <div
              class="fitted-button blue"
              onClick={() => {
                handleEdit(comment.commentId, comment.commentText, 'comment')
              }}
            >
              <span class="material-symbols-outlined icon-size-sm">edit</span>
            </div>
          )} */}
        </div>
        <div>{shortenName(comment.commentOwnerName)}</div>
      </div>
    )
  }

  const renderMilestones = (milestone, objective, topic) => {
    return (
      <div key={milestone.milestoneId} className="decision-log-inner-grid-area-view">
        <div
          key={milestone.milestoneId}
          style={{ display: 'grid', gridTemplateColumns: '1fr 0.03fr', gap: '1rem' }}
        >
          <div
            className="decision-log-milestone"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '1rem',
              alignItems: 'center',
            }}
          >
            {!isTeamMeetingView && isLogEditable && (
              <div style={{}}>
                <input
                  type="checkbox"
                  id={milestone.milestoneId}
                  name={milestone.milestoneId}
                  checked={checkboxMiles[milestone.milestoneId]}
                  onChange={(e) => handleCheck(e, false)}
                />
              </div>
            )}
            <div
              style={{
                backgroundColor: '#A9A9A9',
                color: 'white',
                border: '1px solid  white',
                padding: '0.1rem 0.2rem',
                borderRadius: '0.5rem',
                width: '4.5rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '13.33px',
                }}
              >
                {t('decisionLog.action')}
              </div>
            </div>
            {!isTeamMeetingView && isLogEditable ? (
              <Wrapper>
                <MentionsInput
                  className="body_bottom_text"
                  value={milestone.milestoneDescription}
                  onChange={(e, v, nv, mentions) => {
                    let id = milestone.milestoneId
                    let mention = mentionState.find((mention) => mention.id === id)
                    if (mention) {
                      setMentionState((prev) =>
                        prev.map((item) =>
                          item.id === id ? { ...item, mentions: mentions } : item,
                        ),
                      )
                    } else {
                      setMentionState([...mentionState, { id: id, mentions: mentions }])
                    }

                    updateItemText(milestone.milestoneId, e.target.value)
                  }}
                  onBlur={(e) => {
                    if (/\S/.test(milestone.milestoneDescription)) {
                      updateOutcome(0, milestone.milestoneId, milestone.milestoneDescription)
                    }
                  }}
                  allowSuggestionsAboveCursor={true}
                  style={{
                    height: '3rem',
                    padding: '0',

                    input: {
                      margin: 0,
                      overflow: 'auto',
                    },
                    suggestions: {
                      list: {
                        maxHeight: 80,
                        overflowY: 'auto',
                      },
                      item: {
                        '&focused': {
                          backgroundColor: '#d3d3d3',
                        },
                      },
                    },
                    highlighter: {
                      border: '4px solid transparent',
                    },
                  }}
                  spellCheck={false}
                >
                  <Mention
                    trigger="@"
                    data={userList}
                    // renderSuggestion={this.renderUserSuggestion}
                    markup="@[__display__](user:__id__)"
                    displayTransform={(id, display) => `@${display}`}
                    renderSuggestion={(suggestion, search, highlightedDisplay) => (
                      <div className="user">{highlightedDisplay}</div>
                    )}
                    appendSpaceOnAdd={true}
                    // style={{backgroundColor:'rgb(206, 228, 229)'}}
                  />
                </MentionsInput>
              </Wrapper>
            ) : (
              <div style={{ width: '40rem' }}>
                {linkifyString(removeMentionMarkupFromText(milestone.milestoneDescription))}
              </div>
            )}
          </div>
          {!isTeamMeetingView && isLogEditable && (
            <div style={{}}>
              {/* <div
                class="fitted-button blue"
                onClick={() => {
                  handleEdit(milestone.milestoneId, milestone.milestoneDescription, 'activity')
                }}
              >
                <span class="material-symbols-outlined icon-size-sm">edit</span>
              </div> */}
              <div
                class="fitted-button blue"
                onClick={() => {
                  assignActivity(milestone)
                }}
              >
                <span class="material-symbols-outlined icon-size">person_add</span>
              </div>
            </div>
          )}
        </div>
        <div>
          {milestone?.assignees?.map((assignee, index) => (
            <span key={index}>
              {index > 0 && ', '}
              {shortenName(assignee.name)}
            </span>
          ))}
        </div>
      </div>
    )
  }

  const inputChangedHandler = (e) => {
    let tlId = parseInt(e.eId)
    setTlId(tlId)
    getDecisionLogListData(tlId)
  }

  const handleAdditionalLogViewers = (e) => {
    setLogViewersList(e?.map((emp) => emp.eId))
    setPeopleList(e)
  }

  async function assignActivity(milestone) {
    setSelectedMilestoneId(milestone.milestoneId)
    setIsAssignmentModalOpen(true)
    setSelectedAssigneeList(milestone?.assignees)
  }

  const closeAssigneeActivity = () => {
    setSelectedAssigneeList([])
    setSelectedMilestoneId(null)
    getDecisionLogData(
      decisionLogList?.decisionLogs[0].logId,
      decisionLogList.decisionLogs[0].isFinalized,
    )
  }

  const updateOutcome = async (commentId, activityId, textInput) => {
    let data = {}
    data.commentId = commentId
    data.activityId = activityId
    data.outcome = textInput
    let id = activityId === 0 ? commentId : activityId

    let mentions =
      mentionState.find((mention) => mention.id === id)?.mentions.map((m) => m.id) ?? null
    if (mentions) {
      data.mentions = mentions
    }

    await mutationUpdateItemText.mutateAsync(data, {
      onSuccess: () => {
        Toast.fire({
          icon: 'success',
          title:
            commentId != 0
              ? t('Common:modalMessages.commentUpdatedSuccessfully')
              : t('Common:modalMessages.actionUpdatedSuccessfully'),
        })
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongPleaseTryAgain'),
        })
      },
    })
  }

  const updateMilestoneAssignees = (milestoneId, newAssignees) => {
    const updateMilestone = (milestone) => {
      if (milestone.milestoneId === milestoneId) {
        milestone.assignees = newAssignees
        return true
      }
      return false
    }

    const updateTopics = (topics) => {
      for (const topic of topics) {
        if (topic.milestones && topic.milestones.some(updateMilestone)) {
          return true
        }
      }
      return false
    }

    for (const objective of decisionLogData.objectives) {
      const topicTypes = ['topics']

      for (const topicType of topicTypes) {
        if (objective[topicType] && updateTopics(objective[topicType])) {
          return
        }
      }
    }
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {isEditCommentActivityModalOpen && (
          <EditCommentActivityModal
            isModalOpen={isEditCommentActivityModalOpen}
            closeModal={() => {
              handleCloseEditCommentActivityModal()
            }}
            commentId={commentIdToEdit}
            activityId={activityIdToEdit}
            textIn={textInput}
            userList={userList}
          />
        )}
        {isAssignmentModalOpen && selectedMilestoneId && (
          <AssigneeActivity
            objectiveId={-1}
            milestoneId={selectedMilestoneId}
            setModelView={setIsAssignmentModalOpen}
            assigneeList={selectedAssigneeList}
            view={isAssignmentModalOpen}
            loadAgain={closeAssigneeActivity}
            updateMilestoneAssignees={updateMilestoneAssignees}
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
            {hasRole('cxo') && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <div style={{ fontWeight: 'bold' }}>{t('decisionLog.selectLeader')}</div>
                <SelectSingleField
                  value={
                    teamLeadersData?.employees?.filter(
                      (emp) => parseInt(emp.eId) === parseInt(tlId),
                    )[0]
                  }
                  options={teamLeadersData?.employees}
                  onChange={inputChangedHandler}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.eId}
                  width={'20rem'}
                />
              </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <div style={{ fontWeight: 'bold' }}>{t('decisionLog.selectLog')}</div>
              <Select
                menuPortalTarget={document.body}
                placeholder={t('Common:filter.select') + '...'}
                value={selectedLog}
                onChange={handleChangeSelectedLog}
                getOptionLabel={(option) =>
                  `${option?.logName} ${
                    option?.isMain &&
                    (option?.logStatus === 'NOT_STARTED_DECISION_LOG_STATUS' ||
                      option?.logStatus === 'IN_PROGRESS_DECISION_LOG_STATUS')
                      ? '(' + t('decisionLog.inProgress') + ')'
                      : ''
                  }`
                }
                getOptionValue={(option) => option?.logId}
                options={decisionLogList?.decisionLogs}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '25rem',
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }),
                }}
              />
            </div>

            {!((hasRole('team') && !isDelegateTl) || hasRole('ic')) && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <div style={{ fontWeight: 'bold' }}>{t('decisionLog.logName')}</div>
                <input
                  placeholder={t('Common:filter.select') + '...'}
                  value={logName}
                  onChange={(e) => {
                    handleChangeLogName(e)
                  }}
                  style={{
                    width: '20rem',
                    height: '2.5rem',
                    backgroundColor: 'hsl(0, 0%, 100%)',
                    borderColor: 'hsl(0, 0%, 80%)',
                    borderRadius: '4px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    boxSizing: 'border-box',
                  }}
                />
              </div>
            )}
          </div>
          {isLogEditable && (
            <>
              <div style={{ width: 'auto', fontWeight: 'bold', marginTop: '0.5rem' }}>
                {t('decisionLog.additionalLogRecipients')}
              </div>
              <Select
                isMulti={true}
                value={peopleList}
                options={additionalLogViewersList}
                onChange={handleAdditionalLogViewers}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.eId}
                isClearable={true}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '42rem',
                  }),
                }}
              />
            </>
          )}
        </div>

        {decisionLogData?.objectives?.length > 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              //marginTop: '1.2rem',
            }}
          >
            <div style={{ marginBottom: '1rem', padding: '0 2rem 2rem 2rem' }}>
              <div className="decision-log-meeting-table">
                <div className="decision-log-meeting-header"></div>
                {/* <div className="decision-log-inner-grid-area-view">
                  <div className="decision-log-meeting-header">{t('decisionLog.outcome')}</div>
                  <div className="decision-log-meeting-header" style={{ paddingLeft: '0rem' }}>
                    {t('decisionLog.owner')}
                  </div>
                  {!isTeamMeetingView && isLogEditable && (
                    <div className="decision-log-meeting-header">{t('decisionLog.include')}?</div>
                  )}
                </div>

                <div className="divider decision-log-meeting-description"></div> */}

                {decisionLogData?.objectives?.map((objective, index) => {
                  const { topics } = objective
                  const totalRows = topics?.length ?? 0
                  return (
                    <React.Fragment key={`objective-${index}`}>
                      {totalRows > 0 && (
                        <React.Fragment>
                          <div
                            className="decision-log-meeting-description"
                            style={{ paddingTop: '2rem', maxWidth: '90%' }}
                          >
                            <b>
                              {/* only show initiative number if not general and initiative id exists */}
                              {isProductSettingsEnabled &&
                              !objective?.isGeneralObjective &&
                              objective?.initiativeId &&
                              objective.isGeneralObjective === false ? (
                                <span>
                                  {objective?.initiativeId.toString()?.padStart(5, '0')} -{' '}
                                  {objective.objDescription}
                                </span>
                              ) : (
                                <span>{objective.objDescription}</span>
                              )}
                            </b>
                          </div>
                          <div className="decision-log-meeting-description">
                            <div className="underline-meeting"></div>
                          </div>
                          {topics?.length > 0 && (
                            <>
                              {topics.map((topic) => (
                                <>
                                  <div
                                    className="decision-log-meeting-description"
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      gap: '1rem',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor:
                                          topic.topicType === 1
                                            ? 'rgb(237, 82, 46)'
                                            : topic.topicType === 2
                                              ? 'rgb(9, 38, 213)'
                                              : topic.topicType === 3
                                                ? 'rgb(127, 131, 155)'
                                                : '#9933ff',
                                        color: 'white',
                                        padding: '0.2rem 0.2rem',
                                        borderRadius: '0.5rem',
                                        width: '5rem',
                                        fontWeight: 'bold',
                                        height: 'fit-content',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          fontSize: '13.33px',
                                        }}
                                      >
                                        {topic.topicType === 1
                                          ? t('Common:filter.blocker')
                                          : topic.topicType === 2
                                            ? t('Common:filter.guidance')
                                            : topic.topicType === 3
                                              ? t('Common:filter.update')
                                              : t('Common:filter.admin')}
                                      </div>
                                    </div>
                                    <b style={{ maxWidth: '53rem' }}>
                                      {linkifyString(topic.description)}
                                    </b>
                                    <div>
                                      {topic.hasMeetingDeck && (
                                        <>
                                          <Link
                                            to={`/meetingDeck`}
                                            state={{
                                              umtId: topic.umtId,
                                              topicType: topic.topicType,
                                              redirectTo: 'decisionLog',
                                            }}
                                          >
                                            <div class="fitted-button blue">
                                              <span class="material-symbols-outlined icon-size">
                                                slideshow
                                              </span>
                                            </div>
                                          </Link>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="decision-log-meeting-description">
                                    <div className="underline-meeting-light"></div>
                                  </div>
                                  {/* <div></div> */}
                                  {/* Bring back if we want indentation */}

                                  {/* <div className="decision-log-blocker">
                                      {linkifyString(topic.description)}
                                    </div> */}
                                  <div>
                                    {topic?.comments?.length > 0 && (
                                      <>
                                        {topic.comments.map((comment) => (
                                          <>
                                            {isLogEditable ? (
                                              renderComments(comment, objective, topic)
                                            ) : (
                                              <>
                                                {comment.showInLog ? (
                                                  renderComments(comment, objective, topic)
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ))}
                                      </>
                                    )}
                                    {topic?.milestones?.length > 0 && (
                                      <>
                                        {topic.milestones.map((milestone) => (
                                          <>
                                            {isLogEditable ? (
                                              renderMilestones(milestone, objective, topic)
                                            ) : (
                                              <>
                                                {milestone.showInLog ? (
                                                  renderMilestones(milestone, objective, topic)
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ))}
                                      </>
                                    )}
                                  </div>
                                </>
                              ))}
                            </>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
            {logNameErrorMessage && (
              <div
                style={{
                  height: '1rem',
                  width: '100%',
                  textAlign: 'center',
                  paddingBottom: '0.5rem',
                }}
              >
                <span
                  className="validation-error"
                  style={{
                    fontSize: '14px',
                    visibility: logNameErrorMessage ? 'visible' : 'hidden',
                  }}
                >
                  {logNameErrorMessage}
                </span>
              </div>
            )}
            {!isTeamMeetingView && isLogEditable && (
              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <div class="fitted-button blue" onClick={() => handleClearUnfinalizedLog()}>
                  <span>{t('decisionLog.clearLog')}</span>
                </div>
                <div class="fitted-button blue" onClick={() => handleSendDecisionLog()}>
                  <span>{t('decisionLog.sendLog')}</span>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div style={{ padding: '2rem 0rem 2rem 10rem' }}>
            {t('decisionLog.thereAreNoLogsAtThisTime')}
          </div>
        )}
      </div>
      <p>&nbsp;</p>
    </>
  )
}

export default DecisionLog

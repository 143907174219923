import React, { useState, useRef } from 'react'
import { Container } from './style'
import { Draggable } from 'react-beautiful-dnd'
import CardModal from '../../../../components/CardModal'

import EditTask from '../../../createTask/index'

import SubActivity from '../../../SubActivities/index'

import './style.scss'
import { dayMonthFilter } from '../../../../utils/time'

import CommentsModal2 from '../../../../components/CommentsModal2'
import { postHttpRequest, getHttpRequest } from '../../../../api/query/dynamicAPI'
import {
  POST_KANBAN_MARK_ACTIVITY_AS_PRIORITY,
  CREATE_SUB_ACTIVITY,
  GET_SINGLE_SUB_ACTIVITY,
  API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
  API_ERROR_MESSAGE,
  DELETE_SUB_ACTIVITY,
} from '../../../../api/constant/route'
import AddEditActivityModal from '../../../../components/AddEditActivityModal'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import Toast from '../../../../common/toast'
import { useTranslation } from 'react-i18next'
import Textarea from 'rc-textarea'
import Swal from 'sweetalert2'
import KanbanDropdownMenu from '../../../../components/KanbanDropdownMenu'
import { uploadFileFilingCabinet } from '../../../../api/services/filingCabinet.services'
import { getCompanyConfigSettings } from '../../../../utils/auth'
import AssigneeActivity from '../../../MeetingMultiView/components/MeetingTopics/common/assigneeActivity'
import LinkActionToMeetingTopicModal from '../../../../components/LinkActionToMeetingTopicModal'
import { removeMentionMarkupFromText } from '../../../../utils/parseMarkup'
import { unLinkMeetingTopic } from '../../../../api/services/meetingTopic.services'
import SubactivityDropdownMenu from '../../../SubActivities/subactivityDropdownMenu'
import SubactivityModal from '../subactivityModal'
import ExpandableText from '../../../../components/ExpandableText'
import AiMemoQuestionsModal from '../../../MeetingMultiView/components/MeetingTopics/common/component/AiMemoQuestionsModal'
import AiMemoModal from '../../../MeetingMultiView/components/MeetingTopics/common/component/AiMemoModal'
import ActionLabelDisplay from '../actionLabelDisplay'
import CardColorModal from '../cardColorModal'

function Task(props) {
  const { t } = useTranslation(['Common'])
  const [isCreateModel, setIsCreateModel] = useState(false)
  const [subActivites, setSubActivites] = useState(false)
  let assigneeList = props?.task?.assigneeList
  let displayAssigneeList = ''
  if (assigneeList != undefined && assigneeList.length > 0) {
    displayAssigneeList = assigneeList
      .map((e) => {
        return e.name.match(/\b(\w)/g).join('')
      })
      .join(',')
  }
  //Modals open state hooks
  //Modals open state hooks
  const [isSingleActivityModalOpen, setIsSingleActivityModalOpen] = useState(false)
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false)
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false)
  const [isLinkActionToMeetingTopicModalOpen, setIsLinkActionToMeetingTopicModalOpen] =
    useState(false)

  const [showSubactivities, setShowSubactivities] = useState(false)
  const [newSubActivityDescription, setNewSubActivityDescription] = useState('')
  const [isCardModalOpen, setIsCardModalOpen] = useState(false)

  const [subActivityListData, setSubActivityListData] = useState(
    props?.task?.subactivityList ? props?.task?.subactivityList : [],
  )
  const [selectedSubactivity, setSelectedSubactivity] = React.useState(null)
  const [isSubactivityModalOpen, setIsSubactivityModalOpen] = React.useState(false)

  const kanbanUploadRef = useRef(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const showMeetingDeck = getCompanyConfigSettings('showMeetingDeck') === true

  const [isMilestoneLabelsModalOpen, setIsMilestoneLabelsModalOpen] = useState(false)
  const [milestoneLabelsList, setMilestoneLabelsList] = useState([])

  const [isCardColorModalOpen, setIsCardColorModalOpen] = useState(false)

  React.useEffect(() => {
    let listData = props?.task?.subactivityList ? props?.task?.subactivityList : []
    setSubActivityListData(listData)
  }, [props?.task?.subactivityList])

  React.useEffect(() => {
    if (props?.task?.milestoneLabels) {
      setMilestoneLabelsList(props?.task?.milestoneLabels)
    }
  }, [props?.task?.milestoneLabels])

  function getSubActivites() {
    getHttpRequest(`${GET_SINGLE_SUB_ACTIVITY}/${props.task.milestoneId}`)
      .then((response) => {
        if (response && response.subActivitiesList && response.subActivitiesList.length) {
        }
        if (!response) {
          return
        }
      })
      .catch((error) => {})
  }

  const calculatePercentage = (totalSubactivityCount) => {
    let countCompleted = 0
    if (subActivityListData) {
      let tempSubActivitiesList = JSON.parse(JSON.stringify(subActivityListData))

      for (let sub of tempSubActivitiesList) {
        if (sub.status === 'COMPLETE') {
          countCompleted++
        }
      }
    }

    let completedSubactivityCount = countCompleted

    if (totalSubactivityCount === 0) {
      return 0
    } else {
      let rawPercentage = (completedSubactivityCount * 100) / totalSubactivityCount
      return Math.round(rawPercentage)
    }
  }

  const openCommentsModal = () => {
    setIsCommentsModalOpen(true)
  }

  const closeCommentsModal = () => {
    setIsCommentsModalOpen(false)
  }

  const canBeDisabledNoSubactivity = props.task.totalSubactivityCount > 0 ? '' : 'inactive-blue'

  const canBeDisabledNoComments = props.task.totalCommentsCount > 0 ? '' : 'inactive-blue'

  async function markActicityAsPriority(value, milestoneId) {
    const postObject = {
      milestoneId: milestoneId,
      newStatus: value,
    }
    postHttpRequest(POST_KANBAN_MARK_ACTIVITY_AS_PRIORITY, postObject)
      .then((response) => {
        if (response) {
          props.setIsRefreshObjective((old) => !old)
          return
        } else {
        }
      })
      .catch((error) => {})
  }

  const currentUser = localStorage.getItem('tfmu')
  const demoUser = localStorage.getItem('dMd') === 'true'

  const shouldApplyStyle = props.task.umtId !== 0
  const topicColors = ['#ED522E', '#0926D5', 'rgb(127, 131, 155)', '#9933FF']
  const topicType = ['Blocked', 'Guidance', 'Update', 'Admin']
  // ideally, when umtId is not 0. topic type will be in (1,2,3,4)
  //const containerStyle = shouldApplyStyle ? { borderTop: '4px solid '+ topicColors[props.task.topicType - 1] } : {}
  const containerStyle = shouldApplyStyle
    ? { backgroundColor: topicColors[props.task.topicType - 1] }
    : {}

  const handleTextChange = (e) => {
    setNewSubActivityDescription(e.target.value)
  }

  const createSubactivity = () => {
    const postObj = {}
    postObj.objectiveId = parseInt(props?.objectiveId)
    postObj.activityId = parseInt(props?.task?.milestoneId)
    postObj.subActivities = [
      { subAcitivityDescription: newSubActivityDescription, status: 'NOT_YET_STARTED' },
    ]

    postHttpRequest(CREATE_SUB_ACTIVITY, postObj)
      .then((response) => {
        if (response) {
          setNewSubActivityDescription('')
          props.setIsRefreshObjective((old) => !old)
          Toast.fire({
            icon: 'success',
            title: API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
          })

          return
        }
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: API_ERROR_MESSAGE,
        })
      })
  }

  const [memoAction, setMemoAction] = React.useState(null)
  const [isAiMemoQuestionsModalOpen, setIsAiMemoQuestionsModalOpen] = React.useState(false)
  const [isFromAiMemoQuestionsModal, setIsFromAiMemoQuestionsModal] = React.useState(false)
  const [isAiMemoDraftModalOpen, setIsAiMemoDraftModalOpen] = React.useState(false)
  const [memoMaterial, setMemoMaterial] = React.useState(null)
  const [showEng, setShowEng] = React.useState(
    getCompanyConfigSettings('languageSettings') !== 'es',
  )

  const openAiMemoGeneration = (data) => {
    setMemoAction(data)
    setIsAiMemoQuestionsModalOpen(true)
    setIsFromAiMemoQuestionsModal(true)
  }

  const openAiMemoDraft = (data) => {
    setIsFromAiMemoQuestionsModal(false)
    setMemoAction(data)
    setIsAiMemoDraftModalOpen(true)
  }

  async function confirmDeleteSubactivity(subActId, subActivityText) {
    Swal.fire({
      title: t('Common:modalMessages.areYouSureDeleteX', { X: subActivityText }),
      text: '',
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        deleteSubActivity(subActId)
      }
    })
  }

  const deleteSubActivity = (subActId) => {
    const postObject = {
      subActivityId: subActId,
    }
    postHttpRequest(DELETE_SUB_ACTIVITY, postObject)
      .then((response) => {
        if (response) {
          props.setIsRefreshObjective((old) => !old)
          return
        } else {
        }
      })
      .catch((error) => {})
  }

  const updateSubActivityListDescriptionOnBlur = (e, index) => {
    if (subActivityListData[index]) {
      subActivityListData[index][e.target.name] = e.target.value
    }
    editSubactivity(index)
  }

  const updateSubActivityListStatus = (e, index) => {
    const tempSubActivitiesList = [...subActivityListData]
    let status = tempSubActivitiesList[index].status
    if (status === 'COMPLETE') {
      tempSubActivitiesList[index].status = 'NOT_YET_STARTED'
    } else if (status === 'NOT_YET_STARTED') {
      tempSubActivitiesList[index].status = 'COMPLETE'
    }

    editSubactivity(index)
  }

  const editSubactivity = (index) => {
    const postObj = {}
    postObj.objectiveId = parseInt(props?.objectiveId)
    postObj.activityId = parseInt(props?.task?.milestoneId)
    let copySubActivity = subActivityListData[index]
    postObj.subActivities = [{ ...copySubActivity }]

    postHttpRequest(CREATE_SUB_ACTIVITY, postObj)
      .then((response) => {
        if (response) {
          props.setIsRefreshObjective((old) => !old)
          return
        } else {
        }
      })
      .catch((error) => {})
  }

  async function updateSubActivityList(value, index) {
    const dataArray = [...subActivityListData]
    const subActivity = dataArray[index]
    if (subActivity) {
      subActivity.subAcitivityDescription = value
    }

    dataArray[index] = subActivity
    setSubActivityListData(dataArray)
  }

  const handleUploadFileClick = () => {
    if (!kanbanUploadRef.current) return
    kanbanUploadRef.current.click()
  }

  const unLinkAction = async () => {
    setIsLoading(true)
    let data = {}
    data['actionId'] = props.task.milestoneId
    try {
      let result = await unLinkMeetingTopic(data)
      if (result.success) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.actionSuccessfullyUnlinkedToMeetingTopic'),
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    setIsLoading(false)
    props.task.umtId = 0
  }

  async function onChangeFile(e) {
    const files = e.target.files
    if (files.length !== 0) {
      setIsLoading(true)
      let formData = new FormData()

      formData.append('milestoneId', props.task?.milestoneId)
      formData.append('objId', props.task?.objectiveId)

      let umtId = props.task?.umtId
      if (umtId && umtId !== 0) {
        formData.append('umtId', umtId)
        // formData.append('topicType', topicType)
      }
      formData.append('file', files[0], files[0].name)
      if (props.task?.fileId) {
        formData.append('existingFileId', props.task?.fileId)
      }

      let response = await uploadFileFilingCabinet(formData)
      setIsLoading(false)
      if (response.upload_status === 'success') {
        props.setIsRefreshObjective((old) => !old)
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.fileUploadSuccess'),
        })
      } else {
        let errorResult = response.data.upload_status
        if (errorResult === 'timeout') {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.fileUploadRequestTimeout'),
          })
        } else if (errorResult === 'malicious') {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.fileScanningFailed'),
          })
        } else {
          // Default error handling for other cases
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        }
      }
    }
  }

  async function downloadFile(fileId) {
    const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
    window.open(`${BASE_URL}/export_filing_cabinet_file/${fileId}`, '_blank')
  }

  function isPastDue(unixTimestamp) {
    if (unixTimestamp === 0) {
      return false
    }

    const currentDate = Date.now()
    const inputDate = unixTimestamp * 1000

    return (
      inputDate < currentDate && new Date(currentDate).getDate() !== new Date(inputDate).getDate()
    )
  }

  const isCreatedIn24Hours = (unixTimestamp) => {
    if (unixTimestamp === 0) {
      return false
    }

    const currentDate = Date.now()
    const inputDate = unixTimestamp * 1000
    return inputDate > currentDate - 129600000
  }

  const renderToTheRight = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
          {props?.task?.assigneeList
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .slice(0, 3) // Display only the first 3 assignees
            .map((assignee, assigneeIndex) => (
              <React.Fragment key={assigneeIndex}>
                {props?.employeesWithColors.map((emp, empIndex) => (
                  <React.Fragment key={empIndex}>
                    {emp.eId === assignee.eId && (
                      <span
                        style={{
                          backgroundColor: emp.color,
                          marginLeft: assigneeIndex > 0 ? '-10px' : '0', // Adjust the overlap
                        }}
                        className="tooltip circle-small user-initials-circle"
                      >
                        {(assignee.name.match(/\b(\w)/g) || []).slice(0, 2).join('')}
                        <span className="tooltiptext" style={{ fontSize: '16px' }}>
                          {assignee.name}
                        </span>
                      </span>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          {props?.task?.assigneeList?.length > 3 && (
            <span className="tooltip">
              ...
              <span className="tooltiptext" style={{ fontSize: '16px' }}>
                {/* You can add a tooltip with the names of remaining assignees here */}
                {props?.task?.assigneeList
                  .slice(3)
                  .map((assignee) => assignee.name)
                  .join(', ')}
              </span>
            </span>
          )}
          <div
            className="no-border-button no-border-button-gray tooltip"
            onClick={() => {
              setIsAssigneeModalOpen(true)
            }}
          >
            <span className="material-symbols-outlined icon-size">person_add</span>
            <span class="tooltiptext" style={{ left: '90%' }}>
              {' '}
              {t('Common:tooltip.assignToOthers')}{' '}
            </span>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {isSingleActivityModalOpen && (
        <AddEditActivityModal
          isModalOpen={isSingleActivityModalOpen}
          closeModal={() => {
            setIsSingleActivityModalOpen(false)
          }}
          objectiveId={props.objectiveId}
          activityId={props.task.milestoneId}
          setIsRefreshObjective={props.setIsRefreshObjective}
          usedInKanban={true}
        />
      )}

      {isAiMemoQuestionsModalOpen && memoAction && (
        <AiMemoQuestionsModal
          isModalOpen={isAiMemoQuestionsModalOpen}
          closeModal={(hasDraftGeneratedInQAModal = false) => {
            // only open draft modal if draft has been generated in QA modal
            if (hasDraftGeneratedInQAModal) {
              setIsAiMemoDraftModalOpen(true)
            }
            setIsAiMemoQuestionsModalOpen(false)
          }}
          memoAction={memoAction}
          setMemoMaterial={setMemoMaterial}
          setIsAiMemoDraftModalOpen={setIsAiMemoDraftModalOpen}
          setShowEng={setShowEng}
          showEng={showEng}
        />
      )}
      {isAiMemoDraftModalOpen && memoAction && (
        <AiMemoModal
          isModalOpen={isAiMemoDraftModalOpen}
          closeModal={(hasError) => {
            setIsAiMemoDraftModalOpen(false)
            if (!hasError) {
              setMemoAction(null)
              setMemoMaterial(null)
              // setIsAiMemoQuestionsModalOpen(false)
              props.setIsRefreshObjective((old) => !old)
            }
          }}
          aiMemoCount={props.task.aiMemoCount}
          memoAction={memoAction}
          memoMaterial={memoMaterial}
          isFromAiMemoQuestionsModal={isFromAiMemoQuestionsModal}
          setIsAiMemoQuestionsModalOpen={setIsAiMemoQuestionsModalOpen}
          setShowEng={setShowEng}
          showEng={showEng}
        />
      )}

      {isCardColorModalOpen && (
        <CardColorModal
          milestoneId={props.task.milestoneId}
          closeModal={() => {
            setIsCardColorModalOpen(false)
            props.setIsRefreshObjective((old) => !old)
          }}
        />
      )}

      <Draggable
        key={props.task.milestoneId.toString()}
        draggableId={props.task.milestoneId.toString()}
        index={props.index}
        isDragDisabled={subActivites || isCreateModel}
      >
        {(provided, snapshot) => (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            bgColor={props.task.cardColor !== '' ? `#${props.task.cardColor}` : 'white'}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                justifyItems: 'center',
              }}
            >
              {props.task.umtId !== 0 ? (
                <div
                  className="action-box tooltip"
                  style={containerStyle}
                  onClick={() => {
                    setIsCardModalOpen(true)
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {topicType[props.task.topicType - 1]}
                    <span class="tooltiptext bgu_kanban_desc" style={{ left: '90%' }}>
                      {' '}
                      {props.task.meetingTopicDescription}{' '}
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  className={'no-border-button no-border-button-gray  tooltip'}
                  onClick={() => {
                    setIsLinkActionToMeetingTopicModalOpen(true)
                  }}
                >
                  <span class="material-symbols-outlined icon-size"> add_link</span>
                  <span class="tooltiptext" style={{ left: '90%' }}>
                    {' '}
                    {t('Common:tooltip.linkMeetingTopic')}{' '}
                  </span>
                </div>
              )}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  className={'no-border-button no-border-button-gray  tooltip'}
                  onClick={() => {
                    setIsCardColorModalOpen(true)
                  }}
                >
                  <span class="material-symbols-outlined icon-size"> colors</span>
                  <span class="tooltiptext" style={{ left: '90%' }}>
                    {' '}
                    {t('Common:tooltip.changeCardColor')}{' '}
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {!props.task.isPriority && (
                    <button
                      class="icon-button-no-border"
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        width: 'fit-content',
                        padding: '0 0 0.5rem 0rem',
                        backgroundColor:
                          props.task.cardColor !== '' ? `#${props.task.cardColor}` : 'white',
                      }}
                    >
                      <span
                        class="material-symbols-outlined icon-size"
                        onClick={() => {
                          markActicityAsPriority(true, props.task.milestoneId)
                        }}
                      >
                        flag
                      </span>
                    </button>
                  )}
                  {props.task.isPriority && (
                    <button
                      class="icon-button-no-border"
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        width: 'fit-content',
                        padding: '0 0 0.5rem 0rem',
                        backgroundColor:
                          props.task.cardColor !== '' ? `#${props.task.cardColor}` : 'white',
                      }}
                    >
                      <span
                        class="material-symbols-outlined icon-size"
                        style={{ color: 'red' }}
                        onClick={() => {
                          markActicityAsPriority(false, props.task.milestoneId)
                        }}
                      >
                        flag
                      </span>
                    </button>
                  )}
                </div>

                <div className={'tooltip'} style={{ display: 'flex', flexDirection: 'column' }}>
                  {props.task.criticalityScore === 'CRITICAL' && (
                    <>
                      <span class="material-symbols-outlined  red-no-border icon-size-xl">
                        {' '}
                        stat_2
                      </span>
                      <span class="tooltiptext" style={{ left: '90%' }}>
                        {t('Common:priority.criticalPriority')}
                      </span>
                    </>
                  )}

                  {props.task.criticalityScore === 'HIGH' && (
                    <>
                      <span class="material-symbols-outlined  red-no-border icon-size-xl">
                        {' '}
                        stat_1
                      </span>
                      <span class="tooltiptext" style={{ left: '90%' }}>
                        {t('Common:priority.highPriority')}
                      </span>
                    </>
                  )}

                  {props.task.criticalityScore === 'MEDIUM' && (
                    <>
                      <span
                        style={{ alignSelf: 'flex-start' }}
                        class="material-symbols-outlined orange-no-border icon-size-xl spin-180"
                      >
                        {' '}
                        minimize
                      </span>
                      <span class="tooltiptext" style={{ left: '90%' }}>
                        {t('Common:priority.mediumPriority')}
                      </span>
                    </>
                  )}

                  {props.task.criticalityScore === 'LOW' && (
                    <>
                      <span class="material-symbols-outlined blue-no-border icon-size-xl">
                        {' '}
                        stat_minus_1
                      </span>
                      <span class="tooltiptext" style={{ left: '90%' }}>
                        {t('Common:priority.lowPriority')}
                      </span>
                    </>
                  )}

                  {props.task.criticalityScore === 'BACKLOG_DEPRIORITIZED' && (
                    <>
                      <span class="material-symbols-outlined  blue-no-border icon-size-xl">
                        {' '}
                        stat_minus_2
                      </span>
                      <span class="tooltiptext" style={{ left: '90%' }}>
                        {t('Common:priority.deprioritized')}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <ActionLabelDisplay
              milestoneId={props.task.milestoneId}
              labels={milestoneLabelsList}
              refresh={() => {
                props.setIsRefreshObjective((old) => !old)
              }}
            />

            <div>
              <div
                className="task-title"
                style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
              >
                {/* add new tag */}
                {isCreatedIn24Hours(props?.task?.createdAt) && (
                  <span
                    style={{
                      fontWeight: '800',
                      fontStyle: 'italic',
                      color: '#4472C4',
                      marginRight: '0.5rem',
                    }}
                  >
                    {t('Common:modalMessages.new')}
                  </span>
                )}
                <ExpandableText
                  text={removeMentionMarkupFromText(props.task.description)}
                  renderToTheRight={renderToTheRight}
                />
              </div>
            </div>
            <div
              className="task-action-container"
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: '0.5rem',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}></div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                ></div>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                  paddingBottom: '0.5rem',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div
                    style={{ alignItems: 'center' }}
                    className={'no-border-button no-border-button-gray tooltip'}
                    onClick={() => setShowSubactivities(!showSubactivities)}
                  >
                    <span class="material-symbols-outlined icon-size">list</span>
                    <span style={{ padding: '0 0 0rem 0.3rem' }}>
                      {' '}
                      {props.task.totalSubactivityCount}
                    </span>
                    <span class="tooltiptext"> {t('Common:tooltip.showTaskList')} </span>
                  </div>

                  {props?.task?.subactivityList.length > 0 ? (
                    <div style={{ width: '2rem', height: '2rem' }} className="tooltip">
                      <CircularProgressbar
                        value={calculatePercentage(props?.task?.totalSubactivityCount)}
                        text={`${calculatePercentage(props?.task?.totalSubactivityCount)}%`}
                        styles={buildStyles({
                          textSize: '2.2rem',
                        })}
                      />

                      <span class="tooltiptext">
                        {' '}
                        {t('Common:tooltip.taskProgressPercentage')}{' '}
                      </span>
                    </div>
                  ) : (
                    <div style={{ width: '2rem', height: '2rem' }}></div>
                  )}
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyItems: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '14px',
                      color: isPastDue(props.task.dueDate) === true ? 'red' : 'black',
                    }}
                  >
                    {props.task.dueDate === 0 ? '' : dayMonthFilter(props.task.dueDate)}
                  </span>
                  <div
                    style={{ alignItems: 'center' }}
                    className={'no-border-button no-border-button-gray tooltip'}
                    onClick={() => {
                      openCommentsModal()
                    }}
                  >
                    <span class="material-symbols-outlined icon-size"> comment</span>
                    <span style={{ padding: '0 0 0rem 0.3rem' }}>
                      {' '}
                      {props.task.totalCommentsCount}
                    </span>
                    <span class="tooltiptext"> {t('Common:tooltip.comments')} </span>
                  </div>
                  {!props.backlogActivity && (
                    <KanbanDropdownMenu
                      onClickEdit={() => setIsSingleActivityModalOpen(true)}
                      isLoading={isLoading}
                      showMeetingDeck={showMeetingDeck}
                      fileId={props.task.fileId}
                      onClickDownloadFile={() => downloadFile(props.task.fileId)}
                      onClickUploadFile={() => handleUploadFileClick()}
                      onChangeFile={(e) => onChangeFile(e)}
                      kanbanUploadRef={kanbanUploadRef}
                      milestoneId={props.task.milestoneId}
                      onCilckUnlink={() => unLinkAction()}
                      umtId={props.task.umtId > 0 ? props.task.umtId : null}
                      aiMemoCount={props.task.aiMemoCount}
                      onClickAiMemo={() => openAiMemoGeneration(props.task)}
                      onClickDraftMemo={() => openAiMemoDraft(props.task)}
                      backgroundColor={
                        props.task.cardColor !== '' ? `#${props.task.cardColor}` : 'white'
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            {showSubactivities && (
              <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                {subActivityListData?.map((data, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '0.8rem',
                        justifyContent: '1rem',
                        alignItems: 'center',
                      }}
                    >
                      <Textarea
                        id="subAcitivityDescription"
                        name="subAcitivityDescription"
                        placeholder={t('Common:formPlaceHolder.typeToAdd')}
                        autoComplete="off"
                        autoSize
                        value={subActivityListData[index].subAcitivityDescription}
                        style={{
                          width: '80%',
                          resize: 'none',
                          border: '1px solid rgb(238, 238, 238)',
                          backgroundColor: 'rgba(238, 238, 238, 0.2)',
                          borderRadius: '2.5px',
                          fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
                          minHeight: '3.5rem',
                          backgroundColor: 'white',
                        }}
                        onChange={(e) => {
                          updateSubActivityList(e.target.value, index)
                        }}
                        onBlur={(e) => {
                          updateSubActivityListDescriptionOnBlur(e, index)
                        }}
                      />
                      <div
                        style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center' }}
                      >
                        {data.status === 'NOT_YET_STARTED' && (
                          <div
                            class="fitted-button checklist"
                            onClick={(e) => {
                              updateSubActivityListStatus(e, index)
                            }}
                            style={{ margin: '0' }}
                          >
                            <span
                              class="material-symbols-outlined grey-check"
                              style={{ margin: '0' }}
                            >
                              done
                            </span>
                          </div>
                        )}

                        {data.status === 'COMPLETE' && (
                          <div
                            class="fitted-button checklist"
                            onClick={(e) => {
                              updateSubActivityListStatus(e, index)
                            }}
                            style={{ margin: '0' }}
                          >
                            <span
                              class="material-symbols-outlined green-check"
                              style={{ margin: '0' }}
                            >
                              done
                            </span>
                          </div>
                        )}
                        <SubactivityDropdownMenu
                          deleteSubActivity={(e) => {
                            confirmDeleteSubactivity(
                              data.subActivityId,
                              subActivityListData[index].subAcitivityDescription,
                            )
                          }}
                          editDatesSubactivity={(e) => {
                            setSelectedSubactivity(data)
                            setIsSubactivityModalOpen(true)
                          }}
                          backgroundColor={
                            props.task.cardColor !== '' ? `#${props.task.cardColor}` : 'white'
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '0.4rem',
                    justifyContent: '1rem',
                    alignItems: 'center',
                  }}
                >
                  <Textarea
                    style={{
                      minHeight: '2.5rem',
                      width: '80%',
                      resize: 'none',
                      border: '1px solid rgb(238, 238, 238)',
                      backgroundColor: 'rgba(238, 238, 238, 0.2)',
                      borderRadius: '2.5px',
                      fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
                      backgroundColor: 'white',
                    }}
                    autoSize
                    id="subActivityDescription"
                    name="subActivityDescription"
                    placeholder={t('Common:formPlaceHolder.typeToAdd')}
                    autoComplete="off"
                    className="custom-input"
                    onChange={(e) => {
                      handleTextChange(e)
                    }}
                    value={newSubActivityDescription}
                  />

                  <div
                    class="fitted-button blue"
                    style={{ backgroundColor: 'white' }}
                    onClick={() => {
                      createSubactivity()
                    }}
                  >
                    <span class="material-symbols-outlined">add</span>
                  </div>
                </div>
              </div>
            )}
            {/* </div> */}
            {isCreateModel && (
              <EditTask
                objectiveId={props.objectiveId}
                view={isCreateModel}
                setModelView={setIsCreateModel}
                setIsRefreshObjective={props.setIsRefreshObjective}
                taskId={props.task.milestoneId.toString()}
              />
            )}

            {subActivites && (
              <SubActivity
                objectiveId={props.objectiveId}
                task={props.task}
                view={subActivites}
                setModelView={setSubActivites}
                setIsRefreshObjective={props.setIsRefreshObjective}
                taskId={props.task.milestoneId.toString()}
              />
            )}

            {isCommentsModalOpen && (
              <CommentsModal2
                objective={{ id: props.objectiveId, statement: props?.objective?.statement }}
                taskId={props.task.milestoneId}
                handleCancel={closeCommentsModal}
                setIsRefreshObjective={props.setIsRefreshObjective}
              />
            )}

            {isAssigneeModalOpen && (
              <AssigneeActivity
                setModelView={setIsAssigneeModalOpen}
                view={isAssigneeModalOpen}
                objectiveId={props.objectiveId}
                milestoneId={props.task.milestoneId}
                assigneeList={props.task.assigneeList}
                loadAgain={() => {
                  props.setIsRefreshObjective((old) => !old)
                }}
              />
            )}

            {isLinkActionToMeetingTopicModalOpen && (
              <LinkActionToMeetingTopicModal
                closeModal={() => {
                  setIsLinkActionToMeetingTopicModalOpen(false)
                }}
                actionId={props.task.milestoneId}
                objId={props.objectiveId}
                reloadBackground={() => {
                  props.setIsRefreshObjective((old) => !old)
                }}
                umtId={props.task.umtId > 0 ? props.task.umtId : null}
              />
            )}

            {isCardModalOpen && (
              <CardModal
                isModalOpen={isCardModalOpen}
                closeModal={() => {
                  setIsCardModalOpen(false)
                }}
                objectiveId={props.objectiveId}
                topicType={props.task.topicType}
                umtId={props.task.umtId}
              />
            )}

            {isSubactivityModalOpen && (
              <SubactivityModal
                closeModal={() => {
                  setIsSubactivityModalOpen(false)
                  setSelectedSubactivity(null)
                }}
                subactivity={selectedSubactivity}
                setIsRefreshObjective={props.setIsRefreshObjective}
              />
            )}
          </Container>
        )}
      </Draggable>
    </>
  )
}

export default Task

import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const ExpandableText = ({ text, renderToTheRight }) => {
  const { t } = useTranslation(['Common'])
  const [isExpanded, setIsExpanded] = useState(false)
  const [showToggle, setShowToggle] = useState(false)
  const textRef = useRef(null)

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight)
        const maxHeight = lineHeight * 3 // Three lines
        const isOverflowing = textRef.current.scrollHeight > maxHeight
        setShowToggle(isOverflowing)
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [text])

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={textRef}
        style={{
          display: '-webkit-box',
          WebkitLineClamp: isExpanded ? 'unset' : 3,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          wordBreak: 'break-word',
          position: 'relative',
          lineHeight: '1.5em',
          maxHeight: isExpanded ? 'none' : '4.5em', // 3 lines
          transition: 'max-height 0.3s ease',
        }}
      >
        {text}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {showToggle ? (
          <div
            style={{
              fontSize: '14px',
              color: '#3B82F6',
              cursor: 'pointer',
            }}
            onClick={toggleExpand}
            onMouseEnter={(e) => (e.target.style.color = '#1D4ED8')}
            onMouseLeave={(e) => (e.target.style.color = '#3B82F6')}
          >
            {isExpanded ? t('Common:commonButtons.seeLess') : t('Common:commonButtons.seeMore')}
          </div>
        ) : (
          <div></div>
        )}
        {renderToTheRight()}
      </div>
    </div>
  )
}

export default ExpandableText

import React from 'react'
import { Formik, Field, Form } from 'formik'
import EditAreaLeaderSchema from './formSchema'
import { useGovernanceContext } from '../../../context/governanceContext'
import { concatSchema, AddEditEmployeeSchema } from './formSchema'
import { postHttpRequest } from '../../../../../api/query/dynamicAPI'
import Toast from '../../../../../common/toast'
import { useTranslation } from 'react-i18next'
import ModalSaveButton from '../../../../../components/ModalSubmitButton'

const AddEditEmployeeForm = ({ setResetForm, setRefetchData }) => {
  const { state, dispatch } = useGovernanceContext()
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const [isLoading, setIsLoading] = React.useState(false)
  let returnData = []
  let deps = []
  if (state.editCeoLeaderIndex !== null) {
    returnData = state.data.ceoLeadersList[state.editCeoLeaderIndex]
    deps = [state.editCeoLeaderIndex, state.data.ceoLeadersList]
  } else if (state.editCsuiteLeaderIndex !== null) {
    returnData = state.data.csuiteLeadersList[state.editCsuiteLeaderIndex]
    deps = [state.editCsuiteLeaderIndex, state.data.csuiteLeadersList]
  } else if (state.editAreaLeaderIndex !== null) {
    returnData = state.data.areaLeadersList[state.editAreaLeaderIndex]
    deps = [state.editAreaLeaderIndex, state.data.areaLeadersList]
  } else if (state.editTeamLeaderIndex !== null) {
    returnData = state.data.teamLeadersList[state.editTeamLeaderIndex]
    deps = [state.editTeamLeaderIndex, state.data.teamLeadersList]
  } else if (state.editTeamMemberIndex !== null) {
    returnData = state.data.teamMembersList[state.editTeamMemberIndex]
    deps = [state.editTeamMemberIndex, state.data.teamMembersList]
  }
  const editEmployee = React.useMemo(() => {
    return returnData
  }, [deps])

  let validationSchema = AddEditEmployeeSchema
  // only add email constraint when it's adding new leaders
  if (
    state.editCeoLeaderIndex == null &&
    state.editAreaLeaderIndex === null &&
    state.editCsuiteLeaderIndex === null &&
    state.editTeamLeaderIndex === null &&
    state.editTeamMemberIndex === null
  ) {
    validationSchema = concatSchema()
  }

  const saveEditEmployee = async (values, level, eLeaderId) => {
    try {
      const url = '/save_edit_employee'
      values.level = level
      if (eLeaderId !== 0) {
        values.eLeaderId = eLeaderId
      }
      let response = await postHttpRequest(url, { employee: values })
      return response
    } catch (error) {
      return { success: false, e_id: null, error: error }
    }
  }

  const fireFailureToast = () => {
    Toast.fire({
      icon: 'error',
      title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
    })
  }

  const fireSuccessToast = () => {
    Toast.fire({
      icon: 'success',
      title: t('Common:modalMessages.updatedSuccessfully'),
    })
  }

  const handleSubmit = async (values) => {
    try {
      // debugger
      if (editEmployee !== null) {
        // ceo
        let level = 10
        let dispatchObj = {}
        let isAddNewLeader = false
        let eLeaderId = undefined
        let employeeToEdit = {
          ...editEmployee,
          name: values.name,
          email: values.email,
          title: values.title,
          function: values.function,
        }
        // ceo is selected for editing
        if (state.editCeoLeaderIndex !== null) {
          level = 10
          dispatchObj = {
            type: 'UPDATE_CEO_LEADER',
            ceoLeadersList: employeeToEdit,
            ceoLeaderIndex: state.editCeoLeaderIndex,
          }
        } else if (state.editCsuiteLeaderIndex !== null) {
          level = 1
          dispatchObj = {
            type: 'UPDATE_C_SUITE_LEADER',
            cSuiteLeadersList: employeeToEdit,
            cSuiteLeaderIndex: state.editCsuiteLeaderIndex,
            isCsuiteLeaderListUpdated: true,
          }
        } else if (state.editAreaLeaderIndex !== null) {
          level = 2
          dispatchObj = {
            type: 'UPDATE_AREA_LEADER',
            areaLeadersList: employeeToEdit,
            areaLeadeIndex: state.editAreaLeaderIndex,
            isAreaLeaderListUpdated: true,
          }
        } else if (state.editTeamLeaderIndex !== null) {
          // employeeToEdit.managesF2w = values.managesF2w == 'yes' ? true : false
          employeeToEdit.showWizards = values.showWizards == 'yes' ? true : false

          level = 3
          dispatchObj = {
            type: 'UPDATE_TEAM_LEADER',
            teamLeadersList: employeeToEdit,
            teamLeaderIndex: state.editTeamLeaderIndex,
            isTeamLeaderListUpdated: true,
          }
        } else if (state.editTeamMemberIndex !== null) {
          level = null
          dispatchObj = {
            type: 'UPDATE_TEAM_MEMBER',
            teamMembersList: employeeToEdit,
            teamMemberIndex: state.editTeamMemberIndex,
            isTeamMemberListUpdated: true,
          }
          // when no leaders are selected => adding new leaders
        } else {
          isAddNewLeader = true
          employeeToEdit.showSetupAgent = values.showSetupAgent == 'yes' ? true : false
          if (state.role === 'ceo') {
            level = 10
            dispatchObj = {
              type: 'ADD_CEO_LEADER',
              newLeader: employeeToEdit,
            }
          } else if (state.role === 'csuite') {
            level = 1
            eLeaderId = state.selectedCeoLeaderEId
            dispatchObj = {
              type: 'ADD_C_SUITE_LEADER',
              newLeader: employeeToEdit,
              isCsuiteLeaderListUpdated: false,
            }
          } else if (state.role === 'areaLeader') {
            level = 2
            eLeaderId = state.selectedCsuiteLeaderEId
            dispatchObj = {
              type: 'ADD_AREA_LEADER',
              newLeader: employeeToEdit,
              isAreaLeaderListUpdated: false,
            }
          } else {
            level = 3
            // employeeToEdit.managesF2w = values.managesF2w == 'yes' ? true : false
            employeeToEdit.showWizards = values.showWizards == 'yes' ? true : false

            eLeaderId = state.selectedAreaLeaderEId
            dispatchObj = {
              type: 'ADD_TEAM_LEADER',
              newLeader: employeeToEdit,
              isTeamLeaderListUpdated: false,
            }
          }
        }
        const { success, e_id, error } = await saveEditEmployee(employeeToEdit, level, eLeaderId)

        if (!success) {
          fireFailureToast()
        } else {
          if (isAddNewLeader) {
            // we need the new e_id to bind the new leader name with the id in the dropdown.
            dispatchObj.newLeader.eId = e_id
          }
          dispatch(dispatchObj)
          fireSuccessToast()
        }
      }
    } catch (e) {
      fireFailureToast()
    }
  }
  const formikRef = React.useRef()

  React.useEffect(() => {
    if (formikRef.current !== undefined) {
      setResetForm(() => formikRef.current.resetForm)
    }
  }, [formikRef])

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        name: editEmployee?.name ?? '',
        email: editEmployee?.email ?? '',
        title: editEmployee?.title ?? '',
        function: editEmployee?.function ?? '',
        // managesF2w: editEmployee?.managesF2w==true?'yes':'no',
        showWizards:
          editEmployee?.showWizards == true || editEmployee?.showWizards == undefined
            ? 'yes'
            : 'no',
        showSetupAgent: editEmployee?.showSetupAgent === true ? 'yes' : 'no',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        setIsLoading(true)
        await handleSubmit(values)
        setIsLoading(false)
        setRefetchData((prev) => !prev)
        resetForm()
      }}
    >
      {({ errors, touched, resetForm }) => (
        <Form className="form">
          <div className="form-field">
            <label htmlFor="name">Name</label>
            <Field id="name" name="name" />
            {errors.name && touched.name && <div className="validation-error">{errors.name}</div>}
          </div>

          {state.editCeoLeaderIndex == null &&
            state.editAreaLeaderIndex === null &&
            state.editCsuiteLeaderIndex === null &&
            state.editTeamLeaderIndex === null &&
            state.editTeamMemberIndex === null && (
              <div className="form-field">
                <label htmlFor="email">Email</label>
                <Field id="email" name="email" />
                {errors.email && touched.email && (
                  <div className="validation-error">{errors.email}</div>
                )}
              </div>
            )}

          <div className="form-field">
            <label htmlFor="title">Job Title</label>
            <Field id="title" name="title" />
            {errors.title && touched.title && (
              <div className="validation-error">{errors.title}</div>
            )}
          </div>

          {state.editTeamMemberIndex === null && (
            <div className="form-field">
              <label htmlFor="function">Function</label>
              <Field id="function" name="function" />
            </div>
          )}

          {/* Note: If condition is just iffy given the logic oh how the modal knows wether
           to edit or add and area leader or team leader, needs future re-write */}
          {/* {state.editCeoLeaderIndex ===null && state.editAreaLeaderIndex === null && state.editCsuiteLeaderIndex === null 
            && state.role !== 'csuite' && state.role !== 'areaLeader' && state.role !== 'ceo' && (
            <div className="form-field">
              <label htmlFor="managesF2w">Manages a flow to work pool?</label>
              <Field as="select" id="managesF2w" name="managesF2w">
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </Field>
              {errors.managesF2w && touched.managesF2w && (
                <div className="validation-error">{errors.managesF2w}</div>
              )}
            </div>
          )} */}

          {state.editCeoLeaderIndex === null &&
            state.editAreaLeaderIndex === null &&
            state.editCsuiteLeaderIndex === null &&
            state.editTeamMemberIndex === null &&
            state.role !== 'csuite' &&
            state.role !== 'areaLeader' &&
            state.role !== 'ceo' && (
              <div className="form-field" style={{ width: '80%' }}>
                <label htmlFor="showWizards" style={{ maxWidth: '236.59px' }}>
                  Receive Team Leader Wizard (for setup)?
                </label>
                <Field as="select" id="showWizards" name="showWizards">
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Field>
                {errors.showWizards && touched.showWizards && (
                  <div className="validation-error">{errors.showWizards}</div>
                )}
              </div>
            )}
          {state.editCeoLeaderIndex === null &&
            state.editAreaLeaderIndex === null &&
            state.editCsuiteLeaderIndex === null &&
            state.editTeamMemberIndex === null && (
              <div className="form-field" style={{ width: '80%' }}>
                <label htmlFor="showSetupAgent" style={{ maxWidth: '236.59px' }}>
                  Receive New AI Setup Agent?
                </label>
                <Field as="select" id="showSetupAgent" name="showSetupAgent">
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </Field>
                {errors.showSetupAgent && touched.showSetupAgent && (
                  <div className="validation-error">{errors.showSetupAgent}</div>
                )}
              </div>
            )}

          <div className="modal-dual-btn">
            <button
              className="dual-btn negative-btn"
              onClick={() => {
                resetForm()
                dispatch({ type: 'CLOSE_EMPLOYEE_MODAL' })
              }}
              type="button"
            >
              CANCEL
            </button>
            <ModalSaveButton isLoading={isLoading} />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddEditEmployeeForm

import React, { useEffect, useState } from 'react'
import BGUScoreCard from '.'
import { getHttpRequest } from '../../../../api/query/dynamicAPI'
import BGUTopicAgeTableModal from './component/BGUTopicAgeTableModal'
import BGUOpenCloseActionTableModal from './component/BGUOpenCloseActionTableModal'
import BGUActivityDuesTableModal from './component/BGUActivityDuesTableModal'
import Modal from '../../../../components/Modal'
import InPageLoader from '../../../../components/InPageLoader'

const BGUScoreCardModal = ({
  getLoadingStateFromChildComponent,
  visibility,
  loadAgain = () => {},
  eIdsToLoadBGU = [],
  selectedViewMode,
  closeModal,
  selectedMeetingId,
}) => {
  const [refreshBGUScorecard, setRefreshBGUScorecard] = useState(false)
  const [BGUScoreCardData, setBGUScoreCardData] = useState(null)

  const [isActivityModalOpen, setIsActivityModalOpen] = React.useState(false)
  const [isActionModalOpen, setIsActionModalOpen] = React.useState(false)
  const [isTopicModalOpen, setIsTopicModalOpen] = React.useState(false)
  const [selectedBar, setSelectedBar] = React.useState(null)
  const [isOpen, setIsOpen] = React.useState(null)
  const [activitiesDuesCategory, setActivitiesDuesCategory] = React.useState(null)

  // const { eIdsOfSelectedTls } = useOutletContext()

  const closeTestModal = () => {
    setIsActionModalOpen(false)
    setIsTopicModalOpen(false)
    setIsActivityModalOpen(false)
  }

  function getBGUScoreCard() {
    let url = ''
    url = eIdsToLoadBGU.length > 0 ? url + '/' + eIdsToLoadBGU.join(',') : url

    let evaluatedViewMode = selectedViewMode

    let params = {
      viewMode: evaluatedViewMode,
      meetingId: selectedMeetingId,
    }

    getHttpRequest(`/bgu_scorecard_response${url}`, { params: { ...params } }).then((response) => {
      const data = response
      if (data) {
        setBGUScoreCardData(data)
      } else {
      }
    })
  }

  useEffect(() => {
    getBGUScoreCard()
  }, [])

  return (
    <>
      <Modal width={'50vw'} closeModal={closeModal}>
        {isTopicModalOpen === true && selectedBar !== null && (
          <BGUTopicAgeTableModal
            data={selectedBar}
            isModalOpen={isTopicModalOpen}
            closeModal={closeTestModal}
            refreshBGUScorecardFn={() => {}}
            loadAgain={loadAgain}
            selectedViewMode={selectedViewMode}
            selectedMeetingId={selectedMeetingId}
          />
        )}

        {isActionModalOpen === true && selectedBar !== null && isOpen !== null && (
          <BGUOpenCloseActionTableModal
            data={selectedBar}
            isModalOpen={isActionModalOpen}
            closeModal={closeTestModal}
            refreshBGUScorecardFn={() => {}}
            loadAgain={loadAgain}
            eIdsOfSelectedTls={[1, 2]}
            isOpen={isOpen}
            selectedViewMode={selectedViewMode}
            selectedMeetingId={selectedMeetingId}
          />
        )}
        {BGUScoreCardData ? (
          <BGUScoreCard
            actionChartData={BGUScoreCardData}
            setIsActionModalOpen={setIsActionModalOpen}
            setIsActivityModalOpen={setIsActivityModalOpen}
            setIsTopicModalOpen={setIsTopicModalOpen}
            setSelectedBar={setSelectedBar}
            setIsOpen={setIsOpen}
            setActivitiesDuesCategory={setActivitiesDuesCategory}
            refreshBGUScorecardFn={() => {}}
            loadAgain={loadAgain}
            visibility={'visible'}
            getLoadingStateFromChildComponent={getLoadingStateFromChildComponent}
          />
        ) : (
          <InPageLoader />
        )}
      </Modal>
    </>
  )
}

export default BGUScoreCardModal

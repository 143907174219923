import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import Toast from '../../common/toast'

export async function getKpiList(objId) {
  try {
    const data = await getHttpRequest(`/get_kpi_list/${objId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      //title: API_ERROR_MESSAGE,
    })
  }
}

export async function getKpiUpdates(kpiId) {
  try {
    const data = await getHttpRequest(`/get_kpi_updates/${kpiId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      //title: API_ERROR_MESSAGE,
    })
  }
}

export async function addEditKpi(formData) {
  try {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    const data = await postHttpRequest(`/create_edit_kpi`, formData, config)

    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      //title: API_ERROR_MESSAGE,
    })
  }
}

export async function updateKpi(formData) {
  try {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    const data = await postHttpRequest(`/create_edit_kpi_update`, formData, config)

    return data
  } catch (e) {
    return e.response
  }
}

export async function deleteKpi(formData) {
  try {
    const data = await postHttpRequest(`/delete_kpi`, formData, {})

    return data
  } catch (e) {
    return e.response
  }
}

export async function completeKpi(formData) {
  try {
    const data = await postHttpRequest(`/complete_kpi`, formData, {})

    return data
  } catch (e) {
    return e.response
  }
}

export async function getKpiListGroupedByUserObjectives() {
  try {
    const data = await getHttpRequest(`/get_kpi_list_grouped_by_user_objectives`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      //title: API_ERROR_MESSAGE,
    })
  }
}

export async function updateKpiStatus(formData) {
  try {
    const data = await postHttpRequest(`/update_kpi_status`, formData, {})

    return data
  } catch (e) {
    return e.response
  }
}

export async function getKpiUpdateById(kpiUpdateId) {
  try {
    const data = await getHttpRequest(`/get_kpi_update_by_id/${kpiUpdateId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      //title: API_ERROR_MESSAGE,
    })
  }
}

export async function getObjRelatedPeople(objId) {
  try {
    const data = await getHttpRequest(`/get_people_related_to_obj?obj_id=${objId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      //title: API_ERROR_MESSAGE,
    })
  }
}

export async function getCompanyOverview() {
  try {
    const data = await getHttpRequest(`/get_company_overview`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {}
}

export async function getTeamOverview() {
  try {
    const data = await getHttpRequest(`/get_team_overview`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {}
}

export async function setCompanyOverview(params) {
  try {
    const data = await postHttpRequest(`/set_company_overview`, params, {})

    return data
  } catch (e) {
    return e.response
  }
}

export async function setTeamOverview(params) {
  try {
    const data = await postHttpRequest(`/set_team_overview`, params, {})

    return data
  } catch (e) {
    return e.response
  }
}

export async function setKpiWizardTimeframe(params) {
  try {
    const data = await postHttpRequest(`/set_kpi_wizard_timeframe`, params, {})

    return data
  } catch (e) {
    return e.response
  }
}

export async function setKpiWizardLongtermAnswer(params) {
  try {
    const data = await postHttpRequest(`/set_kpi_wizard_longterm_answer`, params, {})

    return data
  } catch (e) {
    return e.response
  }
}

export async function setKpiWizardShorttermAnswer(params) {
  try {
    const data = await postHttpRequest(`/set_kpi_wizard_shortterm_answer`, params, {})

    return data
  } catch (e) {
    return e.response
  }
}

export async function getSuggestedAndExistingKpisForObj(goalId) {
  try {
    const data = await getHttpRequest(`/get_suggested_and_existing_kpis_for_obj/${goalId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {}
}

export async function getLevelObjsWithKpiCount() {
  try {
    const data = await getHttpRequest(`/get_level_objs_with_kpi_count`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {}
}

export async function saveManyKpis(params) {
  try {
    const data = await postHttpRequest(`/save_many_kpis`, params, {})

    return data
  } catch (e) {
    return e.response
  }
}

import React, { useState, useEffect } from 'react'
import Modal from '../../Modal'
import { Formik, Field, Form } from 'formik'
import InPageLoader from '../../InPageLoader'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import SelectSingleField from '../../../components/CustomMultipleSelect/singleOption.jsx'
import { addEditKpi } from '../../../api/services/kpi.services'
import Toast from '../../../common/toast'
import { getObjRelatedPeople } from '../../../api/services/kpi.services'
import { ModalSaveButtonFitted } from '../../ModalSubmitButton'
import { getConsolidatedConfigSettings } from '../../../utils/auth'
import useGetAllActiveCompanyObj from '../../../api/query/useGetAllActiveCompanyObj'
import { getCurrentDate } from '../../../utils/general'

const AddEditKPIModal = ({ closeModal, mode, kpi, objId, reload }) => {
  //mode = "add" or "edit"
  const { t } = useTranslation(['Common'])
  const [isLoading, setIsLoading] = useState(false)
  const [objRelatedPeople, setObjRelatedPeople] = useState([])
  const showGovernanceMgmntFeatures =
    getConsolidatedConfigSettings('show_governance_mgmt_features') === true
  const { data: objList } = useGetAllActiveCompanyObj({ isFromDashBoardAndIsRegularTl: false })

  useEffect(() => {
    getObjRelatedPeopleList(objId)
  }, [])

  const kpiTypeList = [
    { label: t('kpi.percent'), value: 1 },
    { label: t('kpi.number'), value: 2 },
    { label: t('kpi.milestone'), value: 3 },
  ]

  const kpiFrequencyList = [
    { label: t('kpi.weekly'), value: 1 },
    { label: t('kpi.biweekly'), value: 2 },
    { label: t('kpi.monthly'), value: 3 },
  ]

  const addEditKpiModalSchema = (requiredMessage, requireNumber, startDateBeforeDueDate) => {
    return Yup.object().shape({
      name: Yup.string().required(requiredMessage),
      unit: Yup.string().when('type', {
        is: '3',
        then: Yup.string(),
        otherwise: Yup.string().required(requiredMessage),
      }),

      type: Yup.string().required(requiredMessage),
      frequency: Yup.string().required(requiredMessage),
      target: Yup.number().when('type', {
        is: '3',
        then: Yup.number(),
        otherwise: Yup.number().required(requiredMessage).typeError(requireNumber),
      }),
      targetDate: Yup.date().required(requiredMessage),
      owner: Yup.number().required(requiredMessage),
      startDate: Yup.date().test('is-before', startDateBeforeDueDate, function (value) {
        const { targetDate } = this.parent
        if (value && targetDate) {
          return new Date(value) < new Date(targetDate)
        }
        return true
      }),
    })
  }

  const validationSchema = addEditKpiModalSchema(
    t('Common:required'),
    t('Common:mustBeNumber'),
    t('Common:startAfterDueMsg'),
  )

  const handleSubmit = async (values) => {
    let formData = new FormData()
    formData.append('name', values.name)
    formData.append('kpiType', values.type)
    formData.append('frequency', values.frequency)
    if (values.type === '3') {
      formData.append('kpiTarget', 0)
    } else {
      formData.append('kpiTarget', values.target)
      formData.append('unit', values.unit)
    }
    formData.append('dueDate', formatDate(values.targetDate))
    formData.append('objId', objId)
    if (values.startDate !== '') {
      formData.append('startDate', formatDate(values.startDate))
    }

    formData.append('ownerEId', values.owner)
    if (mode === 'edit') {
      formData.append('changeObjective', values.changeObjective)
    }
    if (mode === 'edit') {
      formData.append('kpiId', kpi.kpiId)
    }

    let result = await addEditKpi(formData)
    setIsLoading(false)
    reload()
    if (result) {
      Toast.fire({
        icon: 'success',
        title: mode === 'add' ? t('kpi.kpiAddSuccess') : t('kpi.kpiEditSuccess'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  function formatDate(inputDate) {
    // Parse the input date string
    const dateParts = inputDate.split('-')
    const year = dateParts[0]
    const month = dateParts[1]
    const day = dateParts[2]

    // Create a new Date object with the corrected month
    const inputDateObject = new Date(year, month - 1, day)

    // Format the date as mm/dd/yy
    const formattedDate = `${(inputDateObject.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${inputDateObject.getDate().toString().padStart(2, '0')}/${inputDateObject
      .getFullYear()
      .toString()
      .slice(-2)}`

    return formattedDate
  }

  const formatDateForDateDropdown = (inputDate) => {
    // this is for possible empty start date
    if (!inputDate) {
      return ''
    }
    const inputDateObj = new Date(inputDate)
    const year = inputDateObj.getFullYear()
    const month = (inputDateObj.getMonth() + 1).toString().padStart(2, '0')
    const day = inputDateObj.getDate().toString().padStart(2, '0')
    const formattedDateString = `${year}-${month}-${day}`
    return formattedDateString
  }

  async function getObjRelatedPeopleList(kpiId) {
    setIsLoading(true)

    let result = await getObjRelatedPeople(kpiId)
    setIsLoading(false)
    if (result && result.employees) {
      setObjRelatedPeople(result.employees)
    }
  }

  return (
    <>
      <Modal
        style={{ zIndex: 103 }}
        width={'40rem'}
        title={mode === 'add' ? t('kpi.addKpi') : t('kpi.editKpi')}
        closeModal={closeModal}
      >
        {isLoading && <InPageLoader isLoading={isLoading} />}
        {!isLoading && (
          <Formik
            enableReinitialize
            initialValues={{
              name: mode === 'edit' ? kpi?.name : '',
              unit: mode === 'edit' ? kpi?.unit : '',
              type: mode === 'edit' ? kpi?.kpiType.toString() : '',
              frequency: mode === 'edit' ? kpi?.frequency : '',
              target: mode === 'edit' ? kpi?.target : '',
              targetDate: mode === 'edit' ? formatDateForDateDropdown(kpi?.targetDate) : '',
              owner: mode === 'edit' ? kpi?.ownerEId : '',
              changeObjective: objId,
              objId: objId,
              startDate: mode === 'edit' ? (kpi?.startDate ?? '') : getCurrentDate(),
              createdBy: mode === 'edit' ? kpi?.madeBy : '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values)
              closeModal(true)
              resetForm()
            }}
          >
            {({ errors, touched, resetForm, setFieldValue, values }) => (
              <Form className="objective-form">
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '40rem',
                    }}
                  >
                    <div className="form-field">
                      <label>
                        {t('kpi.name')} <span style={{ color: 'red' }}> *</span>
                      </label>
                      <Field
                        type="text"
                        maxLength="200"
                        id="name"
                        name="name"
                        autoComplete="off"
                        style={{ width: '20rem' }}
                      />
                      {errors.name && touched.name && (
                        <div className="validation-error">{errors.name}</div>
                      )}
                    </div>

                    <div className="form-field">
                      <label>
                        {t('kpi.type')} <span style={{ color: 'red' }}> *</span>
                      </label>

                      <Field
                        as="select"
                        id="type"
                        name="type"
                        style={{ width: '21rem', height: '2.5rem' }}
                        disabled={mode === 'edit' && kpi.numKpiUpdates > 0}
                      >
                        <option value=""></option>

                        {kpiTypeList?.map((item, goalIndex) => {
                          return (
                            <option key={`level1-${goalIndex}`} id={item.value} value={item.value}>
                              {item.label}
                            </option>
                          )
                        })}
                      </Field>

                      {errors.type && touched.type && (
                        <div className="validation-error">{errors.type}</div>
                      )}
                    </div>
                    {values.type !== '3' && (
                      <div className="form-field">
                        <label>
                          {t('kpi.unit')} <span style={{ color: 'red' }}> *</span>
                        </label>
                        <Field
                          type="text"
                          maxLength="200"
                          id="unit"
                          name="unit"
                          autoComplete="off"
                          style={{ width: '20rem' }}
                        />
                        {errors.unit && touched.unit && (
                          <div className="validation-error">{errors.unit}</div>
                        )}
                      </div>
                    )}

                    <div className="form-field">
                      <label>
                        {t('kpi.updateFrequency')} <span style={{ color: 'red' }}> *</span>
                      </label>

                      <Field
                        as="select"
                        id="frequency"
                        name="frequency"
                        style={{ width: '21rem', height: '2.5rem' }}
                      >
                        <option value=""></option>

                        {kpiFrequencyList?.map((item, goalIndex) => {
                          return (
                            <option key={`level1-${goalIndex}`} id={item.value} value={item.value}>
                              {item.label}
                            </option>
                          )
                        })}
                      </Field>
                      {errors.frequency && touched.frequency && (
                        <div className="validation-error">{errors.frequency}</div>
                      )}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', columnGap: '1rem' }}>
                      {values.type !== '3' && (
                        <div className="form-field">
                          <label>
                            {t('kpi.target')} <span style={{ color: 'red' }}> *</span>
                          </label>
                          <Field
                            type="text"
                            maxLength="200"
                            id="target"
                            name="target"
                            autoComplete="off"
                            style={{ width: '20rem' }}
                          />
                          {errors.target && touched.target && (
                            <div className="validation-error">{errors.target}</div>
                          )}
                        </div>
                      )}
                      <div className="form-field">
                        <label>
                          {t('kpi.startDate')} <span style={{ color: 'red' }}> *</span>
                        </label>
                        <Field name="startDate" type="date" style={{ width: '20rem' }}></Field>
                        {errors.startDate && touched.startDate && (
                          <div className="validation-error">{errors.startDate}</div>
                        )}
                      </div>

                      <div className="form-field">
                        <label>
                          {t('kpi.reachTargetBy')} <span style={{ color: 'red' }}> *</span>
                        </label>
                        <Field name="targetDate" type="date" style={{ width: '20rem' }}></Field>
                        {errors.targetDate && touched.targetDate && (
                          <div className="validation-error">{errors.targetDate}</div>
                        )}
                      </div>
                    </div>

                    <div className="form-field">
                      <label>
                        {t('kpi.owner')} <span style={{ color: 'red' }}> *</span>
                      </label>

                      <Field
                        as="select"
                        id="owner"
                        name="owner"
                        style={{ width: '21rem', height: '2.5rem' }}
                      >
                        <option value=""></option>

                        {objRelatedPeople?.map((item, goalIndex) => {
                          return (
                            <option key={`level1-${goalIndex}`} id={item.eId} value={item.eId}>
                              {item.name}
                            </option>
                          )
                        })}
                      </Field>
                      {errors.owner && touched.owner && (
                        <div className="validation-error">{errors.owner}</div>
                      )}
                    </div>
                    {mode === 'edit' && (
                      <div className="form-field">
                        <label>{t('kpi.createdBy')}</label>

                        <Field
                          type="text"
                          maxLength="200"
                          id="createdBy"
                          name="createdBy"
                          autoComplete="off"
                          style={{ width: '20rem' }}
                          disabled={true}
                        />
                      </div>
                    )}
                    {mode === 'edit' && showGovernanceMgmntFeatures && (
                      <div className="form-field">
                        <label>{t('kpi.changeObjective')}</label>
                        <Field
                          as="select"
                          id="changeObjective"
                          name="changeObjective"
                          style={{ width: '21rem', height: '2.5rem' }}
                        >
                          <option value=""></option>

                          {objList?.objectives?.map((item, index) => {
                            if (!item.isGeneralObjective) {
                              return (
                                <option key={index} id={item.id} value={item.id}>
                                  {item.statement}
                                </option>
                              )
                            }
                          })}
                        </Field>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="modal-dual-btn"
                  style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
                >
                  <ModalSaveButtonFitted
                    isLoading={isLoading}
                    tag={t('Common:commonButtons.cancel')}
                    onClick={() => {
                      resetForm()
                      closeModal()
                    }}
                  />
                  <ModalSaveButtonFitted
                    isLoading={isLoading}
                    id="uw-kpi-update-modal-update-btn"
                    tag={t('Common:commonButtons.save')}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  )
}

export default AddEditKPIModal

import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Toast from '../../../../common/toast'
import {
  meetingCommentAdd,
  meetingMilestonAdd,
  getSingleMeetingTopicDetails,
  useUpdateMeetingLogs,
} from '../../../../api/services/meetingTopic.services'
import Card from '../../../MeetingMultiView/components/MeetingTopics/common/Card'
import { usePdf } from '@mikecousins/react-pdf'
import './popup.scss'
import { useTranslation } from 'react-i18next'
import { getConsolidatedConfigSettings, hasRole } from '../../../../utils/auth'
import {
  meetingMultiViewSubRoutes,
  routes,
  // teamMeetingViewSubRoutes,
} from '../../../../utils/routes'
import { getObjectivUserList } from '../../../../api/services/employee.services'
import { useMeetingRecording } from '../../../../utils/meetingRecordingContext/meetingRecordingContext'
import MeetingTranscription from '../../../../components/MeetingTranscription'

const MeetingDeck = () => {
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()

  const { umtId, topicType } = location.state
  const { t } = useTranslation(['Common', 'MeetingHub'])

  const [objData, setObjData] = useState(null)
  const [currentSelection, setCurrentSelection] = useState([])
  const [page, setPage] = useState(1)
  const canvasRef = useRef(null)
  const [scale, setScale] = useState(1.0) // Initial scale
  const initialScale = useRef(1.0)
  const [loadPdf, setLoadPdf] = useState(false)
  const [userList, setUserList] = React.useState([])
  const { state, dispatch, updateTopicFocusFn } = useMeetingRecording()
  const canUseMeetingSummarization =
    getConsolidatedConfigSettings('can_use_meeting_summarization') === true

  const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
  //const BASE_URL = 'http://localhost:8000'

  const pdf_Url =
    BASE_URL +
    '/get_meeting_deck' +
    '?umtId=' +
    umtId +
    '&topicType=' +
    (topicType != 'undefined' ? topicType : 1)

  const { pdfDocument, pdfPage } = usePdf({
    file: pdf_Url ? pdf_Url : 'sample.pdf',
    page,
    canvasRef,
    scale: scale,
    onPageLoadSuccess: (p) => {
      console.log('page used scale and page', scale, page)
    },
  })

  const [flag, setFlag] = useState(false)
  const [resolved, setResolved] = useState(false)
  const readOnly = location.state?.isReadOnly

  const handleKeyDown = (event) => {
    switch (event.keyCode) {
      // Left arrow key
      case 37:
        setPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage))
        break

      // Right arrow key
      case 39:
        if (page < pdfDocument.numPages) setPage((prevPage) => prevPage + 1)
        break

      default:
        break
    }
  }

  useEffect(() => {
    // Attach the event listener to the document
    document.addEventListener('keydown', handleKeyDown)

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown]) // `handleKeyDown` in dependency array ensures it gets the latest state/hooks.

  const getAllEmployees = async () => {
    const response = await getObjectivUserList(0)
    if (response?.employees)
      setUserList(response.employees.map((emp) => ({ display: emp.name, id: emp.eId })))
  }

  useEffect(() => {
    if (location.state) {
      getScreenData()
      getAllEmployees()
    }
  }, [location.state])

  useEffect(() => {
    if (pdfPage && canvasRef) {
      const pageViewport = pdfPage.getViewport({ scale: 1 })
      const pdfWidth = pageViewport.width

      const containerWidth = document.querySelector('.slide-container').clientWidth

      const scale = containerWidth / pdfWidth
      console.log('scale', scale)
      if (initialScale.current == 1.0) {
        initialScale.current = scale
      }
      console.log('initialScale', initialScale.current)
      console.log(
        'scale > (initialScale.current ?? 1) * (1.1 ** 5): ',
        scale > (initialScale.current ?? 1) * 1.1 ** 5,
      )
      setScale(scale)
      setLoadPdf(true)
    }
  }, [pdfPage])

  async function getScreenData() {
    const postObj = { umtId: umtId, topicType: topicType }
    const result = await getSingleMeetingTopicDetails(postObj)
    setIsLoading(false)
    if (result) {
      if (result?.blockedObjectives.length > 0) {
        setObjData(result?.blockedObjectives[0])
        setCurrentSelection(result?.blockedObjectives)
        setFlag(result?.blockedObjectives[0]?.isPriority)
        setResolved(!result?.blockedObjectives[0]?.status)
      } else if (result?.decisionObjectives.length > 0) {
        setObjData(result?.decisionObjectives[0])
        setCurrentSelection(result?.decisionObjectives)
        setFlag(result?.decisionObjectives[0]?.isPriority)
        setResolved(!result?.decisionObjectives[0]?.status)
      } else if (result?.updateObjectives.length > 0) {
        setObjData(result?.updateObjectives[0])
        setCurrentSelection(result?.updateObjectives)
        setFlag(result?.updateObjectives[0]?.isPriority)
        setResolved(!result?.updateObjectives[0]?.status)
      } else if (result?.adminTopicObjectives.length > 0) {
        setObjData(result?.adminTopicObjectives[0])
        setCurrentSelection(result?.adminTopicObjectives)
        setFlag(result?.adminTopicObjectives[0]?.isPriority)
        setResolved(!result?.adminTopicObjectives[0]?.status)
      } else {
        closeMeetingDeckView()
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  async function createActivity(data, comment, index, mentions = []) {
    const formData = {
      milestoneDesc: comment,
      goalId: data?.objectiveId,
      mentions: mentions.map((mention) => mention.id),
    }

    formData.umtId = data?.umtId

    // setIsLoading(true);
    const result = await meetingMilestonAdd(formData)
    setIsLoading(false)
    if (result) {
      getScreenData()
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.actionUpdatedSuccesfully'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  async function createComment(data) {
    // setIsLoading(true);
    const result = await meetingCommentAdd(data)
    setIsLoading(false)
    if (result) {
      getScreenData()
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.commentUpdatedSuccessfully'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const mutationUpdateMeetingLogs = useUpdateMeetingLogs()

  async function createFlagStatus(data, type) {
    const formData = {}
    if (type == 'flag') {
      formData.isPriority = !data?.isPriority
    } else if (type == 'status') {
      formData.status = !data?.status
    }

    formData.umtId = data?.umtId

    await mutationUpdateMeetingLogs.mutateAsync(formData, {
      onSuccess: () => {
        getScreenData()
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }
  const closeMeetingDeckView = () => {
    const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5

    const redirectTo = location.state?.redirectTo
    let targetUrl

    if (redirectTo === 'kanban') {
      targetUrl = `/${routes.kanban}`
    } else if (redirectTo === 'decisionLog') {
      if (hasRole('team') && !isDelegateTl) {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.decisionLog}`
      } else {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.decisionLog}`
      }
    } else if (redirectTo === 'myDecisionHistory') {
      if (hasRole('team') && !isDelegateTl) {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.myDecisions}`
      } else {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.myDecisions}`
      }
    } else if (redirectTo === 'meetingTopics') {
      if ((hasRole('team') || hasRole('ic')) && !isDelegateTl) {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.meetingTopics}`
      } else {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.meetingTopics}`
      }
    } else if (redirectTo === 'home') {
      targetUrl = `/${routes.home}`
    }

    const filters = {
      readOnly: readOnly,
    }

    navigate(targetUrl, { state: { data: filters } })
  }

  const updateCardRecordingState = async (umtId, objId, newValue, newState) => {
    dispatch({
      type: 'SET_CARD_RECORDING_STARTED',
      umtId: umtId,
      newValue: newValue,
    })
    console.log(state)
    await updateTopicFocusFn(umtId, objId, newState, currentSelection, getScreenData)
  }

  return (
    <div className="slider-wrapper">
      <div className="slide-container" style={{ overflow: 'auto' }}>
        <div>
          {pdfDocument ? (
            <>
              {!pdfDocument && <span>Loading...</span>}
              {Boolean(pdfDocument && pdfDocument.numPages) && (
                <>
                  <nav>
                    <ul className="pager">
                      <li className="scale" style={{ display: 'flex', gap: '1rem' }}>
                        <button
                          disabled={scale > (initialScale.current ?? 1) * 1.1 ** 3}
                          onClick={() => setScale((prev) => prev * 1.1)}
                          className="fitted-button blue"
                        >
                          <span className="material-symbols-outlined icon-size">
                            <span class="material-symbols-outlined icon-size-l">zoom_in</span>{' '}
                          </span>
                        </button>
                        <button
                          disabled={scale <= (initialScale.current ?? 1)}
                          onClick={() => setScale((prev) => (prev * 1) / 1.1)}
                          className="fitted-button blue"
                        >
                          <span className="material-symbols-outlined icon-size">
                            <span class="material-symbols-outlined icon-size-l">zoom_out</span>{' '}
                          </span>
                        </button>
                        <button
                          disabled={page === 1}
                          onClick={() => setPage(page - 1)}
                          className="fitted-button blue"
                        >
                          <span style={{ width: '0.4rem' }}></span>
                          <span className="material-symbols-outlined icon-size">
                            <span class="material-symbols-outlined">arrow_back_ios</span>{' '}
                          </span>
                        </button>
                      </li>
                      <li className="next" style={{ display: 'flex', gap: '1rem' }}>
                        <button
                          disabled={page === pdfDocument.numPages}
                          onClick={() => setPage(page + 1)}
                          className="fitted-button blue"
                        >
                          <span style={{ width: '0.4rem' }}></span>
                          <span className="material-symbols-outlined icon-size">
                            <span class="material-symbols-outlined">arrow_forward_ios</span>{' '}
                          </span>
                        </button>

                        <button
                          onClick={() => closeMeetingDeckView()}
                          className="fitted-button blue"
                        >
                          <span className="material-symbols-outlined icon-size">
                            <span class="material-symbols-outlined">close</span>{' '}
                          </span>
                        </button>
                      </li>
                    </ul>
                  </nav>
                  {loadPdf && <canvas ref={canvasRef} />}
                </>
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      {!location.state?.fromKanban && (
        <div className="comments-wrapper">
          {objData && (
            <Card
              data={objData}
              createFlagStatus={createFlagStatus}
              flag={flag}
              createComment={createComment}
              createActivity={createActivity}
              showFull={false}
              loadAgain={getScreenData}
              refreshBGUScorecardFn={() => {}}
              resolved={resolved}
              viewOnlyMode={readOnly}
              flagStatusLoading={
                mutationUpdateMeetingLogs.isLoading || mutationUpdateMeetingLogs.isFetching
              }
              userList={userList}
              updateCardRecordingState={updateCardRecordingState}
              isPresentationMode={true}
            />
          )}
          {canUseMeetingSummarization && state.hasSummarizationStarted && (
            <div
              style={{
                border: '2px solid rgba(128, 128, 128, 0.24)',
                width: '17rem',
                height: '18rem',
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    display: 'flex',
                    paddingRight: '1rem',
                    paddingTop: '0.3rem',
                    paddingLeft: '0.5rem',
                    fontSize: '13px',
                    color: '#949494',
                  }}
                >
                  {state.hasSummarizationStarted
                    ? t('MeetingHub:transcript.summarizingMeetingInProgress')
                    : t('MeetingHub:transcript.startSummarizingMeeting')}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  // paddingLeft:'1rem',
                  textAlign: 'left',
                  marginLeft: '1rem',
                  marginTop: '1rem',
                  marginBottom: '0.3rem',
                  color: '#5780ca',
                }}
              >
                {t('MeetingHub:transcript.title')}
              </div>
              <div className="divider"></div>
              <MeetingTranscription />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default MeetingDeck

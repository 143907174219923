import React, { useState, useRef } from 'react'
import './customNode.scss'
import ActivityIcon from '../../../assets/activity.svg'
import MessageIcon from '../../../assets/message.svg'
import ZoomIcon from '../../../assets/zoom.svg'
import ZoomOutIcon from '../../../assets/zoomOut.svg'
import ObjMapDropdownMenu from './objMapDropdownMenu'
import { getConsolidatedConfigSettings } from '../../../utils/auth'
import './styles.scss'
import { useMeetingRecording } from '../../../utils/meetingRecordingContext/meetingRecordingContext'
import { useTranslation } from 'react-i18next'

const handleStyle = { left: 10 }

function TopicNode({ data }) {
  const { t } = useTranslation(['Common', 'ObjectiveMap', 'MeetingHub'])
  const { state, dispatch, updateTopicFocusFn } = useMeetingRecording()
  const latestStateRef = useRef(state)

  // Update the ref whenever the state changes
  React.useEffect(() => {
    latestStateRef.current = state
  }, [state])

  // Default hideDropdown to false
  const hideDropdown = data.hideDropdown || false
  const getBackgroundColor = (topicType) => {
    const colors = {
      0: '#F6aAB6C',
      1: 'rgb(237, 82, 46)',
      2: 'blue',
      3: 'gray',
      4: '#9933FF',
    }
    return colors[topicType] || 'transparent'
  }
  const [actionCount, setActionCount] = useState(0)
  const [decisionCount, setDecisionCount] = useState(0)

  React.useEffect(() => {
    if (state.topicIdCommentDecisionActionCount) {
      for (let key in latestStateRef.current.topicIdCommentDecisionActionCount
        .topic_id_action_count_obj) {
        if (parseInt(key) === parseInt(data.umtId)) {
          if (
            latestStateRef.current.topicIdCommentDecisionActionCount.topic_id_action_count_obj[
              key
            ] > 0
          ) {
            setActionCount(
              latestStateRef.current.topicIdCommentDecisionActionCount.topic_id_action_count_obj[
                key
              ],
            )
          }
        }
      }
      for (let key in latestStateRef.current.topicIdCommentDecisionActionCount
        .topic_id_decision_count_obj) {
        if (parseInt(key) === parseInt(data.umtId)) {
          if (
            latestStateRef.current.topicIdCommentDecisionActionCount.topic_id_decision_count_obj[
              key
            ] > 0
          ) {
            setDecisionCount(
              latestStateRef.current.topicIdCommentDecisionActionCount.topic_id_decision_count_obj[
                key
              ],
            )
          }
        }
      }
    }
  }, [latestStateRef.current.topicIdCommentDecisionActionCount])

  return (
    <div className="nodrag text-updater-node">
      <div className={`custom-box-node topic-box`}>
        <ul className="node-text-list">
          <li className="single-node" style={{ marginBottom: '0.2rem' }}>
            <div
              className="topic-blocker"
              style={{ backgroundColor: getBackgroundColor(data.topicType) }}
              onClick={() => data.ShowCardTopicModal(data.umtId, data.topicType)}
            >
              {data.topicType === 1
                ? 'Blocker'
                : data.topicType === 2
                  ? 'Guidance'
                  : data.topicType === 3
                    ? 'Update'
                    : data.topicType === 4
                      ? 'admin'
                      : 'Topic'}
            </div>
            <p style={{ overflow: 'auto', height: '2rem' }}>{data.description}</p>
          </li>
        </ul>
        <div className="icons-list-node-box" style={{ justifyContent: 'space-between' }}>
          {latestStateRef.current.isGeneratingActionsCommentsFromRecording[data?.umtId] ? (
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                height: '100%',
                margin: 'auto 0',
                justifyContent: 'flex-start',
              }}
            >
              <div
                className="loading-spinner"
                style={{ width: '0.6rem', height: '0.6rem', marginLeft: '5px' }}
              ></div>
            </div>
          ) : (
            data.isReviewMode && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  gap: '0.5rem',
                  alignItems: 'center',
                }}
              >
                {state.isCardRecordingStarted[data.umtId] ? (
                  <div
                    className="circle-container"
                    onClick={() => data.HandleCardFocus(data)}
                    style={{
                      cursor: 'pointer',
                      margin: 'auto 0px auto 5px',
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <div className="circle"></div>
                    <div className="icon-container">
                      <span
                        className="material-symbols-outlined icon-size"
                        style={{ fontSize: '15px' }}
                      >
                        speech_to_text
                      </span>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: 'auto 0px auto 5px',
                    }}
                  >
                    <button
                      type="button"
                      className="mic-button tonedown-blue-btn"
                      onClick={() => data.HandleCardFocus(data)}
                      style={{ width: '20px', height: '20px' }}
                    >
                      <span
                        className="material-symbols-outlined icon-size"
                        style={{ fontSize: '15px' }}
                      >
                        speech_to_text
                      </span>
                    </button>
                  </div>
                )}
                {actionCount > 0 && (
                  <div className="action-decision-count-box tooltip">
                    <span>{actionCount}</span>
                    <span>A</span>
                    <span class="tooltiptext">
                      + &nbsp; {actionCount} &nbsp;
                      {t('ObjectiveMap:tooltip.action')}{' '}
                    </span>
                  </div>
                )}
                {decisionCount > 0 && (
                  <div className="action-decision-count-box tooltip">
                    <span>{decisionCount}</span>
                    <span>D</span>
                    <span class="tooltiptext">
                      + &nbsp; {decisionCount} &nbsp;
                      {t('ObjectiveMap:tooltip.decision')}{' '}
                    </span>
                  </div>
                )}
              </div>
            )
          )}
          {data?.kpiName ? (
            <li className="fitted-button blue" style={{ height: '1rem' }}>
              <div class=" tooltip">
                <span class="material-symbols-outlined icon-size-sm">query_stats</span>
                <span class="tooltiptext">{data?.kpiName}</span>
              </div>
            </li>
          ) : (
            <div></div>
          )}
          {data.isReviewMode && (
            <div
              style={{
                //width: '100%',
                alignSelf: 'flex-end',
                gap: '0.313rem',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '0 0.1rem .313rem 0.1rem',
                padding: '0',
              }}
            >
              <ObjMapDropdownMenu
                topicMode={true}
                ShowEditTopicModal={() => {
                  data.ShowEditTopicModal(data)
                }}
                ShowCardTopicModal={() => {
                  data.ShowCardTopicModal(data.umtId, data.topicType)
                }}
                ShowDeleteTopicModal={() => {
                  data.ShowDeleteTopic(data.umtId)
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TopicNode

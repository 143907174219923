import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/logo_new.png'
import {
  getCompanyConfigSettings,
  getConsolidatedConfigSettings,
  getHasSetupWizardAction,
  hasRole,
} from '../../utils/auth.js'
import { routes } from '../../utils/routes'

const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

const Landing = () => {
  const navigate = useNavigate()

  let currentUser = localStorage.getItem('tfmu') || null
  let userRoles = localStorage.getItem('tfmp') || null
  let setup = localStorage.getItem('setup') || null
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false

  const enableSetupAgent = getConsolidatedConfigSettings('enable_setup_agent') === true
  const productSettings = getCompanyConfigSettings('productSettings')

  React.useEffect(() => {
    if (currentUser !== null && userRoles !== null && setup !== null) {
      localStorage.setItem('hrefReason_5B', '0x5B')

      if (hasRole('ic')) {
        productSettings ? navigate('/Kanban') : navigate('/SummaryOfObjectives')
      } else if (getHasSetupWizardAction() && enableSetupAgent) {
        navigate(`/${routes.setupIntroScreen}`, {
          state: {
            teamLeaderIntro: true,
          },
        })
      } else if (
        hasRole('cxo') ||
        hasRole('area') ||
        hasRole('ceo') ||
        hasRole('cos') ||
        (hasRole('team') && isDelegateTl)
      ) {
        navigate('/MeetingMultiView')
      } else if (hasRole('team')) {
        navigate('/MeetingMultiView')
      } else if (hasRole('enterprise')) {
        navigate('/Governance')
      } else {
        navigate('/ObjectiveMap')
      }
    }
  }, [navigate])

  localStorage.removeItem('logoutReason')

  return (
    <div>
      <center>
        <div style={{ marginBottom: '4rem' }}></div>
        <img src={logo} style={{ width: '300px' }}></img>

        <h1>Decision+</h1>
        <br />
        <a href={`${BASE_URL}/f/login`}>
          <div
            style={{
              width: '100px',
              padding: '20px',
              background: 'rgb(237, 82, 46)',
              color: '#fff',
              fontSize: '20px',
              fontWeight: 'bold',
            }}
          >
            Login
          </div>
        </a>

        {localStorage.getItem('logoutReason') === 'timeout' && (
          <div id="logoutReason">
            <br />
            <em>You were logged out due to inactivity. Please login again.</em>
          </div>
        )}
      </center>
    </div>
  )
}

export default Landing

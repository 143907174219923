import * as Yup from 'yup'

export const AddEditEmployeeSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  title: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
})

const emailSchemaObject = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
})

export const concatSchema = () => {
  return AddEditEmployeeSchema.concat(emailSchemaObject)
}

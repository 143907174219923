import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from '../../../../../common/toast'
import { postHttpRequest, getHttpRequest } from '../../../../../api/query/dynamicAPI'

const TranscriptSummary = ({ meetingSummary, meetingId, reload }) => {
  const { t } = useTranslation(['Common'])
  const [hasEditChanges, setHasEditChanges] = React.useState(false)
  const [mode, setMode] = React.useState('read')
  const [summaryText, setSummaryText] = React.useState('')

  React.useEffect(() => {
    // Decode the string and replace encoded characters
    if (meetingSummary) {
      const decodedSummary = decodeURIComponent(
        meetingSummary?.replace(/\\x([0-9A-Fa-f]{2})/g, '%$1'),
      ).replace(/\\n/g, '\n')
      setSummaryText(decodedSummary)
    }
  }, [meetingSummary, mode])

  const handleChange = (e) => {
    setHasEditChanges(true)
    setSummaryText(e.target.value)
  }

  const textareaStyle = {
    width: '100%',
    height: '18rem',
    padding: '8px',
    marginLeft: '1.5rem',
    marginTop: '1rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
    resize: 'none',
  }

  const saveEditedNotes = async () => {
    // Encode newlines as '\n' before saving
    const encodedSummary = summaryText.replace(/\n/g, '\\n')

    try {
      let response = await postHttpRequest(`/meeting/edit_current_summary/${meetingId}`, {
        currentSummary: encodedSummary,
      })
      if (response.success === true) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.updatedSuccessfully'),
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }

    reload()
    setHasEditChanges(false)
  }

  const summaryPoints = summaryText.split('\n').filter((point) => point.trim() !== '')

  return (
    <div className="meeting-transcription-body">
      <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', paddingLeft: '1.5rem' }}>
        {
          <div
            className="fitted-button blue"
            onClick={() => {
              if (mode === 'edit') {
                setMode('read')
              } else {
                setMode('edit')
              }
            }}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {mode === 'read' && <span className="material-symbols-outlined">edit</span>}
            {mode === 'edit' && <span className="material-symbols-outlined">arrow_back</span>}
          </div>
        }
        {hasEditChanges && mode === 'edit' && (
          <div
            className="fitted-button blue"
            style={{
              height: 'unset',
              alignItems: 'center',
            }}
            onClick={saveEditedNotes}
          >
            <span className="material-symbols-outlined">save</span>
          </div>
        )}
      </div>

      {mode === 'read' && (
        <ul>
          {summaryPoints.map((point, index) => (
            <li key={index} style={{ marginTop: '0.5rem' }}>
              <span style={{ fontSize: '14px', marginLeft: '-3px' }}>{point}</span>
            </li>
          ))}
        </ul>
      )}

      {mode === 'edit' && (
        <textarea style={textareaStyle} value={summaryText} onChange={handleChange} />
      )}
    </div>
  )
}

export default TranscriptSummary

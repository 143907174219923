import React, { useState, useEffect, useRef, Fragment } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import Column from './common/column'
import { throttle } from 'lodash'
import { Link, Navigate } from 'react-router-dom'
import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'
import { POST_KANBAN_BOARD_CHANGE_ACTIVITY_STATUS } from '../../api/constant/route'

import { Container, KanbanHeader, KanbanTitle, CreateTaskButton } from './style'
import SuggestedActivitiesModal from '../../components/SuggestedActivitiesModal'
import useAddManySuggestedActivities from '../../api/mutation/addManySuggestedActivities'
import AddMultipleActivitiesModal from '../../components/AddMultipleActivitiesModal'
import CommentsModal2 from '../../components/CommentsModal2'
import ErrorModal from '../../components/ErrorModal'
import ObjectiveActivityChartsModal from '../../components/ObjectiveActivityChartsModal'
import { useNavigate } from 'react-router-dom'
import { useFeatureFlagsContext } from '../../utils/featureFlagsContext'

import AddEditActivityModal from '../../components/AddEditActivityModal'
import { getCompanyConfigSettings, hasRole } from '../../utils/auth'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { getConsolidatedConfigSettings } from '../../utils/auth'
import Toast from '../../common/toast'
import ObjectiveDetailsModal from '../MeetingMultiView/components/MeetingTopics/common/component/OjbectiveDetailsModal'

import fileDownload from 'js-file-download'
import { getExportSepreadsheet } from '../../api/services/spreadsheet.srvices'
import KanbanMainBacklog from './component/KanbanBacklog'
import LinkActionToMeetingTopicModal from '../../components/LinkActionToMeetingTopicModal'
import KanbanAddEditObjectiveModal from '../../components/AddEditObjectiveModal/kanbanAddEditObjectiveModal'
import TagsManagementModal from '../../components/TagsManagementModal'
import GanttChartModal from '../../components/GanttChartModal/ganttChartModal'

function KanbanMain(props) {
  const { t } = useTranslation(['Kanban'])
  const productSettings = getCompanyConfigSettings('productSettings')
  const [state, setState] = useState('')
  const [objectiveId, setObjectiveId] = useState(props?.objective?.id)

  const [tasks, setTasks] = useState([])
  const [isSingleActivityModalOpen, setIsSingleActivityModalOpen] = useState(false)

  const [isSuggestedActivitiesModalOpen, setIsSuggestedActivitiesModalOpen] = useState(false)
  const [isMultipleActivitiesModalOpen, setisMultipleActivitiesModalOpen] = useState(false)
  const [isRefreshObjective, setIsRefreshObjective] = useState(false)
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [isObjectiveActivityChartsModalOpen, setIsObjectiveActivityChartsModalOpen] =
    useState(false)
  const [buttonClicked, setButtonClicked] = useState(false)

  const [isLinkActionToMeetingTopicModalOpen, setIsLinkActionToMeetingTopicModalOpen] =
    useState(false)
  const [isManageTagsModalOpen, setIsManageTagsModalOpen] = useState(false)
  const [linkActionActionId, setLinkActionActionId] = useState('')
  const [umtId, setUmtId] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [dueDate, setDueDate] = useState(props?.objective?.dueDateStr ?? '')
  const [startDate, setStartDate] = useState(props?.objective?.startDateStr ?? '')
  const firstRender = useRef(true)
  const [isObjDetailsOpen, setIsObjDetailsOpen] = useState(false)
  const [isKanbanBacklogOpen, setIsKanbanBacklogOpen] = useState(false)
  const [isAddEditObjectiveModalOpen, setIsAddEditObjectiveModalOpen] = useState(false)
  const [editedObj, setEditedObj] = useState({})
  const [isGanttChartModalOpen, setIsGanttChartModalOpen] = React.useState(false)

  useEffect(() => {
    ;(async () => {
      if (!firstRender.current) {
        const postObj = {
          objectiveId: props?.objective.id,
          startDate: startDate,
          dueDate: dueDate,
        }
        await postHttpRequest('/update_objective_date', postObj)
      } else {
        firstRender.current = false
      }
    })()
  }, [startDate, dueDate])

  React.useEffect(() => {
    if (editedObj.statement && editedObj.objId) {
      let tempData = JSON.parse(JSON.stringify(props.data))
      tempData.objectives.find((obj) => parseInt(obj.id) === parseInt(editedObj.objId)).statement =
        editedObj.statement
      props.setData(tempData)
    }
    if (editedObj.tags && editedObj.objId) {
      let tempData = JSON.parse(JSON.stringify(props.data))
      tempData.objectives.find((obj) => parseInt(obj.id) === parseInt(editedObj.objId)).tags =
        editedObj.tags
      props.setData(tempData)
      if (props.setGetTagsListUpdated) {
        props.setGetTagsListUpdated(true)
      }
    }
  }, [editedObj])

  const handleClick = () => {
    setButtonClicked(true)
  }

  const handleOpenTagsModal = () => {
    setIsManageTagsModalOpen(true)
  }

  const { flags } = useFeatureFlagsContext()
  const backgroundColor =
    props?.objective.originalId !== 0 && flags.enable_combined_objs === 'true'
      ? 'rgb(192, 192, 192)'
      : 'rgb(127, 127, 127)'
  const objIdsToShowTitle = props?.objIdsToShowTitle
  const navigate = useNavigate()

  const [isProces, setIsProces] = useState(false)

  const currentUser = localStorage.getItem('tfmu')
  const demoUser = localStorage.getItem('dMd') === 'true'
  const templateFeatureUsers = [
    'Sasha@transforml.co',
    'david.heidelberger@voya.com',
    'Liz.Wieser@voya.com',
    'Elizabeth.Wieser@voya.com',
    'krista.snow@voya.com',
    'amy.hall@voya.com',
  ]

  const showNewTemplateFeatures =
    demoUser ||
    templateFeatureUsers.some((elem) => currentUser.toLowerCase() === elem.toLowerCase())

  const hideAddActivityBt = JSON.parse(localStorage.getItem('tfmp')).includes('cxo')

  const disableAiConfig = getCompanyConfigSettings('disableAi')
  const l2UserCanSeeL2InKanban = getConsolidatedConfigSettings('l2_user_can_see_l2_in_kanban')
  const allowAddingL2Activities = getConsolidatedConfigSettings('allow_adding_l2_activities')

  React.useEffect(() => {
    if (!props.isObjectiveDataLoading) {
      getObjectiveTasks(
        objectiveId,
        props?.filterObj,
        props?.objective.isViewerOnly,
        props?.objective.showAssignedActivitiesOnly,
      )
    }
  }, [
    objectiveId,
    isRefreshObjective,
    props.filterObj,
    props.refetchActions,
    props.milestoneLabelId,
    props.isObjectiveDataLoading,
  ])

  function getObjectiveTasks(objectiveId, filterObj, isViewerOnly, showAssignedActivitiesOnly) {
    // Remove the objectiveFilter property from filterObj as we do the objective filtering on the frontend.
    // Passing a lot of data causes the api to fail so avoid passing the objectiveFilter property
    const { objectiveFilter, ...newFilterObj } = filterObj

    setIsLoading(true)
    setTimeout(() => {
      getHttpRequest(`/kanban_board_activity_data_with_limit/${objectiveId}/${10}`, {
        params: {
          filter: {
            ...newFilterObj,
            labelUuidsFilter: props.milestoneLabelId ? [props.milestoneLabelId] : [],
          },
          is_shared: isViewerOnly,
          show_assigned_activities_only: showAssignedActivitiesOnly,
        },
      })
        .then((response) => {
          const data = response
          if (data) {
            const tasks = data.milestones
            const columns = data.milestoneStatusList
            const columnOrder = data.columnOrder
            const finalData = { tasks, columns, columnOrder }
            setState(finalData)
            console.log('Object?.keys(data?.milestones): ', Object?.keys(data?.milestones))

            props.setObjectiveActionDict((oldObj) => {
              // Create a new object by spreading the old one
              const newObj = { ...oldObj }
              // Update the specific key with the new value
              newObj[props?.objective?.id] = Object?.keys(data?.milestones)?.length
              // Return the new object to update the state
              return newObj
            })
            props.setP13nModeColumnData({ columns, columnOrder })
          } else {
          }
          setIsLoading(false)
        })
        .catch((error) => {})
    }, props.delay * 1000)
  }

  // function onDragEnd(result) {
  // {(result) =>
  const onDragEnd = throttle((result) => {
    const { destination, source, draggableId } = result

    // setIsProces(true);
    if (isProces) return

    // TODO:  Recorder our column
    if (!destination) {
      return
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const new_column_status = destination.droppableId
    const taskId = Math.floor(draggableId)

    // Start Column source
    const start = state.columns[source.droppableId]

    // Start Column destination
    const finish = state.columns[destination.droppableId]

    if (start === finish) {
      const newTaskIds = Array.from(start.activityIds)
      ///

      newTaskIds.splice(source.index, 1)

      const drag = Math.floor(draggableId)

      newTaskIds.splice(destination.index, 0, drag)

      const newColumn = {
        ...start,
        activityIds: newTaskIds,
      }

      const cloneState = { ...state }
      cloneState.columns[source.droppableId] = newColumn
      setState(cloneState)

      return
    } else {
      // Moving From One List to Other

      const startTaskIds = Array.from(start.activityIds)
      startTaskIds.splice(source.index, 1)
      const newStart = {
        ...start,
        activityIds: startTaskIds,
      }

      const finishTaskIds = Array.from(finish?.activityIds ?? [])

      const drag = Math.floor(draggableId)
      finishTaskIds.splice(destination.index, 0, drag)

      const newFinish = {
        ...finish,
        activityIds: finishTaskIds,
      }

      const cloneState = { ...state }

      cloneState.columns[source.droppableId] = newStart
      cloneState.columns[destination.droppableId] = newFinish

      const postObject = {
        objectiveId: objectiveId,
        milestoneId: taskId,
        newStatus: new_column_status,
      }
      setState(cloneState)

      shuffleTaskBetweenColumn(postObject)
      //For loop being done to figure out if the task being moved is linked to any meeting topic, therefore know wheter to ask to add blocker or not
      let tempUmtId = 0
      for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].milestoneId === taskId) {
          setUmtId(tasks[i].umtId)
          tempUmtId = tasks[i].umtId
        }
      }
      // Commented out for now until we finalize the logic that we want this behaviour out
      // if (new_column_status == 'AT_RISK' && tempUmtId === 0) {
      //   //only ask to add blocker if action is not linked to any meeting toipc yet when moved to AT_RISK column
      //   Swal.fire({
      //     title: t('Common:modalMessages.blockerModalSuggestion'),
      //     text: '',
      //     confirmButtonText: t('Common:commonButtons.yes'),
      //     cancelButtonText: t('Common:commonButtons.no'),
      //     showCancelButton: true,
      //   }).then((result) => {
      //     if (result.value) {
      //       setLinkActionActionId(taskId)
      //       setIsLinkActionToMeetingTopicModalOpen(true)
      //     }
      //   })
      // }
    }
  }, 100)

  function onDragStart() {}

  function onDragUpdate() {}

  async function shuffleTaskBetweenColumn(postObject) {
    postHttpRequest(POST_KANBAN_BOARD_CHANGE_ACTIVITY_STATUS, postObject)
      .then((response) => {
        if (response) {
          // setIsRefreshObjective((old) => !old)
          return
        } else {
        }
      })
      .catch((error) => {})
  }

  const mutationAddManyActivities = useAddManySuggestedActivities()

  const addSuggestedActivities = async (activities) => {
    await mutationAddManyActivities.mutateAsync(
      { milestones: activities },
      {
        onSuccess: () => {
          setIsRefreshObjective((old) => !old)
        },
        onError: () => {
          alert(t('Common:errorModal.text5'))
        },
      },
    )
  }

  const openCommentsModal = () => {
    setIsCommentsModalOpen(true)
  }

  const closeCommentsModal = () => {
    setIsCommentsModalOpen(false)
  }

  useEffect(() => {
    let activities = []
    state?.columnOrder?.length > 0 &&
      state?.columnOrder?.map((columnId, index) => {
        const column = state.columns[columnId]

        if (column?.activityIds && column.activityIds.length > 0) {
          activities = column.activityIds.map((activityIds) => state.tasks[activityIds])
        }
      })
    setTasks(activities)
  }, [state])

  const hasAnyActivities = (id) => {
    if (tasks.length === 0) {
      return false
    }
    tasks?.map((task, index) => {
      if (task.objectiveId === parseInt(id)) {
        return true
      }
      return false
    })
  }

  const toggleContent = (index) => {
    let tempIsHidden = [...props.isHidden]
    tempIsHidden[index] = !tempIsHidden[index]
    props.setIsHidden(tempIsHidden)
  }

  const validateStartEndDate = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (name === 'startDate') {
      if (dueDate && value > dueDate) {
        Toast.fire({
          icon: 'error',
          title: t('startAfterDueToastMsg'),
        })
      } else {
        setStartDate(value)
      }
    } else if (name === 'dueDate') {
      if (startDate && value < startDate) {
        Toast.fire({
          icon: 'error',
          title: t('dueDateBeforeStartToastMsg'),
        })
      } else {
        setDueDate(value)
      }
    }
  }

  async function onExportCSV(objId) {
    //setIsLoading(true)
    const postData = { file: true }
    const result = await getExportSepreadsheet(objId, postData)
    //setIsLoading(false)
    if (result) {
      const today = new Date()
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const dd = String(today.getDate()).padStart(2, '0')
      const yyyy = today.getFullYear()
      let fileName = `${props.objective.statement}` + '_' + `${mm}-${dd}-${yyyy}` + '.xlsx'
      fileDownload(result, fileName)
    } else {
      alert('There was an error downloading the file.')
    }
  }

  return (
    <>
      {isKanbanBacklogOpen && (
        <KanbanMainBacklog
          isModalOpen={isKanbanBacklogOpen}
          closeModal={() => {
            setIsKanbanBacklogOpen(false)
            setIsRefreshObjective((old) => !old)
          }}
          objective={props?.objective}
          employeesWithColors={props?.employeesWithColors}
          objIdsToShowTitle={props?.objIdsToShowTitle}
          index={props?.index}
          buttonTypes={props?.buttonTypes}
          userList={props.userList}
          milestoneLabelName={props.milestoneLabelName}
        />
      )}
      {flags.enable_combined_objs === 'false' &&
        props?.objective?.isViewerOnly === true &&
        props?.objective?.ownerName != undefined && (
          <div
            style={{
              color: 'rgb(85, 85, 85)',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '12px',
            }}
          >
            {t('Kanban:owner')}: {props?.objective?.ownerName}
          </div>
        )}
      <KanbanHeader>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {props?.isHidden[props?.index] && (
            <span
              class="material-symbols-outlined"
              onClick={() => {
                toggleContent(props?.index)
              }}
              style={{ fontSize: '30px', cursor: 'pointer' }}
            >
              arrow_drop_down
            </span>
          )}

          {!props?.isHidden[props?.index] && (
            <span
              class="material-symbols-outlined"
              onClick={() => {
                toggleContent(props?.index)
              }}
              style={{ fontSize: '30px', cursor: 'pointer' }}
            >
              arrow_drop_up
            </span>
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {props.isShared && <div className="shared-box">Shared</div>}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {productSettings &&
                props?.objective?.initiativeId !== 0 &&
                !props?.objective?.isGeneralObjective && (
                  <span style={{ height: 'fit-content' }}>
                    <div style={{ display: 'flex', margin: '0 0.3rem 0 0.3rem ' }}>
                      <div>{props?.objective?.initiativeId.toString()?.padStart(5, '0')}</div>
                      <span style={{ paddingLeft: '0.1rem' }}> - </span>
                    </div>
                  </span>
                )}
              <KanbanTitle> {props?.objective?.statement} </KanbanTitle>
            </div>
            {props?.objective?.isGeneralObjective && <div>{t('Kanban:generalObjectiveMsg')}</div>}
            {flags.enable_combined_objs === 'true' &&
              (props?.objective?.originalId != 0 || props?.objective.isViewerOnly) &&
              (props?.objective?.isCopy ? (
                <span> {props?.objective?.ownerName} </span>
              ) : (
                <span>
                  {' '}
                  {t('Kanban:owner')}: {props?.objective?.ownerName}{' '}
                </span>
              ))}
          </div>
        </div>
        {props?.milestoneLabelId === null && (
          <div style={{ display: 'flex', flexDirection: 'row', columnGap: '3rem' }}>
            {showNewTemplateFeatures &&
              props?.objective?.hasTemplate === false &&
              props?.objective?.activitiesCount === 0 && (
                <>
                  <Link
                    to={`/createCustomTemplate?objectiveId=${objectiveId}`}
                    className="create-template-btn"
                  >
                    <div
                      class="fitted-button blue"
                      onClick={() => setisMultipleActivitiesModalOpen(true)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '24px',
                        width: '8rem',
                      }}
                    >
                      {t('Kanban:createTemplate')}
                    </div>
                  </Link>
                </>
              )}

            <>
              {showNewTemplateFeatures && props?.objective?.hasTemplate === true && (
                <Link
                  to={`/createCustomTemplate?objectiveId=${objectiveId}`}
                  className="create-template-btn"
                >
                  <div
                    class="fitted-button blue"
                    style={{ display: 'flex', alignItems: 'center', height: '24px', width: '8rem' }}
                  >
                    {t('Kanban:editTemplate')}
                  </div>
                </Link>
              )}
            </>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: '1rem',
              }}
            >
              {productSettings ? (
                <div
                  class="fitted-button blue"
                  onClick={() => {
                    openCommentsModal()
                  }}
                >
                  <span class="material-symbols-outlined">comment</span>
                </div>
              ) : (
                <div
                  className="fitted-button blue tooltip"
                  onClick={() => {
                    setIsObjDetailsOpen(true)
                  }}
                >
                  <span className="material-symbols-outlined">History </span>
                  <span class="tooltiptext"> {t('Common:tooltip.showDecisionHistory')} </span>
                </div>
              )}

              {showNewTemplateFeatures && (
                <>
                  <div
                    class="fitted-button blue"
                    onClick={() => {
                      setIsObjectiveActivityChartsModalOpen(true)
                    }}
                  >
                    <span class="material-symbols-outlined">insert_chart</span>
                  </div>

                  <div class="fitted-button blue" onClick={handleClick}>
                    <span class="material-symbols-outlined">table_view</span>
                  </div>

                  {props.objective.activitiesCount > 0 && (
                    <div
                      class="fitted-button blue"
                      style={{ alignSelf: 'end', marginBottom: '0.5rem', marginRight: '0.5rem' }}
                      onClick={() => {
                        onExportCSV(objectiveId)
                      }}
                    >
                      <span class="material-symbols-outlined">ios_share</span>
                    </div>
                  )}

                  {buttonClicked && (
                    <Navigate
                      to={`/spreadsheets?objId=${objectiveId}`}
                      state={{ employeesWithColors: props?.employeesWithColors }}
                    />
                  )}
                </>
              )}
              {!props?.objective?.isGeneralObjective && (
                <div
                  class="fitted-button blue tooltip"
                  onClick={async () => {
                    setIsGanttChartModalOpen(true)
                  }}
                  title="Gantt Chart View"
                >
                  <span class="material-symbols-outlined icon-size-l">view_timeline</span>
                </div>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
              {/* l2UserCanSeeL2InKanban  allowAddingL2Activities can't be true at the same time since tl only has allowAddingL2Activities and al only has l2UserCanSeeL2InKanban*/}
              {/* Commented out for now while we finalize how to substitute add activity modal in the cards */}
              {/* {(props?.objective?.isGeneralObjective || props?.objective?.level === 2 && (l2UserCanSeeL2InKanban !== true && allowAddingL2Activities !== true))
              || hideAddActivityBt ? null : (
              <div
                class="fitted-button blue"
                onClick={() => setIsSingleActivityModalOpen(true)}
                data-testid={`add-activity-${props.objective.id}`}
              >
                <span class="material-symbols-outlined">add</span>{t('activity')}
              </div>
            )} */}

              {!props?.objective?.isGeneralObjective && props?.objective?.hasTemplate === false && (
                <>
                  {disableAiConfig || props?.objective?.level === 2 ? null : (
                    <>
                      <div
                        class="fitted-button blue"
                        onClick={() => setIsSuggestedActivitiesModalOpen(true)}
                      >
                        <span
                          style={{
                            display: 'flex',
                            fontSize: '20px',
                            width: '24px',
                            height: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {' '}
                          AI{' '}
                        </span>
                      </div>

                      <div
                        class="fitted-button blue"
                        onClick={() => setisMultipleActivitiesModalOpen(true)}
                        data-testid={`add-activity-${props.objective.id}`}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <span class="material-symbols-outlined">add</span>
                        {t('Common:commonButtons.multiple')}
                      </div>
                    </>
                  )}
                  <div
                    class="fitted-button blue"
                    onClick={() => setIsSingleActivityModalOpen(true)}
                    data-testid={`add-activity-${props.objective.id}`}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span class="material-symbols-outlined">add</span>
                  </div>

                  {props?.objective?.level === 3 && !hasRole('ic') && (
                    <div
                      class="fitted-button blue"
                      style={{ alignSelf: 'end', marginBottom: '0.5rem', marginRight: '0.5rem' }}
                      onClick={() => {
                        setIsAddEditObjectiveModalOpen(true)
                      }}
                    >
                      <span class="material-symbols-outlined">edit </span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </KanbanHeader>
      {!props?.isHidden[props?.index] && (
        <>
          {props?.milestoneLabelId === null && (
            <div
              style={{
                paddingLeft: '0.2rem',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              <span
                class="material-symbols-outlined icon-size"
                style={{ cursor: 'pointer' }}
                onClick={handleOpenTagsModal}
              >
                sell
              </span>
              {props?.objective?.tags?.map((tag, tagIndex) => {
                return (
                  <div
                    key={tagIndex}
                    style={{
                      backgroundColor: '#e6e6e6',
                      color: 'black',
                      padding: '0.2rem 0.5rem',
                      borderRadius: '0.5rem',
                      marginLeft: '0.5rem',
                      marginTop: '0.2rem',
                    }}
                  >
                    {tag.tagName}
                  </div>
                )
              })}
            </div>
          )}
          <div
            style={{
              paddingTop: '0.5rem',
              paddingLeft: '0.5rem',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {props?.objective?.objIcMappings
              ?.sort((a, b) => a.eName.localeCompare(b.eName))
              .slice(0, 3) // Display only the first 3 assignees
              .map((person, personIndex) => (
                <>
                  {props?.employeesWithColors.map((emp, empIndex) => (
                    <>
                      {emp.eId === person.eId && (
                        <span
                          className="tooltip circle-medium user-initials-circle"
                          style={{
                            backgroundColor: emp.color,
                            marginLeft: personIndex > 0 ? '-10px' : '0', // Adjust the overlap
                          }}
                        >
                          {(person.eName.match(/\b(\w)/g) || []).slice(0, 2).join('')}

                          {/* Fix tooltip alignment */}
                          {/* <span class="tooltiptext bgu_kanban_desc" style={{ fontSize: '16px' }}>
                              {person.eName}
                            </span> */}
                        </span>
                      )}
                    </>
                  ))}
                </>
              ))}
            {props?.task?.assigneeList?.length > 3 && (
              <span className="tooltip">
                ...
                <span className="tooltiptext" style={{ fontSize: '16px' }}>
                  {/* You can add a tooltip with the names of remaining assignees here */}
                  {props?.task?.assigneeList
                    .slice(3)
                    .map((assignee) => assignee.name)
                    .join(', ')}
                </span>
              </span>
            )}
            {/* Commenting out start and end date until 100% sure it's not needed */}
            {/* {!props?.objective?.isGeneralObjective && props?.objective?.level !== 1 && (
              <div style={{ marginLeft: '3rem', display: 'flex', flexDirection: 'row' }}>
                <div>
                  <span>{t('start')}:</span>
                  <input
                    type="date"
                    value={startDate}
                    name="startDate"
                    onKeyDown={(e) => {
                      e.preventDefault()
                    }}
                    onChange={validateStartEndDate}
                  />
                </div>
                <div style={{ marginLeft: '0.3rem' }}>
                  <span>{t('end')}: </span>
                  <input
                    type="date"
                    value={dueDate}
                    name="dueDate"
                    onKeyDown={(e) => {
                      e.preventDefault()
                    }}
                    onChange={validateStartEndDate}
                  />
                </div>
              </div>
            )} */}
          </div>
          <div>
            {state.tasks == undefined && !isLoading && (
              <div style={{ padding: '1vw' }}>
                {props?.filterObj.peopleFilter.length === 0 &&
                props?.filterObj.timeframeFilter === 'all'
                  ? t('Kanban:noActivitiesAdded')
                  : t('Kanban:noActitivitiesFilter')}
              </div>
            )}
          </div>
          {/* Commenting out ShowRecentMeeting until we are sure 100% it's not needed */}
          {/* <ShowRecentMeeting objectiveId={objectiveId} delay={props.delay * 1000 + 500} /> */}

          <Container>
            <DragDropContext
              onDragStart={onDragStart}
              onDragUpdate={onDragUpdate}
              onDragEnd={onDragEnd}
            >
              {state !== '' &&
                state?.columnOrder.length > 0 &&
                state?.columnOrder.map((columnId, index) => {
                  const column = state.columns[columnId]
                  let tasks = []
                  if (column?.activityIds && column.activityIds.length > 0) {
                    tasks = column.activityIds.map((activityIds) => state.tasks[activityIds])
                  }
                  return (
                    <Column
                      key={column.statusId}
                      column={column}
                      tasks={tasks}
                      index={index}
                      statement={props?.objective?.statement}
                      objectiveId={objectiveId}
                      setIsRefreshObjective={setIsRefreshObjective}
                      employeesWithColors={props?.employeesWithColors}
                      buttonTypes={props.buttonTypes}
                      showAssignedActivitiesOnly={props?.objective?.showAssignedActivitiesOnly}
                      setIsKanbanBacklogOpen={setIsKanbanBacklogOpen}
                      milestoneLabelName={props.milestoneLabelName}
                      userList={props.userList}
                    />
                  )
                })}
            </DragDropContext>
          </Container>
          <br />
        </>
      )}

      {isLinkActionToMeetingTopicModalOpen && (
        <LinkActionToMeetingTopicModal
          closeModal={() => {
            setIsLinkActionToMeetingTopicModalOpen(false)
          }}
          actionId={linkActionActionId}
          objId={objectiveId}
          reloadBackground={() => {
            setIsRefreshObjective((old) => !old)
          }}
          umtId={umtId > 0 ? umtId : null}
        />
      )}

      {isSingleActivityModalOpen && (
        <AddEditActivityModal
          isModalOpen={isSingleActivityModalOpen}
          closeModal={() => {
            setIsSingleActivityModalOpen(false)
          }}
          objectiveId={objectiveId}
          activityId={null}
          setIsRefreshObjective={setIsRefreshObjective}
          usedInKanban={true}
        />
      )}
      {isSuggestedActivitiesModalOpen === true && (
        <SuggestedActivitiesModal
          objective={props?.objective}
          handleClose={() => setIsSuggestedActivitiesModalOpen(false)}
          addActivities={addSuggestedActivities}
          handleOpenErrorModalMessage={() => {
            setIsSuggestedActivitiesModalOpen(false)
            setIsErrorModalOpen(true)
          }}
        />
      )}
      {isMultipleActivitiesModalOpen === true && (
        <AddMultipleActivitiesModal
          objectiveId={objectiveId}
          handleClose={() => setisMultipleActivitiesModalOpen(false)}
          setIsRefreshObjective={setIsRefreshObjective}
        />
      )}
      {isCommentsModalOpen === true && (
        <CommentsModal2
          objective={{ id: objectiveId, statement: props?.objective?.statement }}
          handleCancel={closeCommentsModal}
          taskId={null}
          umtId={null}
          displayAllComments={true}
        />
      )}
      {isObjectiveActivityChartsModalOpen === true && (
        <ObjectiveActivityChartsModal
          isModalOpen={isObjectiveActivityChartsModalOpen}
          closeModal={() => {
            setIsObjectiveActivityChartsModalOpen(false)
          }}
          objective={props?.objective}
        />
      )}
      {isObjDetailsOpen && (
        <ObjectiveDetailsModal
          isModalOpen={isObjDetailsOpen}
          closeModal={() => {
            setIsObjDetailsOpen(false)
          }}
          objIdIn={parseInt(objectiveId)}
          showInitiativesId={productSettings}
        />
      )}

      {isAddEditObjectiveModalOpen && (
        <KanbanAddEditObjectiveModal
          objId={parseInt(objectiveId)}
          closeModal={() => {
            setIsAddEditObjectiveModalOpen(false)
          }}
          isAddEditObjectiveModalOpen={isAddEditObjectiveModalOpen}
          mode={'edit'}
          setEditedObj={setEditedObj}
          setObjData={() => {}}
          loadAgain={() => {}}
          tags={props?.objective?.tags}
        />
      )}
      {isManageTagsModalOpen && (
        <TagsManagementModal
          closeModal={() => {
            setIsManageTagsModalOpen(false)
          }}
          objectiveId={objectiveId}
          tags={props?.objective?.tags}
          setTags={(tags) => {
            props?.setObjectiveTags(tags)
          }}
          setEditedObj={setEditedObj}
          objective={true}
        />
      )}
      {isGanttChartModalOpen === true && (
        <GanttChartModal
          isModalOpen={isGanttChartModalOpen}
          closeModal={() => {
            setIsGanttChartModalOpen(false)
          }}
          objId={objectiveId}
        />
      )}
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={() => {
          setIsErrorModalOpen(false)
        }}
        errorMessage={t('Common:errorModal.text4')}
      />
    </>
  )
}

export default KanbanMain

import React, { useState, useEffect, useRef } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Toast from '../../common/toast'
import * as Yup from 'yup'
import { useGetSingleActivity } from '../../api/services/spreadsheet.srvices'
import Modal from '../Modal'
import MySelect from '../MySelect'
import useSaveSpreadsheetData from '../../api/mutation/useSaveSpreadsheetData'
import { API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE, API_ERROR_MESSAGE } from '../../api/constant/route'
import InPageLoader from '../InPageLoader'
import { useTranslation } from 'react-i18next'
import TransferActivity from '../TransferActivity'
import Swal from 'sweetalert2'
import { ModalSaveButtonFitted } from '../ModalSubmitButton'
import { MentionsInput, Mention } from 'react-mentions'
import { getObjectivUserList } from '../../api/services/employee.services'
import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'
import CreatableSelect from 'react-select/creatable'
import { KanbanCardHexColors } from '../../utils/colors'

const FormikMentionsInput = ({
  field,
  form,
  userList,
  setMentionState,
  activityId,
  mentionState,
  ...props
}) => {
  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    let mention = mentionState.find((mention) => mention.id === activityId)
    if (mention) {
      setMentionState((prev) =>
        prev.map((item) => (item.id === activityId ? { ...item, mentions: mentions } : item)),
      )
    } else {
      setMentionState([...mentionState, { id: activityId, mentions: mentions }])
    }
    // setMentionsList(mentions)
    form.setFieldValue(field.name, newValue)
  }
  return (
    // <Wrapper>
    <MentionsInput
      // className='body_bottom_box'
      {...field}
      {...props}
      value={field.value}
      onChange={handleChange}
      allowSuggestionsAboveCursor={true}
      style={{
        maxWidth: '317px',
        minWidth: '317px',
        minHeight: '5.5rem',
        border: '0.5px gray',
        borderRadius: '10px',
        backgroundColor: 'rgb(242, 242, 242)',
        padding: '3px',
        suggestions: {
          list: {
            maxHeight: 80,
            overflowY: 'auto',
          },
          item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
              backgroundColor: '#d3d3d3',
            },
          },
        },
        input: {
          overflow: 'auto',
        },
        highlighter: {
          boxSizing: 'border-box',
          overflow: 'hidden',
          height: 70,
          border: '4px solid transparent',
        },
      }}
      spellCheck={false}
    >
      <Mention
        trigger="@"
        data={userList}
        // renderSuggestion={this.renderUserSuggestion}
        markup="@[__display__](user:__id__)"
        displayTransform={(id, display) => `@${display}`}
        renderSuggestion={(suggestion, search, highlightedDisplay) => (
          <div className="user">{highlightedDisplay}</div>
        )}
        appendSpaceOnAdd={true}
      />
    </MentionsInput>
    // </Wrapper>
  )
}

const AddEditActivityModal = ({
  isModalOpen,
  closeModal,
  objectiveId,
  activityId,
  setIsRefreshObjective = () => {},
  refreshBGUScorecardFn = () => {},
  refreshBGUActionsFn = () => {},
  fromModal = false,
  hasTopicType = false,
  refreshMei = () => {},
  usedInKanban = false,
}) => {
  const { t } = useTranslation(['Common'])
  const [userList, setUserList] = React.useState([])

  const { data: activityData, isLoading: isActivityDataLoading } = useGetSingleActivity(
    objectiveId,
    activityId,
  )
  const [fieldsOrder, setFieldsOrder] = React.useState([])

  const [validationSchema, setValidationSchema] = React.useState({})
  const [initialValues, setInitialValues] = React.useState({})

  const [idMapping, setIdMapping] = useState({})
  const [mId, setmId] = useState(null)

  const [tags, setTags] = React.useState([])
  const [dateErrorMsg, setDateErrorMsg] = React.useState(false)

  const [mentionState, setMentionState] = React.useState([])

  const counter = useRef(0)
  const genId = () => counter.current++

  const mutationSaveSpreadsheetData = useSaveSpreadsheetData()
  const [selectedOption, setSelectedOption] = useState([])
  const [isLabelsInputLoading, setIsLabelsInputLoading] = useState(false)
  const [milestoneLabelsList, setMilestoneLabelsList] = useState([])
  const [passedTags, setPassedTags] = useState([])
  const [id, setId] = useState(0)
  const [colorSelected, setColorSelected] = useState(null)

  useEffect(() => {
    getMilestoneLabels()
    if (activityId) {
      getCardColor()
    }
  }, [])

  const getMilestoneLabels = async () => {
    let result
    try {
      result = await getHttpRequest(
        `/get_milestone_labels_for_milestone?milestone_id=${activityId}`,
      )
      let existingtagsList = result.success.map((item) => ({
        id: item[0],
        name: item[1],
      }))
      setPassedTags(existingtagsList)
      setSelectedOption(result.success.map((item) => ({ value: item[0], label: item[1] })))
      let companyMilestoneLabels = await getHttpRequest(`/get_all_milestones_labels_for_company`)

      if (companyMilestoneLabels.success.length > 0) {
        const comLabels = companyMilestoneLabels.success.map((item) => ({
          id: item[0],
          name: item[1],
        }))
        setMilestoneLabelsList(comLabels)
      }
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const getCardColor = async () => {
    try {
      let params = {
        milestoneId: activityId,
      }
      const data = await getHttpRequest('/get_milestone_color', { params })
      setColorSelected(data.color)
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  React.useEffect(() => {
    ;(async () => {
      const response = await getObjectivUserList(0)
      if (response?.employees)
        setUserList(response.employees.map((emp) => ({ display: emp.name, id: emp.eId })))
    })()
  }, [])

  React.useEffect(() => {
    if (activityData) {
      let sch = generateValidationSchema(Object.keys(activityData?.columnMetadata))
      setValidationSchema(sch)

      if (activityData?.data?.length > 0) {
        //if edit
        let tempInitialValues = {}
        Object.keys(activityData?.columnMetadata).forEach((element, index, arrayOrIterable) => {
          tempInitialValues[element] = activityData.data[0][element]
        })
        setInitialValues(tempInitialValues)
      } else {
        //if add
        let tempInitialValues = {}
        Object.keys(activityData?.columnMetadata).forEach((element, index, arrayOrIterable) => {
          const type = activityData?.columnMetadata[element]?.type
          const isMulti = activityData?.columnMetadata[element]?.multiselect
          let value = null
          if (type === 1 || type === 2) {
            value = ''
          } else if (type === 3) {
            value = ''
          } else if ((type === 4 || type === 6) && isMulti === true) {
            value = []
          } else if ((type === 4 || type === 6) && isMulti === false) {
            if (element === 'progress') {
              value = activityData.columnMetadata[element].options.filter((x) => x.value == 5)[0]
                .value
            } else {
              value = ' '
            }
          } else if (type === 5) {
            value = false
          }
          tempInitialValues[element] = value
        })
        setInitialValues(tempInitialValues)
      }

      let mapping = {}
      for (let i = 0; i < activityData.data.length; i++) {
        let id = genId()
        activityData.data[i].id = id
        let mId = activityData.data[i].mId__
        mapping[mId] = id
        setmId(mId)
      }
      setIdMapping(mapping)

      // set order in which fields appear
      let fieldsOrderTemp = []
      for (let i = 0; i < activityData.columns.length; i++) {
        fieldsOrderTemp.push(activityData.columns[i].title)
      }
      setFieldsOrder(fieldsOrderTemp)

      //Re-order dropdown options for "Progress"
      const customOrder = [5, 2, 3, 1, 4]
      let tempOptions = [...(activityData?.columnMetadata?.['progress']?.options ?? [])]
      tempOptions.sort((a, b) => customOrder.indexOf(a.value) - customOrder.indexOf(b.value))
      activityData.columnMetadata['progress'].options = [...tempOptions]
    }
  }, [activityData])

  const generateValidationSchema = (fields) => {
    const schema = {}
    fields.forEach((field) => {
      const type = activityData?.columnMetadata[field]?.type
      const required = activityData?.columnMetadata[field]?.required
      const isMulti = activityData?.columnMetadata[field]?.multiselect

      let word = capitalizeWords(field)
      let validation

      if (type === 1) {
        //text
        validation = Yup.string()
      }
      if (type === 2) {
        //number
        validation = Yup.number()
      }
      if (type === 3) {
        //date
        validation = Yup.date()
      }
      if ((type === 4 || type === 6) && isMulti === true) {
        //array
        if (required === true) {
          validation = Yup.array()
            .of(Yup.number().typeError(`${word} is required`).required(`${word} is required`))
            .min(1, `At least one value for ${word} is required`)
        } else {
          validation = Yup.array().of(Yup.number())
        }
      }
      if ((type === 4 || type === 6) && isMulti === false) {
        //array
        if (required === true) {
          validation = Yup.number().typeError(`${word} is required`).required(`${word} is required`)
        } else {
          validation = Yup.number()
        }
      }
      if (type === 5) {
        //boolean
        validation = Yup.boolean()
      }

      if (required === true && (type === 1 || type === 2 || type === 3 || type === 6)) {
        validation = validation.required(`${word} is required`)
      }

      schema[field] = validation
    })
    return Yup.object().shape(schema)
  }

  const translatedFieldsEnglish = {
    description: 'Description',
    progress: 'Progress',
    'start date': 'Start Date',
    'due date': 'Due Date',
    assignee: 'Assignee',
    'created by': 'Created By',
    priority: 'Priority',
  }

  const translatedFieldsSpanish = {
    description: 'Descripción',
    progress: 'Progreso',
    'start date': 'Fecha de Inicio',
    'due date': 'Fecha Límite',
    assignee: 'Asignado a',
    'created by': 'Creado por',
    priority: 'Prioridad',
  }

  const languageSettings = JSON.parse(localStorage.getItem('tfmc')).languageSettings

  const translateDefaultFields = (field) => {
    if (languageSettings === 'en') {
      return translatedFieldsEnglish[field]
    } else if (languageSettings === 'es') {
      return translatedFieldsSpanish[field]
    } else {
      return capitalizeWords(field)
    }
  }

  function capitalizeWords(str) {
    let words = str.split(' ')
    for (let i = 0; i < words.length; i++) {
      let word = words[i]
      let capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1)
      words[i] = capitalizedWord
    }
    return words.join(' ')
  }

  const submitForm = async (values, isDelete = false) => {
    let mil = {}
    Object.keys(values).forEach((keyName, keyNameIndex) => {
      mil[keyName] = values[keyName]
    })
    mil['mId__'] = mId
    let actdata = [mil]
    if (
      actdata[0]['start date'] != '' &&
      actdata[0]['due date'] != '' &&
      new Date(actdata[0]['start date']) > new Date(actdata[0]['due date'])
    ) {
      setDateErrorMsg(true)
      return
    }

    //set deleted labels
    let delete_list = passedTags.filter(
      (item) => !selectedOption.some((option) => option.label === item.name),
    )
    delete_list.forEach((item) => {
      item['milestoneLabelId'] = item.id
      item['isDeleted'] = true
      item['labelMilestoneName'] = item.name
    })

    let setChangesObj = {
      createdRowIds: Array.from([]),
      updatedRowIds: Array.from([]),
      deletedRowIds: Array.from([]),
      activityData: [],
      idMapping: idMapping,
      columnMetadata: activityData?.columnMetadata,
      objectiveId: objectiveId,
      tags: [{ [mId]: tags }],
      milestoneLabels: delete_list,
      hexColor: colorSelected,
      // mentions: mentionsList.map(mention => mention.id),
    }

    let notInPassedLabels = selectedOption.filter(
      (item) => !passedTags.some((option) => option.name === item.label),
    )
    let add_list = notInPassedLabels
      .filter((item) => !item.hasOwnProperty('isDeleted'))
      .map((item) => ({ labelMilestoneName: item.label, milestoneLabelId: item.id }))
    setChangesObj['milestoneLabels'] = setChangesObj['milestoneLabels'].concat(add_list)

    let mentions =
      mentionState.find((mention) => mention.id === activityId)?.mentions.map((m) => m.id) ?? null
    // it means that user has triggered the onChange event of the textarea..
    // this is to prevent user click on the text then click outside of the textarea immediately
    // in which case mentions are not recorded yet
    if (mentions) {
      setChangesObj.mentions = mentions
    }

    if (activityId && isDelete === false) {
      //if edit activity
      setChangesObj.updatedRowIds = [0]
      actdata[0].id = 0
    } else if (activityId === null && isDelete === false) {
      //if add activity
      setChangesObj.createdRowIds = [0]
      actdata[0].id = 0
    } else if (activityId !== null && isDelete === true) {
      setChangesObj.deletedRowIds = [0]
      actdata[0].id = 0
    }

    setChangesObj.activityData = actdata

    await mutationSaveSpreadsheetData.mutateAsync([parseInt(objectiveId), setChangesObj], {
      onSuccess: (response) => {
        closeModal()
        Toast.fire({
          icon: 'success',
          title: API_SPREADHSHEET_SAVE_SUCCESS_MESSAGE,
        })
      },
      onError: (e) => {
        closeModal()
        Toast.fire({
          icon: 'error',
          title: API_ERROR_MESSAGE,
        })
      },
    })
    if (refreshBGUScorecardFn !== undefined) {
      refreshBGUScorecardFn()
    }

    setIsRefreshObjective((old) => !old)
    refreshBGUActionsFn()
    refreshMei()
  }

  const handleSelectChange = (newValue, actionMeta) => {
    if (!Array.isArray(newValue) || newValue.length <= 4) {
      setSelectedOption(newValue)
    }
  }

  const handleCreate = (inputValue) => {
    setIsLabelsInputLoading(true)
    setTimeout(() => {
      const newOption = createOption(inputValue)
      setIsLabelsInputLoading(false)
      setMilestoneLabelsList((milestonesLabelsList) => [...milestonesLabelsList, newOption])
      setSelectedOption((selectedOption) => [
        ...selectedOption,
        { value: newOption.id, label: newOption.name },
      ])
    }, 1000)
  }

  const createOption = (label) => {
    setId(id - 1)
    return {
      id: id,
      name: label,
      milestoneId: id,
    }
  }

  const buttonStyle = (color) => ({
    width: '38px',
    height: '38px',
    borderRadius: '50%',
    backgroundColor: `#${color}`,
    border: color === 'FFFFFF' ? '1px solid #CCCCCC' : 'none',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    outline: 'none',
    boxShadow: colorSelected === color ? '0 0 0 2px black' : 'none',
  })

  return (
    <>
      <Modal
        title={
          activityId
            ? t('addEditActivityModal.editActivity')
            : t('addEditActivityModal.addActivity')
        }
        isModalOpen={isModalOpen}
        data-testid="Add Objective"
        closeModal={closeModal}
      >
        {isActivityDataLoading && <InPageLoader inComponent={true} />}
        {((fieldsOrder.length > 0 && activityData && activityId === null) ||
          (activityData && activityId && Object.keys(initialValues).length > 0)) && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              // Handle form submission
              submitForm(values)
            }}
          >
            {({ errors, touched, resetForm, setFieldValue, form, values }) => (
              <Form
                className="objective-form"
                style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
              >
                <div>
                  {fieldsOrder.map((field, fieldIndex) => (
                    <div>
                      {/* ************** text field ************** */}
                      {activityData.columnMetadata?.[field]?.type === 1 && (
                        <div
                          key={field}
                          className="form-field action-field form-field action-field--kanban"
                        >
                          <label>
                            {translateDefaultFields(field) +
                              ' ' +
                              (activityData.columnMetadata?.[field].required === true ? '*' : '')}
                          </label>

                          {/* <Field
                            id={field}
                            name={field}
                            type="text"
                            as="textarea"
                            style={{ resize: "none", width: "100%"}}
                            data-testid={
                              capitalizeWords(field) == 'Description' ? 'description-field' : null
                            }
                          />  */}
                          <Field
                            id={field}
                            name={field}
                            component={FormikMentionsInput}
                            userList={userList}
                            setMentionState={setMentionState}
                            mentionState={mentionState}
                            activityId={activityId}
                            placeholder={
                              languageSettings === 'en' ? 'Type here...' : 'Escribe aquí...'
                            }
                          />
                          {errors[field] && <div className="validation-error">{errors[field]}</div>}
                        </div>
                      )}
                      {/* ************** number field ************** */}
                      {activityData.columnMetadata?.[field]?.type === 2 && (
                        <div
                          key={field}
                          className="form-field action-field form-field action-field--kanban"
                        >
                          <label>
                            {translateDefaultFields(field) +
                              ' ' +
                              (activityData.columnMetadata?.[field].required === true ? '*' : '')}
                          </label>
                          <Field id={field} name={field} type="number" />
                          {errors[field] && <div className="validation-error">{errors[field]}</div>}
                        </div>
                      )}
                      {/* ************** date field ************** */}
                      {activityData.columnMetadata?.[field]?.type === 3 && (
                        <div
                          key={field}
                          className="form-field action-field form-field action-field--kanban"
                        >
                          <label>
                            {translateDefaultFields(field) +
                              ' ' +
                              (activityData.columnMetadata?.[field].required === true ? '*' : '')}
                          </label>
                          <Field
                            id={field}
                            name={field}
                            type="date"
                            data-testid={field == 'due date' ? 'due_date' : null}
                            placeholder="yyyy-mm-dd"
                          />
                          {errors[field] && <div className="validation-error">{errors[field]}</div>}
                          {field == 'due date' && dateErrorMsg && (
                            <div className="validation-error">
                              {t('addEditActivityModal.dateErrorMsg')}
                            </div>
                          )}
                        </div>
                      )}
                      {/* ************** boolean field ************** */}
                      {activityData.columnMetadata?.[field]?.type === 5 && (
                        <div
                          key={field}
                          className="form-field action-field form-field action-field--kanban"
                        >
                          <label>
                            {translateDefaultFields(field) +
                              ' ' +
                              (activityData.columnMetadata?.[field].required === true ? '*' : '')}
                          </label>
                          <Field id={field} name={field} type="checkbox" />
                          {errors[field] && <div className="validation-error">{errors[field]}</div>}
                        </div>
                      )}

                      {/* ************** user defined list field  OR  pre-defined field************** */}
                      {(activityData.columnMetadata?.[field]?.type === 4 ||
                        activityData.columnMetadata?.[field]?.type === 6 ||
                        activityData.columnMetadata?.[field]?.type === 7) && (
                        <div
                          key={field}
                          className="form-field action-field form-field action-field--kanban"
                        >
                          <label>
                            {translateDefaultFields(field) +
                              ' ' +
                              (activityData.columnMetadata?.[field].required === true ? '*' : '')}
                          </label>
                          <Field
                            name={field}
                            render={({ field, form }) => (
                              <MySelect
                                field={activityData?.columnMetadata?.[field.name]}
                                form={form}
                                options={activityData?.columnMetadata?.[field.name]?.options}
                                name={field.name}
                                isMulti={activityData.columnMetadata?.[field.name].multiselect}
                                dataTestId={
                                  field.name == 'progress' ? 'progress-field' : field.name
                                }
                                isClearable={false}
                                isDisabled={activityData.columnMetadata?.[field.name]?.type === 7}
                                // not sure why progress and assignee have teh same type...
                                isAssignee={
                                  activityData.columnMetadata?.[field.name]?.type === 4 &&
                                  activityData.columnMetadata?.[field.name].multiselect
                                }
                              />
                            )}
                          />
                          {errors[field] && <div className="validation-error">{errors[field]}</div>}
                        </div>
                      )}
                    </div>
                  ))}
                  {/* Commmented out for now until we have time to prioritize making this work again */}
                  {/* <div className="form-field">
                                        <label>Tags</label>
                                        <TagInput existingTags={tags} setTagsExternally={setTags} />
                                    </div> */}

                  {
                    <div
                      className="form-field action-field form-field action-field--kanban"
                      style={{ maxWidth: '23rem' }}
                    >
                      <label>{t('Kanban:labels')}</label>

                      <CreatableSelect
                        id="selectOption"
                        value={selectedOption}
                        onChange={handleSelectChange}
                        options={milestoneLabelsList
                          .filter(
                            (item) => !selectedOption.some((option) => option.label === item.name),
                          )
                          .map(({ id, name }) => ({ value: id, label: name }))
                          .sort((a, b) => a.label.localeCompare(b.label))}
                        isLoading={isLabelsInputLoading}
                        onCreateOption={handleCreate}
                        isMulti
                        isClearable
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}></div>
                    </div>
                  }
                  {usedInKanban && (
                    <TransferActivity
                      objectiveId={objectiveId}
                      activityId={activityId}
                      closeModal={closeModal}
                      showTransferObj={activityId ? true : false}
                    />
                  )}

                  {usedInKanban && (
                    <div className="form-field action-field form-field action-field--kanban">
                      <label>{t('Kanban:cardColor')}</label>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '16px',
                          padding: '16px',
                        }}
                      >
                        {KanbanCardHexColors.map((color, index) => (
                          <button
                            key={index}
                            style={buttonStyle(color)}
                            onClick={() => setColorSelected(color)}
                            onMouseEnter={(e) => (e.target.style.transform = 'scale(1.1)')}
                            onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            type="button"
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="modal-dual-btn" style={{ columnGap: '4rem' }}>
                  <div
                    class="fitted-button blue"
                    onClick={() => {
                      closeModal()
                      resetForm()
                    }}
                    type="button"
                  >
                    {t('commonButtons.cancel')}
                  </div>
                  {activityId && !hasTopicType && (
                    <div
                      class="fitted-button blue"
                      onClick={async () => {
                        const result = await Swal.fire({
                          title: t('Common:modalMessages.areYouSureDeleteThisActivity'),
                          icon: 'question',
                          showCancelButton: true,
                          confirmButtonText: t('Common:commonButtons.yes'),
                          cancelButtonText: t('Common:commonButtons.no'),
                        })
                        if (result.isConfirmed) {
                          submitForm(values, true)
                          closeModal()
                          resetForm()
                        }
                      }}
                      type="button"
                    >
                      {t('commonButtons.delete')}
                    </div>
                  )}
                  <ModalSaveButtonFitted isLoading={mutationSaveSpreadsheetData.isLoading} />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  )
}
export default AddEditActivityModal

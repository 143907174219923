import styled from 'styled-components'

const Container = styled.div`
  padding: 10px;
  // border: 1px solid lightgrey;
  box-shadow: 0 0px 6px #ccc;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: ${(props) => props.bgColor};
`

const TaskInnerContainer = styled.div``

export { Container, TaskInnerContainer }

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import useCreateUpdateCycle from '../../../../api/mutation/useCreateUpdateCycle'
import useCreateAreaLeaderUpdateCycle from '../../../../api/mutation/useCreateAreaLeaderUpdateCycle'
import { sendEmailReminder } from '../../../../api/services/employee.services'
import useGetGovCycleInfo from '../../../../api/query/useGetGovCycleInfo'
import useGetGovAlCycleInfo from '../../../../api/mutation/useGetGovAlCycleInfo'
import useGovernanceStatusData from '../../../../api/query/useGovernanceCycleStatusData'
import Button from '../../../../components/Button'
import './styles.css'
import useGovernanceAlStatusData from '../../../../api/query/useGovernanceAlCycleStatus'
import Toast from '../../../../common/toast'
import { getAllEmployeeList } from '../../../../api/services/employee.services'
import Modal from '../../../../components/Modal'
import GovernanceStatus from '../../../../components/GovernanceStatus'
import GovernanceAreaLeaderStatus from '../../../../components/AreaLeaderGovernanceStatus'
import InPageLoader from '../../../../components/InPageLoader'

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 1rem 2rem;
  margin: 1rem 0;
  box-shadow: 0 5px 15px #ccc;
  justify-self: center;
  height: 90%;
  overflow-y: auto;
`

const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  white-space: nowrap;
  font-weight: bold;
  gap: 1rem;
  padding-bottom: 0.5rem;
`

const CycleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1rem;
  margin: 2rem 0;
`

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #00a300;
  font-weight: bold;
`

const ManageCadences = () => {
  const [isEmailSelectorModalOpen, setIsEmailSelectorModalOpen] = useState(false)
  const { data: tlGovCycleInfo, isLoading: tlInfoIsLoading } = useGetGovCycleInfo()
  const { data: alGovCycleInfo, isLoading: alInfoIsLoading } = useGetGovAlCycleInfo()
  const mutationTlUpdateCycle = useCreateUpdateCycle()
  const mutationAlUpdateCycle = useCreateAreaLeaderUpdateCycle()
  const [hasTLCycleStarted, setHasTLCycleStarted] = React.useState(false)
  const [hasALCycleStarted, setHasALCycleStarted] = React.useState(false)
  const { data: tlGovernanceStatusData, isLoading: governanceStatusIsLoading } =
    useGovernanceStatusData()
  const { data: alGovernanceStatusData, isLoading: governanceAlStatusIsLoading } =
    useGovernanceAlStatusData()
  if (
    tlInfoIsLoading ||
    alInfoIsLoading ||
    governanceStatusIsLoading ||
    governanceAlStatusIsLoading
  ) {
    return <InPageLoader inComponent={true} />
  }

  //TL new Update wizard start
  const handleNewCycleClick = async () => {
    if (window.confirm('Are you sure you want to start a cycle?')) {
      await mutationTlUpdateCycle.mutateAsync()
      setHasTLCycleStarted(true)
      setTimeout(() => {
        setHasTLCycleStarted(false)
      }, 5000)
    }
  }

  //AL new Update wizard start
  const startNewAlUpdateCycle = async () => {
    if (window.confirm('Are you sure you want to start a cycle?')) {
      await mutationAlUpdateCycle.mutateAsync()
      setHasALCycleStarted(true)
      setTimeout(() => {
        setHasALCycleStarted(false)
      }, 5000)
    }
  }

  return (
    <>
      {isEmailSelectorModalOpen && (
        <EmailEmployeesSelectorModal closeModal={() => setIsEmailSelectorModalOpen(false)} />
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          width: '75%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CardContainer style={{ width: '86%', marginLeft: '25px' }}>
          <CardHeader>Send Emails</CardHeader>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              width: '100%',
              position: 'relative',
            }}
          >
            <div className="underline-admin"></div>
          </div>
          <CardFooter style={{ marginTop: '2rem' }}>
            <button
              type="button"
              className="fitted-button blue"
              style={{
                background: 'white',
                padding: '0.4rem',
                height: '35px',
                fontWeight: 'bold',
                margin: '0 auto',
              }}
              onClick={() => setIsEmailSelectorModalOpen(true)}
            >
              Send Email Reminders
            </button>
          </CardFooter>
        </CardContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '2rem',
            height: '600px',
            width: '90%',
          }}
        >
          <CardContainer style={{ width: '55%', height: '550px', overflowY: 'hidden' }}>
            <CardHeader>Team Leader Update Wizards</CardHeader>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1rem',
                width: '100%',
                position: 'relative',
              }}
            >
              <div className="underline-admin"></div>
            </div>
            <CycleGrid style={{ width: '100%', overflowY: 'auto' }}>
              <div>
                <b>Wizards</b>
              </div>
              <div>
                <b>Date</b>
              </div>
              {tlGovCycleInfo?.cycleAndStartTimes?.map((cycle, index) => (
                <React.Fragment key={`cycle-${index}`}>
                  <div>
                    {cycle.description.startsWith('Update') && (
                      <>&nbsp;&nbsp;&nbsp;&nbsp; {cycle.description}</>
                    )}
                    {!cycle.description.startsWith('Update') && cycle.description}
                  </div>
                  <div>{new Date(cycle.startTimestamp * 1000).toLocaleDateString()}</div>
                </React.Fragment>
              ))}
            </CycleGrid>

            <CardFooter style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'column' }}>
              <button
                type="button"
                className="fitted-button blue"
                style={{
                  background: 'white',
                  padding: '0.4rem',
                  height: '35px',
                  fontWeight: 'bold',
                }}
                onClick={handleNewCycleClick}
              >
                New Update Wizard
              </button>
              {hasTLCycleStarted && 'New Cycle Started!'}
            </CardFooter>
          </CardContainer>
          <GovernanceStatus location={'Governance'} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '2rem',
            height: '600px',
            width: '90%',
          }}
        >
          <CardContainer style={{ width: '55%', height: '550px', overflowY: 'hidden' }}>
            <CardHeader>Area Leader Update Wizards</CardHeader>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1rem',
                width: '100%',
                position: 'relative',
              }}
            >
              <div className="underline-admin"></div>
            </div>
            <CycleGrid style={{ width: '100%', overflowY: 'auto' }}>
              <div>
                <b>Wizards</b>
              </div>
              <div>
                <b>Date</b>
              </div>
              {alGovCycleInfo?.cycleAndStartTimes?.map((cycle, index) => (
                <React.Fragment key={`cycle-${index}`}>
                  <div>{cycle.description.startsWith('Update') && <>{cycle.description}</>}</div>
                  <div>{new Date(cycle.startTimestamp * 1000).toLocaleDateString()}</div>
                </React.Fragment>
              ))}
            </CycleGrid>

            <CardFooter style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'column' }}>
              <button
                type="button"
                className="fitted-button blue"
                style={{
                  background: 'white',
                  padding: '0.4rem',
                  height: '35px',
                  fontWeight: 'bold',
                }}
                onClick={startNewAlUpdateCycle}
              >
                New Update Wizard
              </button>
              {hasALCycleStarted && 'New Cycle Started!'}
            </CardFooter>
          </CardContainer>
          <GovernanceAreaLeaderStatus location={'Governance'} />
        </div>
      </div>
    </>
  )
}

const EmailEmployeesSelectorModal = ({ closeModal }) => {
  const [allEmplsList, setAllEmplsList] = useState([])
  const [showSendEmailLoader, setShowSendEmailLoader] = useState(false)

  async function getAllEmps() {
    const result = await getAllEmployeeList()
    if (result && result?.employees) setAllEmplsList(result?.employees)
  }

  function handleEmailSendClick(isGroupEmail = false) {
    const selectedEmpIdsAndNames = []
    const checkboxes = document.getElementsByName('emailEmpId')
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        let name = allEmplsList.filter((empl) => empl.eId === parseInt(checkbox.value))[0].name

        selectedEmpIdsAndNames.push({
          id: checkbox.value,
          name: name,
        })
      }
    })

    if (selectedEmpIdsAndNames.length === 0) {
      alert('Please select at least one employee to send an email to.')
      return
    }

    let prompt =
      'Are you sure you want to send emails to these employees' +
      (isGroupEmail ? ' and their reportees:' : ':') +
      selectedEmpIdsAndNames.map((emp) => emp.name).join(', ') +
      '?'
    if (window.confirm(prompt)) {
      handleSendEmailReminders(
        selectedEmpIdsAndNames.map((emp) => emp.id),
        isGroupEmail,
      )
    }
  }

  const handleSendEmailReminders = async (listOfEIds, isGroupEmail) => {
    setShowSendEmailLoader(true)
    const result = await sendEmailReminder(listOfEIds, isGroupEmail)
    if (result.success == true) {
      Toast.fire({
        icon: 'success',
        title: 'Success',
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: 'There was an error!',
      })
    }
    closeModal()
  }

  useEffect(() => {
    getAllEmps()
  }, [])

  return (
    <Modal closeModal={closeModal}>
      {showSendEmailLoader && <div>Sending Email(s)...</div>}
      {!showSendEmailLoader && (
        <table>
          {allEmplsList.map((data, key) => (
            <tr key={key}>
              <td>{data.name}</td>
              <td>
                <input name="emailEmpId" value={data.eId} type="checkbox" />
              </td>
            </tr>
          ))}
        </table>
      )}

      <button onClick={() => handleEmailSendClick(false)}>Send Email</button>
      <button style={{ marginLeft: '1rem' }} onClick={() => handleEmailSendClick(true)}>
        Send Email to Group
      </button>
    </Modal>
  )
}

export default ManageCadences
